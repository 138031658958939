import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const AccountingIntegration = () => {
  return (
    <PageWithHeaderCallToAction
      title="Accounting Integration"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Accounting Integration</title>
        <meta
          name="description"
          content="Integrated with all major ERP finance systems."
        />
      </Helmet>

      {/* <h5 className="mb-4">
          Eliminate re-keying of data for accuracy and speed, by uploading
          transactions to your district financial system.
        </h5> */}

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Integrated with all major ERP finance systems.
      </Div>

      <Div row>
        <Div col>
          <ContentSection title="Overview">
            <Div row>
              <Div col={12} colMd={6}>
                <p>
                  InTouch is an end-to-end solution, connecting in-person and
                  online transactions to the general ledger system, and
                  everything in between.
                </p>
                <p>
                  Our convenient and efficient application streamlines bank
                  reconciliation.
                </p>
                <p>
                  Solving workflow issues such as processing time, accuracy of
                  data, controls, and other daily obstacles.
                </p>

                <h5 className="text-intouch-blue">Audit Trail</h5>
                <p>
                  Provides a complete audit trail for all general ledger
                  postings – from the receipt level to the consolidated ledger
                  posting.
                </p>
              </Div>

              <Div col={12} colMd={6}>
                <h5 className="text-intouch-blue">Timeliness & Efficiency</h5>
                <p>
                  Fund balances can be updated quickly, keeping your teachers,
                  directors, and administrators “in the know.”
                </p>
                <p>
                  End-users need up-to-date budgets from their accounting system
                  – otherwise, they create side spreadsheets and other
                  work-intensive processes which offer no value.
                </p>
                <p>
                  InTouch's accounting integration allows managers to post
                  revenues efficiently via an intuitive user interface,
                  eliminating the need for side spreadsheets, etc.
                </p>
              </Div>
            </Div>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default AccountingIntegration;
