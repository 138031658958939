import { Helmet } from "react-helmet";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";

const Careers = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Careers</title>
        <meta name="description" content="" />
      </Helmet>

      <PageHeader title="Careers" subtitle="" />

      <Div container marginTop={3}>
        <Div row>
          <Div col>
            <h4>Current Positions</h4>

            <Div marginTop={4}>
              <h5>Sales Consultant, Revenue Accounting and eCommerce</h5>
              <Div marginBottom={2}>
                <div>Minimum BA and 2 years as B2B Account Executive</div>
                <div>Must have excellent communication skills</div>
              </Div>
            </Div>

            <Div marginTop={4}>
              <h5>Client Support, Accounting and eCommerce</h5>
              <Div marginBottom={2}>
                <div>
                  Minimum BA and 2 years client technical and/or accounting
                  support B2B
                </div>
                <div>Must have excellent communication skills</div>
              </Div>
            </Div>
          </Div>
          <Div col>
            <h4>Ready for a change?</h4>

            <Div marginTop={4}>
              InTouch Offers
              <ul>
                <li>Competitive salary & benefits</li>
                <li>Exciting, high-profile product</li>
                <li>Outstanding career and growth opportunities</li>
                <li>Energetic, team-oriented atmosphere</li>
              </ul>
              <p>
                InTouch employs IT professionals, accounting specialists,
                customer account managers, and many other specialties! To see if
                you fit please send your resume and cover letter to
                careers@intouchreceipting.com
              </p>
              <p>
                We offer an attractive and unique opportunity to be part of the
                industry leading K-12 receipting software firm. Our team of
                developers, sales staff, technical support specialists and
                accounting staff offer a wide range of knowledge and compliment
                a diverse working environment. InTouch was built on ideas that
                were put into action, and we want people who are willing to
                collaborate and work as a team to progress the company,
                applications, and strive to offer customers the best software
                and support possible.
              </p>
              <p>
                Most InTouch positions require education and experience
                combinations. Nearly all positions require varied levels of
                travel and after hours work on an as-needed basis.
              </p>
            </Div>
          </Div>
        </Div>

        <Div row marginTop={5}>
          <Div col>
            InTouch celebrates a diverse workforce. We will never discriminate
            based on color, race, religious beliefs, physical status, gender, or
            any other basis. All qualified applicants will be reviewed equally
            for any open positions.
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default Careers;
