import { Helmet } from "react-helmet";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";

import TelephoneFill from "../icons/TelephoneFill";
import EnvelopeFill from "../icons/EnvelopeFill";
import WindowsIcon from "../icons/WindowsIcon";
import AppleIcon from "../icons/AppleIcon";

const Support = () => {
  return (
    <Div flex flexColumn height={100}>
      <Helmet titleTemplate="InTouch | %s">
        <title>Support</title>
        <meta name="description" content="We're here for you!" />
      </Helmet>

      <PageHeader title="Support" subtitle="We're here for you!" />
      <Div container flexShrink={0}>
        <Div row marginY={3}>
          <Div col display="none">
            <h4>Get help from our support team</h4>
            <p>
              ​InTouch Receipting Support Services are located in Washington.
            </p>
            <p>Help desk hours are from 6:00 am (PST) - 5:00 pm (PST) M-F.</p>
          </Div>

          <Div colMd={6}>
            <h4 className="mt-2 mb-3">Support Contacts</h4>
            <Div classNames={["mt-2"]}>
              <TelephoneFill />
              <span className="ms-2">+1 (800) 627-4767</span>
            </Div>
            <Div classNames={["mt-2"]}>
              <EnvelopeFill />
              <a className="ms-2" href="mailto:support@intouchreceipting.com">
                support@intouchreceipting.com
              </a>
            </Div>
          </Div>

          <Div col marginTop={4} marginTopMd={0} flex flexColumn>
            <h4 className="mt-2 mb-3">Support Resources</h4>
            <Div display="grid" classNames={["gap-2"]}>
              <a
                href="https://intouchprojects.teamwork.com/support/#/tickets/new"
                className="btn btn-lg btn-primary mt-2"
              >
                Create Help Ticket
              </a>
              <a
                href="https://help.intouchreceipting.com/"
                className="btn btn-lg btn-primary mt-2"
              >
                Help Docs
              </a>
              <Div marginTop={2} flex>
                <a
                  href="https://static.intouchreceipting.com/teamviewerqs_en-idcs6k3xpn.exe"
                  className="btn btn-lg btn-primary me-1 d-flex flex-fill align-items-center justify-content-center"
                >
                  Quick Support for Windows
                   <WindowsIcon classNames={["ms-3"]}/> 
                </a>
                <a
                  href="https://download.teamviewer.com/download/TeamViewerQS.dmg"
                  className="btn btn-lg btn-primary ms-1 d-flex flex-fill align-items-center justify-content-center"
                >
                  Quick Support for MacOS
                  <AppleIcon classNames={["ms-3"]}/>
                </a>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default Support;
