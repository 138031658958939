import { SVGAttributes } from "react";

const WindowsIcon = ({
  className,
  classNames,
  width,
  height,
  ...rest
}: { classNames?: string[] } & SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 16}
      height={height ?? 16}
      fill="currentColor"
      className={["bi", `bi-windows`, ...(classNames ?? []), className ?? ""]
        .filter((x) => x)
        .join(" ")
        .trim()}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path d="M6.555 1.375 0 2.237v5.45h6.555V1.375zM0 13.795l6.555.933V8.313H0v5.482zm7.278-5.4.026 6.378L16 16V8.395H7.278zM16 0 7.33 1.244v6.414H16V0z" />
    </svg>
  );
};

export default WindowsIcon;
