import { Helmet } from "react-helmet";
import PageHeader from "../components/PageHeader";
import Div from "../components/Div";
import { routes } from "../misc/routes";
import { Page, PrintDocument } from "../components/PrintDocument";
import logo from "../img/ITR_KEV_Logo_ByKevGroupFeb24.png";

const USServiceLevelAgreementV3 = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>US Service Level Agreement</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <PageHeader
        title="US Service Level Agreement"
        subtitle=""
        classNames={["d-print-none"]}
      />

      <style type="">
        {`
          .t-row {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;
            margin-bottom: 1rem;
          }
        `}
      </style>

      <PrintDocument border={false}>
        <Page>
          <Div container>
            <Div display="none" displayPrint="block" textAlign="center">
              <img src={logo} alt="Logo" style={{ height: "54px" }} />
              <Div display={6} marginTop={2}>
                US Service Level Agreement
              </Div>
            </Div>
            <Div row marginTop={4}>
              <Div col>
                <Div classNames={["t-row"]} flexJustify="center">
                  <strong>
                    SOFTWARE LICENSE AND SOFTWARE SUPPORT AGREEMENT (the
                    "Agreement")
                  </strong>
                </Div>

                <Div marginY={4}>
                  <hr />
                </Div>

                <a
                  href={routes.ServiceLevelAgreements}
                  className="d-print-none"
                >
                  Previous Service Level Agreements
                </a>

                <Div classNames={["t-row"]} flexJustify="center">
                  <strong>Updates to the Agreement</strong>
                </Div>
                <strong>7. TERM</strong>
                <p>Article updated:</p>
                <p>
                  The Software license granted in this Agreement is valid for
                  the period of time beginning on the earlier of the date you
                  select or the date upon which this Agreement was acknowledged
                  and accepted, and shall continue until June 30, 2027 (the
                  "Initial Term"). Upon the expiration of the Initial Term or
                  any renewal term (defined herein) which for the avoidance of
                  doubt shall be June 30 of such year, this Agreement shall
                  automatically renew for a 12-month period beginning on July
                  1and ending on June 30 of such year (any such 12-month
                  renewal, a "Renewal Term" and together with the Initial Term,
                  the "Term") so long as Client has continued to make payments
                  under a paid support agreement, and unless sooner terminated.
                  The annual recurring software price as per the purchase order
                  and payment processing transaction fees as per the offer email
                  with subject line "New Processor Offer Letter (with enclosed
                  updated Terms & Conditions, Acknowledgement Form)" that was
                  sent to Client will not change during the Initial Term.
                </p>
                <p>
                  Client may terminate this Agreement at any time by notifying
                  us in writing at least thirty (30) days in advance of any
                  proposed termination. InTouch Receipting may terminate this
                  Agreement if Client commits a material breach of any of the
                  terms or conditions of this Agreement or fails to pay the
                  required fees for Support/Maintenance, but only after thirty
                  (30) days written notice to Client and an opportunity to cure
                  any such breach or failure to pay. Upon any termination of the
                  license, you will promptly return or destroy, as instructed by
                  us, all Software provided to you, destroy all copies of the
                  Software made by you, and, upon our request, confirm in
                  writing to us that you have complied with your obligations
                  under this paragraph 7. Further, if this Agreement is
                  terminated by Client prior to the conclusion of the Initial
                  Term (be that through explicit termination of this Agreement
                  or through failure to meet obligations under any paid support
                  agreement entered into between Client and InTouch Receipting),
                  the annual recurring software amount for the full term as
                  described in the purchase order shall be immediately due and
                  payable.
                </p>
                <p>
                  Upon termination of the Software license by lapse of time or
                  otherwise, the Software shall cease to function or the client
                  will stop using the software. If the software is hosted by
                  InTouch receipting, upon termination, InTouch will provide a
                  complete MS SQL backup of data. If the software is hosted by
                  the district or other organization, the database backup should
                  be requested by the Client. The client should print out all
                  reports in detail for future reference. The client could
                  request a custom data query from InTouch which would be
                  provided at standard rates for data extract with output in a
                  format requested by the client and supported by MS SQL.
                  Additionally, the client has the ability to use the software
                  to generate tab-delimited extracts for transactional data as
                  part of the core functionality. A MS SQL data backup can be
                  requested at any time only subject to fees related to InTouch
                  technical support time required to run the SQL backup and
                  electronically provide to the district.
                </p>

                <strong>11. ASSIGNMENT OF AGREEMENT</strong>
                <p>Article updated:</p>
                <p>
                  The Client may not assign, delegate, subcontract or otherwise
                  transfer (including by operation of law or change of control)
                  any or all of its rights or responsibilities under this
                  Agreement, in whole or in part, without the prior written
                  consent of the other Party, which consent shall not be
                  unreasonably withheld. Any attempted assignment in
                  contravention of the foregoing shall be void. InTouch
                  Receipting may assign, delegate, subcontract or otherwise
                  transfer any or all of its rights or responsibilities under
                  this Agreement in its discretion, provided that the assignee
                  accepts all of the obligations set forth related to such
                  assignment, delegation, subcontract or transfer.
                </p>

                <strong>12. SERVICE PROVIDERS</strong>
                <p>New article inserted:</p>
                <p>
                  The Parties agree and acknowledge that certain of the Services
                  are provided in connection with third-party service providers,
                  as may be substituted or added from time-to-time by InTouch
                  Receipting in its sole discretion (collectively, the "Service
                  Providers"). Further, the Parties acknowledge that in certain
                  circumstances InTouch Receipting may be required to engage
                  multiple Service Providers to perform the same Service with
                  respect to different transactions, such as may occur with a
                  migration to a different Service Provider or as necessitated
                  by volume or activity considerations or other circumstances.
                  In order to use those certain Services, the Client must agree
                  to and abide by the terms of service and policies of the
                  applicable Service Providers. By signing this Agreement, the
                  Client accepts and agrees to the terms of service and other
                  polices of the Service Providers, as may be updated from time
                  to time.
                </p>
                <p>
                  Credit and Debit Card Payment Processing. In order to use the
                  credit and debit card payment functionality of InTouch
                  Receipting, you must accept and agree and do accept and agree
                  to these{" "}
                  <a href="https://www.intouchreceipting.com/uspaymentterms">
                    terms and conditions
                  </a>
                  , which incorporate by reference terms and conditions of the
                  applicable third-party Service Providers.
                </p>

                <strong>25. AMENDMENTS</strong>
                <p>New article inserted:</p>
                <p>
                  From time to time, we may amend any provision or provisions of
                  this Agreement by sending ninety (90) days' written notice to
                  the Client of the amendment prior to the effective date of the
                  amendment, and the amendment will become effective unless
                  InTouch Receipting receives Client's notice of written
                  termination of this Agreement before such effective date.
                  Except as set forth explicitly herein, no amendment to this
                  Agreement shall be valid unless in writing and signed by an
                  authorized representative of each of the Parties.
                  Notwithstanding the foregoing, the following provisions may be
                  amended from time to time by InTouch Receipting, in its sole
                  discretion, upon thirty (30) days' written notice to the
                  Client:
                </p>
                <Div marginStart={4}>
                  <p>(a) any training services and associated costs;</p>
                  <p>(b) The URL of the Website</p>
                </Div>

                <Div marginY={4}>
                  <hr />
                </Div>

                {/* <Div classNames={["t-row"]} flexJustify="center">
              <strong>
                SOFTWARE LICENSE AND SOFTWARE SUPPORT AGREEMENT (the
                "Agreement")
              </strong>
            </Div> */}
                <p>
                  The following are the terms and conditions (this "Agreement")
                  under which Integrated Register Systems, Inc., dba{" "}
                  <strong>InTouch Receipting</strong> ("we", "us" or "InTouch
                  Receipting") <strong>a subsidiary of KEV Group Inc.</strong>{" "}
                  agrees to license the proprietary computer programs listed
                  below, along with all related documentation supplied
                  therewith, (collectively, the "Software") to the above- named
                  Client ("you" or "Client"). The term "Software" includes any
                  corrections, improvements and enhancements to the Software
                  programs and documentation provided to you by us.
                </p>
                <p>
                  <strong>
                    SOFTWARE: As listed in the purchase order and in Appendix A
                    as available
                  </strong>
                </p>
                <p>
                  BY INSTALLING THE SOFTWARE, USING THE SOFTWARE OR AUTHORIZING
                  ANY OTHER PERSON TO DO SO, YOU ACCEPT THIS AGREEMENT
                </p>
                <p>
                  USING THE SOFTWARE OPERATES AS YOUR CONSENT TO THE
                  TRANSMISSION OF CERTAIN COMPUTER INFORMATION DURING ON-LINE
                  AUTHORIZATION, VALIDATION AND USE OF THE SOFTWARE. CURRENT AND
                  PAID SOFTWARE SUPPORT AGREEMENT IS REQUIRED TO USE ANY OF THE
                  SOFTWARE APPLICATIONS.
                </p>
                <strong>1. LICENSE</strong>
                <p>
                  In consideration of payment of the license fee for the
                  Software, the amount of which is shown on your Order
                  Confirmation, attached hereto and by this reference made a
                  part hereof, we hereby grant to you and you hereby accept from
                  us a non-exclusive, non-transferable and revocable twelve (12)
                  month license to use the Software, in compiled, object code
                  form only, in accordance with the terms and conditions of this
                  Agreement. The Software may be used only by you for your
                  internal business purposes. Some of the Software may be owned
                  by third parties and sublicensed to you. You acknowledge that
                  the Software is protected by United States copyright law and
                  international treaty provisions, and that the Software
                  programs contain trade secrets of ours and our third-party
                  licensors. All right, title and interest in and to the
                  Software belong to, and remain in, us and our licensors,
                  except for the right to use it in accordance with this
                  Agreement. You agree not to reverse engineer, disassemble, or
                  reverse compile any Software object code, or otherwise derive
                  any source code therefore. Except as expressly permitted in
                  this Agreement or the applicable documentation, you agree not
                  to modify, customize, adapt, translate, enhance or change any
                  of the Software, or create any derivative works based on the
                  Software, or otherwise reduce the Software to any humanly
                  perceivable form nor permit any third party not authorized by
                  us to do so.
                </p>
                <p>
                  You may copy the Software programs as necessary for backup and
                  archival purposes and as is essential to use them on a
                  computer or computer network, as described below. You may not
                  otherwise copy the Software for any purpose. You agree not to
                  remove, nor permit the removal of, any proprietary notice or
                  legend contained on or in the Software, and will include all
                  notices included in and on the original media ("Media") in and
                  on all copies made by you.
                </p>
                <p>
                  All right, title and interest in or related to the Software,
                  is and will remain the exclusive property of InTouch
                  Receipting whether specifically recognized or perfected under
                  local applicable law. The source code, object code and
                  embodied trade secrets are not licensed to you.
                </p>
                <p>
                  You agree that you will take no action that reasonably
                  jeopardizes InTouch Receipting's proprietary rights or acquire
                  any right in the Software, except for the license granted
                  under this Agreement. InTouch Receipting will own all rights
                  in any copy, translation, modification, adaptation or
                  derivation of the Software, including any improvements or
                  developments of the Software.
                </p>
                <strong>2. SERVICES, MAINTENANCE AND ERROR CORRECTION</strong>
                <p>
                  In the absence of a separate written agreement between Client
                  and InTouch Receipting concerning Software support and
                  maintenance services ("Support/Maintenance"), Client agrees
                  that all Support/Maintenance shall be provided solely and
                  exclusively by InTouch Receipting. InTouch Receipting will
                  provide Support/Maintenance assistance to the Client when the
                  Client experiences a malfunction or error with the use of the
                  Software.
                </p>
                <p>
                  Support services include the licensed modules and interfaces
                  in the Client's purchase order and subsequent payment. If
                  there was an RFP or formal quote any special provisions
                  included in the RFP/Quote document would supersede this
                  Agreement.
                </p>
                <Div marginStart={4}>
                  <p>
                    a.{" "}
                    <strong>
                      The Software Support Agreement and Help Desk Functions
                    </strong>
                  </p>
                  <Div marginStart={4}>
                    <ol>
                      <li>
                        Correct any defects or replace any of the Software found
                        to be in substantial non-conformance with the program
                        specifications;
                      </li>
                      <li>
                        Provide the Client with enhancements, as they become
                        available, to the Software. InTouch Receipting reserves
                        the right to determine what constitutes an enhancement;
                        enhancements and Software updates are included as part
                        of this Agreement.
                        <br />
                        Enhancements and updates under this Agreement are those
                        provided under the standard InTouch Receipting Software
                        update/build not custom programming or report
                        preparation;
                      </li>
                      <li>
                        Provide reasonable remote technical Support/Maintenance
                        assistance and consultation to the Client with respect
                        to use of the Software;
                      </li>
                      <li>Not to include updates to the Client's operating</li>
                      <li>
                        Help desk functions are to assist in Client questions
                        about Software use, reporting, best practices and will
                        be provided by phone, email, web, remote sessions and
                        other methods providing best service to the Help desk
                        functions are not designed as a substitute for training.
                      </li>
                      <li>
                        Users of InTouch Receipting will be invited to user
                        group meetings at a venue set by InTouch Receipting.
                        There may be an additional fee for attending user group
                        meetings, travel and other costs are the responsibility
                        of the Client.
                      </li>
                    </ol>
                  </Div>
                </Div>
                <p>
                  Any assistance required to fulfill InTouch Receipting's
                  obligations under this Agreement, will be provided at no
                  additional charge unless such assistance is found to be the
                  result of Client's: hardware failure, user error, neglect or
                  negligence. Assistance required as result of such failure,
                  user error, neglect or negligence will be compensated by the
                  Client at InTouch Receipting's standard hourly rate then in
                  effect, plus reasonable expenses for transportation, meals and
                  lodging.
                </p>
                <Div marginStart={4}>
                  <p>
                    b. <strong>Excluded Service</strong>
                  </p>
                </Div>
                <p>
                  InTouch Receipting is not obligated to provide service under
                  the Agreement for service necessitated by vandalism, theft,
                  misuse, hardware malfunction, Client neglect, acts of third
                  parties, fire, water, casualty, act of God, mob violence,
                  labor disputes, malfunction of affiliated equipment,
                  unauthorized modification of the Software, electrical failure,
                  accidents, climatic conditions, or from other causes unrelated
                  to the ordinary operation of the Software.
                </p>
                <p>
                  InTouch excludes services to protect data, data transmission,
                  and data access by unauthorized persons by any methods
                  including software with malicious intent. This Agreement is
                  void if service is performed on the Software by unauthorized
                  individuals, or if attempts are made to modify the Software or
                  use the Software in a manner not set out in the instruction
                  manual or license agreement. Should it be necessary to perform
                  services outlined in this Agreement at the Client's site,
                  Client shall be responsible for reasonable actual
                  out-of-pocket expenses incurred in travel.
                </p>
                <Div marginStart={4}>
                  <p>
                    c. <strong>Response Time</strong>
                  </p>
                </Div>
                <p>
                  There is no guarantee regarding the resolution time to a
                  service call from the Client, however, InTouch Receipting will
                  extend efforts to respond promptly and triage the service
                  call. InTouch Receipting will respond to Software service
                  calls in the order received and will make every effort to
                  respond within the same work day. Resolution capability
                  depends on if InTouch Receipting or the Client is hosting the
                  applications. If the Client is self-hosting, resolution time
                  is dependent on remote access and other Client restrictions.
                  If InTouch Receipting is hosting the applications, our
                  resolution time is not as dependent on the Client technical
                  staff.
                </p>
                <p>
                  InTouch Receipting, however, has established a priority coding
                  system for each problem reported.
                </p>
                <Div marginStart={4}>
                  <Div marginStart={4}>
                    <ol>
                      <li>
                        'A' Priority - A Software error renders the entire
                        system inoperable. Resources assigned within one (1)
                        hour. Resources continue to work on the problem during
                        normal business hours (and then some) until the problem
                        is Average resolution time in a few hours and depends on
                        Client technical staff in most cases to resolve.
                      </li>
                      <li>
                        'B' Priority - A Software error is detected for a system
                        module which seriously impairs systems operations, but
                        does not render it "down." Resources are assigned within
                        four (4) hours and remain assigned until completed, work
                        continues during normal business hours.
                      </li>
                      <li>
                        'C' Priority - Minor problem, but sufficient severity as
                        to warrant correction before the next Software release.
                        Generally completed within 30 days.
                      </li>
                      <li>
                        'D' Priority - "Would like" and minor problems which
                        will be incorporated in future Software releases.
                        InTouch Receipting reserves the right to determine
                        "would like" relevance to the package and the scheduling
                        of the development and release of such items.
                      </li>
                    </ol>
                  </Div>
                </Div>
                <Div marginStart={4}>
                  <p>
                    d. <strong>Excused Performance</strong>
                  </p>
                </Div>
                <p>
                  Neither party shall be liable for any failure to perform or
                  delayed performance of any obligation under this Agreement if
                  such performance is prevented, hindered or delayed for any
                  cause beyond its reasonable control of, including, without
                  limitation, any labor dispute, strike or other industrial
                  disturbance, act of God, flood, shortage of materials,
                  earthquakes, casualty, war, act of public enemy, riot,
                  insurrection, embargo law, blockage, action, restriction and
                  regulation or order of any government, government agency or
                  subdivision thereof, or climatic conditions.
                </p>
                <strong>3. AUTHORIZED USE</strong>
                <p>
                  The number of copies/sites of Software licensed by you is
                  specified by number of sites, ADM (average daily (school)
                  membership) or other methods in the purchasing process. You
                  may use each licensed copy of the Software programs on many
                  terminals in a building without restriction. For the purposes
                  of a site license, a site is considered a high school, a
                  middle school, district office, and alternative school, for
                  example. There may be more than one site license in a building
                  for a student store or other specialized use - these sites
                  will require a support agreement and a software licensing
                  agreement.
                </p>
                <p>
                  The Software may require the entry of license codes in order
                  to operate outside the term of this Agreement. You acknowledge
                  that a signed copy of this Agreement must be sent, via fax,
                  email, or by delivering the original signed version of this
                  Agreement to us in order to receive the codes required to
                  operate the Software after the contracted period.
                </p>
                <strong>4. VALIDATION</strong>
                <p>
                  During installation, un-installation, updating, any license
                  management activities and the use of the Software, the
                  Software will send information to InTouch Receipting about the
                  Software, the Terminal (the terminal the computer where
                  InTouch is operating) and information about the Software
                  currently or previously installed on such Terminal. The
                  information sent to InTouch Receipting may include, but is not
                  limited to, information derived from the hardware
                  configuration of the Terminal, such as Hard Drive serial
                  number, NIC card MAC address, BIOS firmware, CPU type and OS
                  type, and (ii) information about existing or past Software
                  installations and items of a similar nature, (iii) most recent
                  online transaction, (iv) licensed site count and active site
                  count, (v) number of credit card transactions, (vi) duplicate
                  account codes. By using the Software, you consent to the
                  transmission of this information. Under no circumstances will
                  student data be transferred to InTouch Receipting or elsewhere
                  unless it is part of a support or programming effort approved
                  by the Client, which approval shall be given or withheld at
                  the sole discretion of the Client. InTouch Receipting respects
                  the confidentiality of all information contained in the
                  database and under no circumstances will InTouch Receipting
                  share this information with any party. InTouch Receipting does
                  not and will not store credit card information in any
                  application.
                </p>
                <strong>5. CONFIDENTIALITY</strong>
                <p>
                  For purposes of this Agreement, "
                  <strong>Confidential Information</strong>" means any data or
                  information obtained from one party hereunder and provided to
                  the other party, that is valuable and not generally known by
                  the public, including without limitation, any data or
                  information defined herein as a Trade Secret, but which is
                  determined by a court of competent jurisdiction not to rise to
                  the level of a trade secret under applicable law. "
                  <strong>Proprietary Information</strong>" means, collectively,
                  Confidential Information and Trade Secrets. "
                  <strong>Trade Secret</strong>" means information without
                  regard to form, which: (a) derives economic value, actual or
                  potential, from not being generally known to, and not being
                  readily ascertainable by proper means by other persons who can
                  obtain economic value from its disclosure or use, and (b) is
                  the subject of efforts that are reasonable under the
                  circumstances to maintain its secrecy. "
                  <strong>Disclosing Party</strong>" means the party disclosing
                  Proprietary Information, whether such party is you or us, and
                  "<strong>Recipient</strong>" is the party receiving
                  Proprietary Information, whether such party is you or us.
                  InTouch Receipting and Client
                </p>
                <p>
                  acknowledge and agree that during the term of this Agreement
                  each party will have access to and disclose to the other
                  Proprietary Information. Client acknowledges and agrees that
                  the object code and source code of the Software contain Trade
                  Secrets of InTouch Receipting and the Software contains Trade
                  Secrets of InTouch Receipting's licensors. Each party
                  acknowledges the risk due to unauthorized disclosure or
                  unauthorized use of Disclosing Party's Proprietary Information
                  will cause great injury and harm to the Disclosing Party.
                  Recipient covenants and agrees that it shall not, without the
                  prior written consent of Disclosing Party, or as set forth
                  herein, directly or indirectly, (i) disclose, divulge,
                  distribute, publish, reproduce, decompile, reverse engineer,
                  transmit or transfer to others Disclosing Party's Proprietary
                  Information, or any portions thereof, by any means or in any
                  form,
                </p>
                <p>
                  (ii) make use of the Proprietary Information other than as
                  expressly permitted under this Agreement, or (iii) disclose,
                  in whole or in part, any of Disclosing Party's Proprietary
                  Information to any individual, entity or other person, except
                  to those of Recipient's employees or representatives who (a)
                  require access for Recipient's authorized use of Disclosing
                  Party's Proprietary Information, and (b) agree to comply with
                  the use and non- disclosure restrictions stated in this
                  Agreement. Recipient may not use Trade Secret information
                  contained in the Software to develop computer programs that
                  interface or interact with the Software. If an unauthorized
                  use or disclosure occurs, Recipient will immediately notify
                  Disclosing Party and assist Disclosing Party in recovering
                  Disclosing Party's Proprietary Information and prevent its
                  subsequent unauthorized use or dissemination. The restrictions
                  set forth herein shall continue (i) with respect to the Trade
                  Secrets for as long as such information continues to be a
                  Trade Secret under applicable law, and (ii) with respect to
                  Confidential Information, for a period of five (5) years from
                  the date of expiration or termination of this Agreement.
                </p>
                <strong>6. CLIENT RESPONSIBILITIES</strong>
                <p>
                  You agree to: (a) upon the execution of this Agreement
                  designate in writing individuals as the representatives of
                  Client (the "<strong>Client Representatives</strong>"), who
                  shall be authorized to make decisions, approve plans, and
                  grant requests on your behalf in connection with the
                  installation of the Software, and (b) reasonably cooperate
                  with us by, among other things, making available as reasonably
                  required by us, management decisions and personnel in order
                  that our work contemplated hereby may be properly accomplished
                  during the term hereof. You hereby authorize us to rely on all
                  communications from and decisions of the Client
                  Representatives.
                </p>
                <strong>7. TERM</strong>
                <p>
                  The Software license granted in this Agreement is valid for
                  the period of time beginning on the earlier of the date you
                  select or the date upon which this Agreement was acknowledged
                  and accepted, and shall continue until June 30, 2027 (the
                  "Initial Term"). Upon the expiration of the Initial Term or
                  any renewal term (defined herein) which for the avoidance of
                  doubt shall be June 30 of such year, this Agreement shall
                  automatically renew for a 12-month period beginning on July
                  1and ending on June 30 of such year (any such 12-month
                  renewal, a "Renewal Term" and together with the Initial Term,
                  the "Term") so long as Client has continued to make payments
                  under a paid support agreement, and unless sooner terminated.
                  The annual recurring software price as per the purchase order
                  and payment processing transaction fees as per the offer email
                  with subject line "New Processor Offer Letter (with enclosed
                  updated Terms & Conditions, Acknowledgement Form)" that was
                  sent to Client will not change during the Initial Term.
                </p>
                <p>
                  Client may terminate this Agreement at any time by notifying
                  us in writing at least thirty (30) days in advance of any
                  proposed termination. InTouch Receipting may terminate this
                  Agreement if Client commits a material breach of any of the
                  terms or conditions of this Agreement or fails to pay the
                  required fees for Support/Maintenance, but only after thirty
                  (30) days written notice to Client and an opportunity to cure
                  any such breach or failure to pay. Upon any termination of the
                  license, you will promptly return or destroy, as instructed by
                  us, all Software provided to you, destroy all copies of the
                  Software made by you, and, upon our request, confirm in
                  writing to us that you have complied with your obligations
                  under this paragraph 7. Further, if this Agreement is
                  terminated by Client prior to the conclusion of the Initial
                  Term (be that through explicit termination of this Agreement
                  or through failure to meet obligations under any paid support
                  agreement entered into between Client and InTouch Receipting),
                  the annual recurring software amount for the full term as
                  described in the purchase order shall be immediately due and
                  payable.
                </p>
                <p>
                  Upon termination of the Software license by lapse of time or
                  otherwise, the Software shall cease to function or the client
                  will stop using the software. If the software is hosted by
                  InTouch receipting, upon termination, InTouch will provide a
                  complete MS SQL backup of data. If the software is hosted by
                  the district or other organization, the database backup should
                  be requested by the Client. The client should print out all
                  reports in detail for future reference. The client could
                  request a custom data query from InTouch which would be
                  provided at standard rates for data extract with output in a
                  format requested by the client and supported by MS SQL.
                  Additionally, the client has the ability to use the software
                  to generate tab-delimited extracts for transactional data as
                  part of the core functionality. A MS SQL data backup can be
                  requested at any time only subject to fees related to InTouch
                  technical support time required to run the SQL backup and
                  electronically provide to the district.
                </p>
                <p>
                  THIS AGREEMENT WILL AUTOMATICALLY RENEW FOR A 12-MONTH PERIOD
                  with paid support agreement and neither party requesting
                  termination.
                </p>
                <strong>8. INFRINGEMENT WARRANTY AND INDEMNITY</strong>
                <p>
                  Notwithstanding anything herein to the contrary, we warrant to
                  you that: (a) we have title to, or the authority to grant
                  sublicenses to, the Software, and (b) the Software, as
                  delivered by us, will not purposefully infringe any copyright,
                  patent, trade secret, or other intellectual property rights
                  enforceable in the United States of America.
                </p>
                <strong>9. DISCLAIMER OF WARRANTIES</strong>
                <p>
                  Unless expressly stated otherwise herein, THE SOFTWARE IS
                  PROVIDED TO CLIENT IN "<strong>AS-IS</strong>" CONDITION WITH{" "}
                  <strong>NO WARRANTY</strong>. INTOUCH RECEIPTING{" "}
                  <strong>DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES</strong>,
                  EXPRESS OR IMPLIED, REGARDING THE SOFTWARE, MEDIA, AND RELATED
                  MATERIALS, INCLUDING ANY REPRESENTATION AND WARRANTY OF
                  QUALITY, SECURITY, OR ACCURACY AND THE{" "}
                  <strong>
                    IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, AND
                    MERCHANTABILITY
                  </strong>
                  . INTOUCH RECEIPTING DOES NOT WARRANT THAT THE SOFTWARE IS
                  FREE FROM BUGS, VIRUSES, ERRORS, OR OTHER DEFECTS. IF CLIENT
                  IS A RESIDENT OF A JURISDICTION THAT DOES NOT ALLOW THE
                  EXCLUSION OF IMPLIED WARRANTIES, THE ABOVE EXCLUSIONS DO NOT
                  APPLY, AND THE IMPLIED WARRANTIES ARE LIMITED IN DURATION TO
                  SIXTY (60) DAYS FROM YOUR FIRST USE OF THE SOFTWARE.
                </p>
                <strong>10. LIMITATION OF LIABILITY</strong>
                <p>
                  IN NO EVENT WILL INTOUCH RECEIPTING, ITS SUBSIDIARIES, OR
                  LICENSORS, OR ANY OF THE DIRECTORS, OFFICERS, EMPLOYEES, OR
                  AFFILIATES OF ANY OF THE FOREGOING BE LIABLE TO CLIENT UNDER
                  ANY CLAIM ALLEGING, AMONG OTHER THINGS, BREACH OF CONTRACT,
                  NEGLIGENCE, STRICT LIABILITY, PRODUCT LIABILITY, OR OTHER
                  LEGAL OR EQUITABLE THEORY FOR ANY DIRECT, CONSEQUENTIAL,
                  INCIDENTAL, INDIRECT, PUNITIVE OR SPECIAL DAMAGES WHATSOEVER
                  (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS
                  PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION),
                  WHETHER FORESEEABLE OR UNFORESEEABLE, OR FOR COST OF
                  PROCUREMENT OF SUBSTITUTE GOODS, TECHNOLOGY, OR SERVICES,
                  REGARDLESS OF THE BASIS OF THE CLAIM AND EVEN IF INTOUCH
                  RECEIPTING HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE
                  AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED
                  OF ITS ESSENTIAL PURPOSE. INTOUCH RECEIPTING'S CUMULATIVE
                  LIABILITY FOR DAMAGES FOR ANY CAUSE WHATSOEVER, AND REGARDLESS
                  OF THE FORM OF THE ACTION, WILL BE LIMITED TO THE AMOUNT OF
                  MONEY PAID TO INTOUCH RECEIPTING BY CLIENT FOR THE PURCHASE OF
                  THE LICENSE OF THE SOFTWARE THAT CAUSED THE DAMAGES. SOME
                  JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OF INCIDENTAL DAMAGES.
                  ACCORDINGLY, THE LIMITATIONS AND EXCLUSIONS SET FORTH ABOVE
                  MAY NOT APPLY TO CLIENT. THE LIMITATIONS OF DAMAGES SET FORTH
                  ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
                  BETWEEN INTOUCH RECEIPTING AND CLIENT. INTOUCH RECEIPTING
                  WOULD NOT HAVE PROVIDED THE SOFTWARE WITHOUT SUCH LIMITATIONS.
                </p>
                <strong>11. ASSIGNMENT OF AGREEMENT</strong>
                <p>
                  The Client may not assign, delegate, subcontract or otherwise
                  transfer (including by operation of law or change of control)
                  any or all of its rights or responsibilities under this
                  Agreement, in whole or in part, without the prior written
                  consent of the other Party, which consent shall not be
                  unreasonably withheld. Any attempted assignment in
                  contravention of the foregoing shall be void. InTouch
                  Receipting may assign, delegate, subcontract or otherwise
                  transfer any or all of its rights or responsibilities under
                  this Agreement in its discretion, provided that the assignee
                  accepts all of the obligations set forth related to such
                  assignment, delegation, subcontract or transfer.
                </p>
                <strong>12. SERVICE PROVIDERS</strong>
                <p>
                  The Parties agree and acknowledge that certain of the Services
                  are provided in connection with third-party service providers,
                  as may be substituted or added from time-to-time by InTouch
                  Receipting in its sole discretion (collectively, the "Service
                  Providers"). Further, the Parties acknowledge that in certain
                  circumstances InTouch Receipting may be required to engage
                  multiple Service Providers to perform the same Service with
                  respect to different transactions, such as may occur with a
                  migration to a different Service Provider or as necessitated
                  by volume or activity considerations or other circumstances.
                  In order to use those certain Services, the Client must agree
                  to and abide by the terms of service and policies of the
                  applicable Service Providers. By signing this Agreement, the
                  Client accepts and agrees to the terms of service and other
                  polices of the Service Providers, as may be updated from time
                  to time.
                </p>
                <p>
                  Credit and Debit Card Payment Processing. In order to use the
                  credit and debit card payment functionality of InTouch
                  Receipting, you must accept and agree and do accept and agree
                  to these{" "}
                  <a href="https://www.intouchreceipting.com/uspaymentterms">
                    terms and conditions
                  </a>
                  , which incorporate by reference terms and conditions of the
                  applicable third-party Service Providers.
                </p>
                <strong>13. ADDITIONAL LICENSE AGREEMENTS</strong>
                <p>
                  InTouch Receipting software operates under Microsoft SQL
                  database and Operating systems - these systems are not
                  included in the scope of this Agreement.
                </p>
                <strong>14. GOVERNING LAW</strong>
                <p>
                  The validity and performance of this Agreement shall be
                  governed by Washington law (without reference to choice of law
                  principles), except as to copyright and trademark matters,
                  which are covered by federal laws. This Agreement is deemed
                  entered into in Tacoma, WA and shall be construed as to its
                  fair meaning and not strictly for or against either party.
                </p>
                <strong>15. NO WAIVER</strong>
                <p>
                  Any failure by either party to detect, protest, or remedy any
                  breach of this Agreement shall not constitute a waiver or
                  impairment of any such term or condition, or the right of such
                  party at any time to avail itself of such remedies as it may
                  have for any breach or breaches of such term or condition. A
                  waiver may only occur pursuant to the prior written express
                  permission of an authorized officer of the other party.
                </p>
                <strong>16. SEVERABILITY</strong>
                <p>
                  If any provision hereof is declared invalid by a court of
                  competent jurisdiction, such provision shall be ineffective
                  only to the extent of such invalidity, so that the remainder
                  of that provision and all remaining provisions of this
                  Agreement will continue in full force and effect.
                </p>
                <strong>17. HEADINGS</strong>
                <p>
                  Headings used in this Agreement are for convenience only and
                  shall not be considered in construing or interpreting this
                  Agreement.
                </p>
                <strong>18. EXPORT RESTRICTIONS</strong>
                <p>
                  Client acknowledges and agrees that the Software is subject to
                  restrictions and controls imposed by the Export Administration
                  Act and the Export Administration Regulations ("the Acts").
                  Client agrees and certifies that neither the Software nor any
                  direct product thereof is being or will be used for any
                  purpose prohibited by the Acts. Client agrees and certifies
                  that Client is not a citizen or permanent resident of Cuba,
                  Iran, North Korea, Libya, Sudan or Syria.
                </p>
                <strong>19. U.S. GOVERNMENT</strong>
                <p>
                  The Software is a "commercial item," as that term is defined
                  at 48 C.F.R. 2.101 (OCT 1995), consisting of "commercial
                  computer software" <strong>AND </strong>
                  "commercial computer software documentation," as such terms
                  are used in 48 C.F.R. 12.212 (SEPT 1995) and the Department of
                  Defense Federal Acquisition Regulations Sections 252.227-
                  7014(a) (1), (5). Consistent with 48 C.F.R. 12.212 and 48
                  C.F.R. 227- 7202-1 through 227-7202-4 (JUNE 1995), all
                </p>
                <p>
                  U.S. Government End Users acquire the Software (or Licensed
                  Product) with only those rights set forth herein.
                </p>
                <strong>20. CONSUMER INFORMATION AND PRIVACY</strong>
                <p>
                  For details about InTouch Receipting's privacy policies,
                  please refer to the InTouch Receipting Privacy Statement
                  contained on a web site designated by InTouch Receipting
                  (www.intouchreceipting.com).
                </p>
                <strong>21. TRADEMARKS</strong>
                <p>
                  InTouch Receipting, the InTouch Receipting logo, InTouch
                  Receipting Manager, TouchBase, and InTouch Receipting Athletic
                  Director, among others, are registered trademarks and/or
                  registered service marks of InTouch Receipting in the United
                  States of America and other countries.
                </p>
                <strong>22. NO LIMITATIONS</strong>
                <p>
                  This Agreement does not limit any rights that InTouch
                  Receipting may have under trade secret, copyright, patent, or
                  other laws. THE RIGHTS AND OBLIGATIONS OF THE PARTIES UNDER
                  THIS AGREEMENT SHALL NOT BE GOVERNED BY THE UNITED NATIONS
                  CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS.
                </p>
                <strong>23. GENERAL</strong>
                <p>
                  All the terms and conditions of this Agreement are binding
                  upon and inure to the benefit of the parties hereto, and their
                  successors, legal representatives and permitted assigns.
                </p>
                <strong>24. ENTIRE AGREEMENT</strong>
                <p>
                  This Agreement constitutes the final, complete and exclusive
                  statement of the agreement between InTouch Receipting and the
                  Client in respect of the subject matter hereof. This Agreement
                  shall govern any services or content related to the Software,
                  unless such services or content are subject to a separate
                  written agreement between Client and InTouch Receipting.
                  However, the limitations of liability and disclaimer of
                  warranties in this Agreement shall apply to InTouch Receipting
                  with respect to such content or services except to the extent
                  provided otherwise in a separate written agreement approved by
                  InTouch Receipting between Client and InTouch Receipting. In
                  all respects, to the extent this Agreement contradicts any
                  terms of service referenced herein, this Agreement governs.
                </p>
                <strong>25. AMENDMENTS</strong>
                <p>
                  From time to time, we may amend any provision or provisions of
                  this Agreement by sending ninety (90) days' written notice to
                  the Client of the amendment prior to the effective date of the
                  amendment, and the amendment will become effective unless
                  InTouch Receipting receives Client's notice of written
                  termination of this Agreement before such effective date.
                  Except as set forth explicitly herein, no amendment to this
                  Agreement shall be valid unless in writing and signed by an
                  authorized representative of each of the Parties.
                  Notwithstanding the foregoing, the following provisions may be
                  amended from time to time by InTouch Receipting, in its sole
                  discretion, upon thirty (30) days' written notice to the
                  Client:
                </p>
                <Div marginStart={4}>
                  <p>(a) any training services and associated costs;</p>
                  <p>(b) The URL of the Website</p>
                </Div>
                <strong>26. INDEMNIFICATION</strong>
                <p>
                  InTouch Receipting agrees to the fullest extent permitted by
                  law, to indemnify and hold harmless Client, its officers,
                  directors, trustees, employees, agents, contractors and
                  consultants against all claims, damages, liabilities, losses,
                  costs and expenses, including reasonable attorneys' fees,
                  experts' fees, and other legal costs to the extent caused by
                  InTouch Receipting's breach of this Agreement or its negligent
                  acts or omissions in its performance of services under this
                  Agreement and that of its officers, employees, subcontractors,
                  or anyone for whom InTouch Receipting is legally liable.
                  InTouch is not required to indemnify for data breeches,
                  malicious software, individuals 'hacking' or injecting
                  software in client-hosted or InTouch-hosted environments.
                </p>
                <p>
                  Client agrees to the fullest extent permitted by law, to
                  indemnify and hold harmless InTouch Receipting, its officers,
                  directors, shareholders, employees and agents against all
                  claims, damages, liabilities, losses, costs and expenses,
                  including reasonable attorneys' fees, experts' fees, and any
                  other legal costs to the extent caused by the negligent acts
                  or omissions of Client, its employees, agents, consultants or
                  anyone for whom Client is legally liable.
                </p>
                <strong>27. INDEPENDENT CONTRACTOR</strong>
                <p>
                  InTouch Receipting shall conduct its services and work as an
                  independent Agent/Contractor with respect to Client. InTouch
                  Receipting agrees that it will, at all times, represent to
                  third persons and to the public generally and to all
                  governmental bodies, including, but not limited to, federal,
                  state or local authorities that the services and work
                  performed by InTouch Receipting with respect to Client is that
                  of an independent agent/contractor and that such is the sole
                  relationship between the parties. It is expressly understood
                  that InTouch Receipting is not in any way constituted the
                  legal representative of Client for any purpose whatsoever.
                  Client shall deduct no income tax or other withholdings
                  whatsoever from payments due InTouch Receipting.
                </p>
                <Div marginTop={4}>
                  <p>
                    <strong>
                      Appendix A - Software Applications Available
                    </strong>
                  </p>
                  <p>
                    InTouch Software Applications include but are not limited
                    to:
                  </p>
                  <p>
                    <em>
                      Client may not have purchased or implemented any of these
                      modules or applications - only licensed modules apply to
                      this agreement
                      <br />
                      <br />
                    </em>
                    <ul>
                      <li>InTouch Receipting</li>
                      <li>InTouch Terminal</li>
                      <li>InTouch Manager</li>
                      <li>InTouch Pay</li>
                      <li>InTouch POS</li>
                      <li>TouchBase</li>
                      <li>Customer Portal</li>
                      <li>Athletic and Activity Module</li>
                      <li>Registrations Module</li>
                      <li>Accounting Interface</li>
                      <li>Student Information Interface</li>
                      <li>Library Interface</li>
                      <li>Student Store Module</li>
                      <li>FundTracker Module</li>
                      <li>Food Service Interface</li>
                      <li>Schedule Interface</li>
                      <li>Lockers Module</li>
                      <li>InTouch Receipting Payment Processing</li>
                      <li>Gates and Concessions Module</li>
                      <li>Payment Pages Module</li>
                    </ul>
                  </p>
                  <p>
                    From time to time, names of modules listed above may change
                    and new modules may be made available for purchase by
                    InTouch Receipting. Any module purchase by a Client and
                    reflected in a Client purchase order and/or InTouch
                    Receipting invoice shall be a licensed module.
                  </p>
                </Div>
              </Div>
            </Div>
          </Div>
        </Page>
      </PrintDocument>
    </>
  );
};

export default USServiceLevelAgreementV3;
