import { useParams } from "react-router-dom";
import USServiceLevelAgreementV1 from "./USServiceLevelAgreement.v1";
import USServiceLevelAgreementV2 from "./USServiceLevelAgreement.v2";
import USServiceLevelAgreementV3 from "./USServiceLevelAgreement.v3";
import Div from "../components/Div";

const USServiceLevelAgreement = () => {
  const params = useParams();
  const version = params["*"];

  switch (version) {
    case "":
    case "v1":
      return <USServiceLevelAgreementV1 />;
    case "v2":
      return <USServiceLevelAgreementV2 />;
    case "v3":
      return <USServiceLevelAgreementV3 />;
  }

  return (
    <Div flex flexJustify="center" margin={5}>
      Not Found
    </Div>
  );
};

export default USServiceLevelAgreement;
