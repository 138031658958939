import { Helmet } from "react-helmet";
import PageHeader from "../components/PageHeader";
import Div from "../components/Div";
import { routes } from "../misc/routes";

const ServiceLevelAgreements = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Service Level Agreements</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <PageHeader title="Service Level Agreements" subtitle="" />

      <Div container>
        <Div row marginTop={4}>
          <Div col>
            <h3>Service Level Agreements</h3>
            <p>
              <a href={routes.USServiceLevelAgreement.replaceAll("*", "v3")}>
                US Service Level Agreement - Current
              </a>
            </p>
            <p>
              <a href={routes.USServiceLevelAgreement.replaceAll("*", "v2")}>
                US Service Level Agreement - v2
              </a>
            </p>
            <p>
              <a href={routes.USServiceLevelAgreement.replaceAll("*", "v1")}>
                US Service Level Agreement - v1
              </a>
            </p>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default ServiceLevelAgreements;
