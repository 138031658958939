import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const FundraisingDonations = () => {
  return (
    <PageWithHeaderCallToAction
      title="Fundraising & Donations"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Fundraising & Donations</title>
        <meta
          name="description"
          content="Custom, dedicated web pages for your activities."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Custom, dedicated web pages for your activities.
      </Div>

      <h5 className="mb-4">
        Put the FUN back into Fundraising with InTouch’s easy to use, and
        profitable fundraising platform.
      </h5>

      <Div row>
        <Div col={12} colMd={6}>
          <ContentSection title="Why InTouch Fundraising?">
            <p>
              Raise money for clubs, teams, and activities with InTouch's
              easy-to-use fundraising and donation platform.
            </p>
            <p>
              Fully integrated with InTouch, so you have complete visibility and
              reporting throughout the fundraiser, making it reliable and secure.
            </p>
            <p>
              Flexible fundraising pages allow districts to customize their
              pages specific to their school, activity, or event.
            </p>
            <p>
              Donors can quickly and easily access the dedicated webpage so they
              can donate to show their support.
            </p>
            <p>
              With InTouch there is only a 3% transaction fee which means{" "}
              <strong>97%</strong> of the money goes to the intended program.
            </p>
            <p>
              3% processing fees compared to other crowdfunding sites, which
              charge up to 30%.
            </p>
          </ContentSection>
        </Div>
        <Div col={12} colMd={6}>
          <ContentSection title="How It Works">
            <ol>
              <li>
                <b>Create</b>
                <ul>
                  <li>
                    InTouch builds the specialized page for your activity/event
                    using your personalized photos, videos, etc
                  </li>
                </ul>
              </li>
              <li>
                <b>Launch</b>
                <ul>
                  <li>
                    Once approved by you, the site is live and easily accessible
                    with a unique URL
                  </li>
                </ul>
              </li>
              <li>
                <b>Promote</b>
                <ul>
                  <li>
                    Spread the word on social media, your district website,
                    flyers, and any other form of communication to get your
                    website out there!
                  </li>
                </ul>
              </li>
              <li>
                <b>Profit</b>
                <ul>
                  <li>
                    Giving your community a new way to support your district
                    with online fundraising/donations will increase your revenue
                    goals
                  </li>
                </ul>
              </li>
            </ol>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default FundraisingDonations;
