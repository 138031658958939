import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import useFetch from "../misc/useFetch";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";

import Compass from "../icons/Compass";
import CheckCircle from "../icons/CheckCircle";
import EnvelopeFill from "../icons/EnvelopeFill";
import TelephoneFill from "../icons/TelephoneFill";
import ExclamationTriangleFill from "../icons/ExclamationTriangleFill";

const Sales = () => {
  const { postJson } = useFetch();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    job: "",
    email: "",
    phone: "",
    districtName: "",
    districtState: "",
    interests: "",
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(
      !form.firstName ||
        !form.lastName ||
        !form.job ||
        !form.email ||
        !form.phone ||
        !form.districtName ||
        !form.districtState ||
        !form.interests
    );
  }, [
    form.firstName,
    form.lastName,
    form.job,
    form.email,
    form.phone,
    form.districtName,
    form.districtState,
    form.interests,
  ]);

  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);

  return (
    <Div flex flexColumn height={100}>
      <Helmet titleTemplate="InTouch | %s">
        <title>Sales</title>
        <meta
          name="description"
          content="Let us share our amazing platform with your school district."
        />
      </Helmet>

      <PageHeader title="Sales" subtitle="" />
      <Div container flexShrink={0}>
        <Div row marginY={3}>
          <Div col>
            <h4 className="mt-2 mb-3">Corporate Office</h4>
            <Div>
              <Div>
                <span className="me-2">
                  <Compass />
                </span>
                420 N Meridian Street
              </Div>
              <Div>
                <span className="me-2 invisible">
                  <Compass />
                </span>
                Suite B
              </Div>
              <Div>
                <span className="me-2 invisible">
                  <Compass />
                </span>
                Puyallup, WA 98371
              </Div>
            </Div>
            <Div classNames={["mt-2"]}>
              <TelephoneFill />
              <span className="ms-2">+1 (800) 627-4767</span>
            </Div>
            <Div classNames={["mt-2"]}>
              <TelephoneFill />
              <span className="ms-2">+1 (253) 922-6077</span>
            </Div>
            <Div classNames={["mt-2"]}>
              <EnvelopeFill />
              <a className="ms-2" href="mailto:sales@intouchreceipting.com">
                sales@intouchreceipting.com
              </a>
            </Div>
            <Div classNames={["mt-2"]}>
              <strong>Fax</strong>
              <span className="ms-2">+1 (253) 922-6154</span>
            </Div>

            <h4 className="mt-5 mb-3">Texas Office</h4>
            <Div>
              <Div>
                <span className="me-2">
                  <Compass />
                </span>
                Dallas, TX
              </Div>
            </Div>
            <Div classNames={["mt-2"]}>
              <TelephoneFill />
              <span className="ms-2">+1 (800) 627-4767</span>
            </Div>
          </Div>

          <Div colMd={6} marginTop={5} marginTopMd={0}>
            <h4 className="mt-2 mb-3">Request Information</h4>
            <p>Thank you for your interest in InTouch.</p>
            <p>We look forward to speaking with you soon!</p>
            {/* <Div className="d-none">
              <iframe
                title="Request Information"
                width="600px"
                height="480px"
                src="https://forms.office.com/Pages/ResponsePage.aspx?id=nToSEJgNvkCfCCJxPAcBJlC7xxzIhAZApFTYoesGDZxUNFRURFRGTzhXSUZEM0dVQTRYQTlBUzU2NS4u&embed=true"
                frameBorder="0"
                marginWidth={0}
                marginHeight={0}
                style={{ border: "none", maxHeight: "100vh" }}
                allowFullScreen
              ></iframe>
            </Div> */}
            <form>
              <Div row classNames={["g-3"]}>
                <Div col={12} colMd={6}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={form.firstName}
                    onChange={(e) => {
                      setForm({ ...form, firstName: e.currentTarget.value });
                    }}
                  />
                </Div>
                <Div col={12} colMd={6}>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={form.lastName}
                    onChange={(e) => {
                      setForm({ ...form, lastName: e.currentTarget.value });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="inputJobTitle">Job Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputJobTitle"
                    value={form.job}
                    onChange={(e) => {
                      setForm({ ...form, job: e.currentTarget.value });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="emailAddress">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailAddress"
                    value={form.email}
                    onChange={(e) => {
                      setForm({ ...form, email: e.currentTarget.value });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="inputPhoneNumber">Phone #</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPhoneNumber"
                    value={form.phone}
                    onChange={(e) => {
                      setForm({ ...form, phone: e.currentTarget.value });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="districtName">District Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="districtName"
                    value={form.districtName}
                    onChange={(e) => {
                      setForm({ ...form, districtName: e.currentTarget.value });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="districtState">District State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="districtState"
                    value={form.districtState}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        districtState: e.currentTarget.value,
                      });
                    }}
                  />
                </Div>

                <Div col={12}>
                  <label htmlFor="interests">What Are You Interested In?</label>
                  <textarea
                    className="form-control"
                    id="interests"
                    value={form.interests}
                    onChange={(e) => {
                      setForm({ ...form, interests: e.currentTarget.value });
                    }}
                  />
                </Div>
              </Div>

              <button
                type="submit"
                className="btn btn-block btn-primary mt-3"
                disabled={disabled}
                onClick={async (e) => {
                  e.preventDefault();

                  setSendSuccess(false);
                  setSendError(false);
                  setSending(true);

                  try {
                    var result = await postJson(
                      "https://intouchreceipting-api.azurewebsites.net/api/emailForm",
                      {
                        to: "sales-website@intouchreceipting.com",
                        subject: "Information Request",
                        fields: [
                          { name: "First Name", value: form.firstName },
                          { name: "Last Name", value: form.lastName },
                          { name: "Job Title", value: form.job },
                          { name: "Email Address", value: form.email },
                          { name: "Phone #", value: form.phone },
                          { name: "District Name", value: form.districtName },
                          {
                            name: "District State",
                            value: form.districtState,
                          },
                          { name: "Interests", value: form.interests },
                        ],
                      }
                    );

                    setSendSuccess(result.ok);

                    setTimeout(() => setSendSuccess(false), 5000);

                    setForm({
                      firstName: "",
                      lastName: "",
                      job: "",
                      email: "",
                      phone: "",
                      districtName: "",
                      districtState: "",
                      interests: "",
                    });
                  } catch {
                    setSendError(true);
                  } finally {
                    setSending(false);
                  }
                }}
              >
                Submit
                <Div
                  marginStart={2}
                  classNames={[
                    "spinner-border spinner-border-sm",
                    sending ? "" : "d-none",
                  ]}
                  role="status"
                >
                  <span className="visually-hidden">Sending...</span>
                </Div>
                <span
                  className={[sendSuccess ? "ms-2" : "d-none"].toClassName()}
                >
                  <CheckCircle />
                </span>
                <span className={[sendError ? "ms-2" : "d-none"].toClassName()}>
                  <ExclamationTriangleFill />
                </span>
              </button>
              <Div marginY={4}>
                For help making a <strong> purchase or payment</strong>, please{" "}
                <strong>contact your school/district</strong> directly.
                <br />
                <br />
                For all other support inquiries, please email
                <a className="ms-2" href="mailto:support@intouchreceipting.com">
                  support@intouchreceipting.com
                </a>
              </Div>
            </form>
            <Div
              marginTop={3}
              classNames={[
                "alert alert-success",
                sendSuccess ? "" : "d-none",
              ]}
              role="alert"
            >
              <p>Thank you for contacting InTouch.</p>
              <p>We will get back to you ASAP.</p>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default Sales;
