import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const AthleticActivityRegistration = () => {
  return (
    <PageWithHeaderCallToAction
      title="Athletic & Activity Registration"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Athletic & Activity Registration</title>
        <meta
          name="description"
          content="Track rosters, emergency info, payments, and eligibility."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Track rosters, emergency info, payments, and eligibility.
      </Div>

      <h5 className="mb-4">
        Eliminate multiple systems and handwritten forms with InTouch
        Registrations.
      </h5>
      <p>
        Our easy-to-use system makes it easier to monitor necessary school forms
        keeping processes moving, and students participating. InTouch follows
        district financial policies and works with your current systems creating
        ONE DATA SOURCE.
      </p>
      <Div row>
        <Div col={12} colMd={6}>
          <ContentSection title="Key Features">
            <ul>
              <li>Upload & track physicals including expiration dates</li>
              <li>Emergency medical information</li>
              <li>Create custom forms and reports</li>
              <li>Capture signatures</li>
              <li>Collect fees</li>
              <li>Build rosters</li>
              <li>Email notifications</li>
              <li>Accessible from mobile devices anytime</li>
            </ul>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="Ways To Use It">
            <ul>
              <li>Athletics registrations</li>
              <li>Club and activity registrations</li>
              <li>Before and after school childcare</li>
              <li>Summer and athletic camps</li>
              <li>School dance and prom tickets</li>
              <li>Field trips</li>
              <li>Drivers Ed registrations</li>
              <li>AP/IB Test registrations</li>
              <li>... and more</li>
            </ul>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default AthleticActivityRegistration;
