import Div from "../components/Div";

const USPaymentTermsAppendix1Content = () => {
  return (
    <>
      <Div classNames={["t-row"]} flexJustify="center">
        <strong>APPENDIX 1 - EQUIPMENT RENTAL AGREEMENT</strong>
      </Div>
      <p>
        <strong>THIS EQUIPMENT RENTAL AGREEMENT ("AGREEMENT")</strong>
        is made of even date as the Merchant Application and Agreement
        ("Merchant Agreement") by and between Nuvei and Merchant.
      </p>
      <p>
        <strong>WHEREAS</strong> Merchant has entered into a Merchant Agreement
        with Servicer for the processing of payment card transactions;
      </p>
      <p>
        <strong>AND WHEREAS</strong> Nuvei has agreed to rent the
        above-described processing-related equipment ("Equipment") to Merchant;
      </p>
      <p>
        <strong>NOW THEREFORE</strong>, the Nuvei and Merchant agree as follows:
      </p>
      <ol>
        <li style={{ marginBottom: "5px" }}>
          <strong>RENTAL</strong>. Subject to the terms and conditions hereof,
          Nuvei hereby agrees to rent Equipment to Merchant for a monthly fee
          per item of Equipment, the whole as set forth above.
        </li>
        <li style={{ marginBottom: "5px" }}>
          Neither ITR nor Bank are a party to this Equipment Rental Agreement.
        </li>
        <li style={{ marginBottom: "5px" }}>
          <strong>TERM AND TERMINATION. </strong>The term of this Agreement
          shall follow that of the Merchant Agreement, as defined in Section 5
          of the Merchant Agreement, unless sooner terminated pursuant to this
          Section. Merchant may terminate this Agreement by providing no less
          than 30 days' notice to Nuvei. For the avoidance of doubt, Merchant's
          obligation to pay any outstanding fees or other amount hereunder shall
          survive termination of this Agreement for any reason. In the that this
          Agreement terminates for any reason, Merchant shall, within twelve
          (12) weeks of the date of termination, and at Merchant's sole expense,
          return the Equipment to Nuvei in its original condition (save for
          normal wear and tear). If Nuvei does not receive the equipment within
          said timeframe, Merchant agrees to immediately pay to Nuvei the
          amounts corresponding to the unreturned Equipment $200 per terminal.
          <strong> All amounts are in US Dollars.</strong>
        </li>
        <li style={{ marginBottom: "5px" }}>
          <p>
            <strong>Terminal Maintenance Program. </strong>During the term of
            this Merchant Agreement, and provided Merchant is not delinquent in
            respect of any obligation hereunder, Nuvei shall repair or replace,
            at its option, all defective or damaged terminals sold or leased to
            Merchant (the "Terminal Maintenance Program" or "TMP"). Replacement
            terminals may be manufactured from new or refurbished parts; all
            such replacements will be functional equivalents of the replaced
            terminal. Merchant is responsible for ensuring that terminal is
            sufficiently packaged considering the condition of any defective or
            damaged terminal prior to shipment and bears the full risk of loss
            or damage for any terminal that is further made defective or damaged
            as a result of such terminal being improperly packaged upon
            shipment. To receive TMP services, Merchant must (i) notify ITR and
            Nuvei of such defect or damage to a terminal no later than 10
            business days from discovering the defect or when the damage was
            discovered and provide its identification number and terminal serial
            number; (ii) describe the symptoms and causes of terminal problems
            to Nuvei; (iii) follow Nuvei's instructions; and (iv) return the
            defective or damaged terminal to Nuvei no later than twelve (12)
            weeks from discovering the defect or when the damage was discovered.
            Replaced terminals become Nuvei's property. Failure to return any
            replaced terminal within the aforementioned twelve (12) week period
            will result in a charge $200 per terminal. All shipping costs to
            return defective or damaged terminals shall be borne by Merchant.
            For the avoidance of doubt, any further costs including but not
            limited to cost of shipments of replacement terminals, terminal
            re-deployment, key injection costs shall be borne by Nuvei. There
            shall be no additional charges for deployment of replaced terminals
            to the Merchants. TMP does not cover (A) lost or stolen terminals;
            (B) damage caused by Merchant's gross negligence ; (C) unauthorized
            modifications, use of incompatible devices or accessories, extreme
            ambient environments, fluctuation or surges of electrical power,
            electrical or magnetic interference, lightning, static electricity,
            fire, acts of God or other external causes; (D) equipment with
            serial numbers that have been altered, defaced or removed; (E)
            cosmetic damage having no effect on functionality; or (F) consumable
            parts, such as batteries.
          </p>
          <p>
            Merchant will be entitled to receive paper rolls upon request to
            Nuvei, while enrolled in the TMP. Costs for ground shipping shall be
            borne by Nuvei.
          </p>
        </li>

        <li style={{ marginBottom: "5px" }}>
          <strong>REPRESENTATIONS AND WARRANTIES OF NUVEI</strong>. NUVEI MAKES
          ABSOLUTELY NO REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
          WRITTEN OR ORAL, AS TO THE MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE OR NON-INFRINGEMENT OF ANY EQUIPMENT AND EXPRESSLY
          DISCLAIMS SAME.
        </li>
        <li style={{ marginBottom: "5px" }}>
          <strong>INDEMNIFICATION</strong>. Merchant agrees to indemnify and
          hold Nuvei harmless from and against all claims, liability, damage,
          loss or expenses which may occur to or arise from the rented
          equipment, including any costs relating to collections and/or the
          return of such rented equipment.
        </li>
        <li style={{ marginBottom: "5px" }}>
          <strong>LIMITATION OF LIABILITY: AMOUNT. </strong>IN NO EVENT SHALL
          NUVEI BE LIABLE TO MERCHANT FOR ANY LIABILITY, WHETHER BASED UPON
          WARRANTY, CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, IN AN AMOUNT
          GREATER THAN THE WHOLESALE VALUE OF THE RENTED EQUIPMENT.
        </li>
        <li style={{ marginBottom: "5px" }}>
          <strong>LIMITATION OF LIABILITY: DAMAGES. </strong>IN NO EVENT SHALL
          NUVEI BE LIABLE TO MERCHANT FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL,
          EXEMPLARY, PUNITIVE, OR OTHER INDIRECT DAMAGES, OR FOR LOSS OF PROFITS
          AND REVENUE, LOSS OF DATA OR LOSS OF USE, ARISING OUT OF THE
          MANUFACTURE, SALE, SUPPLYING OR FAILURE OR DELAY IN SUPPLYING THE
          RENTED EQUIPMENT, WHETHER BASED UPON WARRANTY, CONTRACT, TORT, STRICT
          LIABILITY OR OTHERWISE, EVEN IF AWARE OF THE POSSIBILITY OF SUCH
          DAMAGES OR LOSSES.
        </li>
        <li style={{ marginBottom: "5px" }}>
          <strong>GENERAL. </strong>
          This Agreement, together with any documents to be delivered pursuant
          hereto, constitutes the entire agreement between the parties
          pertaining to the subject matter hereof and supersede all prior
          agreements, understandings, negotiations, and discussions, whether
          oral or written, of the parties. Any terms used herein but not defined
          shall have the same meaning as is ascribed to such term in the
          Merchant Agreement. This Agreement shall be governed by and
          interpreted exclusively in accordance with the laws of the State of
          Arizona, whose jurisdiction the parties hereto irrevocably submit. All
          amendments to this Agreement must be in writing.
        </li>
      </ol>
    </>
  );
};

export default USPaymentTermsAppendix1Content;
