import { useState } from "react";

import logo from "../img/ITR_KEV_Logo_ByKevGroupFeb24.png";
import Div from "./Div";

const NavBar = ({
  logoHref,
  children,
}: {
  logoHref: string;
  children?: JSX.Element[];
}) => {
  const [show, setShow] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <a className="navbar-brand" href={logoHref}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: "54px" }}
        />
      </a>
      <button
        className={["navbar-toggler", show ? "" : "collapsed"].toClassName()}
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => {
          setShow(children ? !show : false);
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <Div
        classNames={[
          "navbar-collapse",
          "collapse",
          show ? "show" : "",
          "justify-content-end",
        ]}
        id="navbarNav"
        onClick={() => setShow(false)}
      >
        <ul className="navbar-nav">{children}</ul>
      </Div>
    </nav>
  );
};

export default NavBar;
