import { Fragment, ReactNode } from "react";

const For = <T,>({
  each,
  render,
}: {
  each?: T[];
  render: (item: T, index: number) => ReactNode;
}) => {
  return <Fragment>{each?.map((x, i) => render(x, i)) ?? []}</Fragment>;
};

export default For;
