import BootstrapProps from "./BootstrapProps";
import Div from "./Div";
import "./PrintDocument.scss";

import { ReactElement, ReactNode } from "react";

export const PrintDocument = ({
  fontSize,
  fontSizePrint,
  landscape,
  border,
  children,
}: {
  fontSizePrint?: string | number;
  landscape?: boolean;
  border: boolean;
  children: ReactElement;
} & Pick<BootstrapProps, "fontSize">) => {
  fontSizePrint = fontSizePrint ?? "smaller";

  if (typeof fontSizePrint == "number") {
    fontSizePrint = `${fontSizePrint}px`;
  }

  return (
    <>
      <style>{`@media screen { #document .document-body { font-size: ${
        fontSize ?? "smaller"
      } !important; } }`}</style>
      <style>{`@media print { #document, #document .document-body { font-size: ${fontSizePrint} !important; color: black; background-color: white !important; print-color-adjust: exact !important; -webkit-print-color-adjust: exact !important; } }`}</style>
      <Div when={!!landscape}>
        <style>{`@page { size: landscape }`}</style>
      </Div>
      <Div col>
        <Div id="document" height={100} width={100} border={border}>
          {children}
        </Div>
      </Div>
    </>
  );
};

export const Page = ({
  breakAfter,
  border,
  children,
  ...rest
}: {
  breakAfter?: boolean;
  children?: ReactNode;
} & BootstrapProps) => {
  return (
    <>
      <Div
        classNames={["document-body"]}
        padding={4}
        marginBottom={breakAfter ? 2 : 0}
        border={border}
        {...rest}
      >
        {children}
        <Div
          when={breakAfter}
          style={{ breakAfter: breakAfter ? "page" : "auto" }}
        ></Div>
      </Div>
    </>
  );
};
