import { routes } from "../misc/routes";
import { Link } from "react-router-dom";

import "../styles/footer.scss";

import logo from "../img/ITR_KEV_Logo_ByKevGroupFeb24.png";

import Div from "./Div";
import For from "./For";
import NavItem from "./NavItem";
import Compass from "../icons/Compass";
import TelephoneFill from "../icons/TelephoneFill";
import EnvelopeFill from "../icons/EnvelopeFill";

const Footer = () => {
  const supportLinks = [
    {
      title: "Create Help Ticket",
      route: routes.CreateHelpTicket,
    },
    // {
    //   title: "Document Library",
    //   route: routes.DocumentLibrary,
    // },
    {
      title: "Help Docs",
      route: routes.HelpDocs,
    },
  ];

  const companyLinks = [
    {
      title: "Partners",
      route: routes.Partners,
    },
    {
      title: "Testimonials",
      route: routes.Testimonials,
    },
    {
      title: "Careers",
      route: routes.Careers,
    },
  ];

  const resourceLinks = [
    {
      title: "Privacy Policy",
      route: routes.PrivacyPolicy,
    },
    {
      title: "Terms of Service",
      route: routes.TermsOfService,
    },
    {
      title: "Cookie Policy",
      route: routes.CookiePolicy,
    },
  ];

  return (
    <footer
      className="mt-auto pb-3 bg-intouch-blue-darkz d-print-none"
      style={{ backgroundColor: "#343A40" }}
    >
      <Div container paddingTop={3}>
        <Div row flexJustifyLg="between">
          <Div colLg={3} marginBottom={4} classNames={["ms-lg-auto mb-lg-0"]}>
            <h6 className="text-white">Corporate Office</h6>
            <Div classNames={["text-muted-light text-sm"]}>
              <Div>
                <span className="me-2">
                  <Compass />
                </span>
                420 N Meridian Street
              </Div>
              <Div>
                <span className="me-2 invisible">
                  <Compass />
                </span>
                Suite B
              </Div>
              <Div>
                <span className="me-2 invisible">
                  <Compass />
                </span>
                Puyallup, WA 98371
              </Div>
            </Div>
            <Div classNames={["text-muted-light text-sm mt-2"]}>
              <TelephoneFill />
              <span className="ms-2">+1 (800) 627-4767</span>
            </Div>
            <Div classNames={["text-muted-light text-sm mt-2"]}>
              <EnvelopeFill />
              <a
                className="ms-2 text-muted-light"
                href="mailto:sales@intouchreceipting.com"
              >
                sales@intouchreceipting.com
              </a>
            </Div>
            <Div classNames={["text-muted-light text-sm mt-2"]}>
              <EnvelopeFill />
              <a
                className="ms-2 text-muted-light"
                href="mailto:support@intouchreceipting.com"
              >
                support@intouchreceipting.com
              </a>
            </Div>
          </Div>

          <Div colLg={3} marginBottom={4} classNames={["ms-lg-auto mb-lg-0"]}>
            <h6 className="text-white">Texas Office</h6>
            <Div classNames={["text-muted-light text-sm"]}>
              <Div>
                <span className="me-2">
                  <Compass />
                </span>
                Dallas, TX
              </Div>
            </Div>
            <Div classNames={["text-muted-light text-sm mt-2"]}>
              <Div>
                <TelephoneFill />
                <span className="ms-2">+1 (800) 627-4767</span>
              </Div>
            </Div>
          </Div>

          <Div colLg={2} marginBottom={4} classNames={["ms-lg-auto mb-lg-0"]}>
            <h6 className="text-white">
              <Link to={routes.Support} className="text-white">
                Support
              </Link>
            </h6>
            <ul className="nav nav-sm nav-x-0 nav-white flex-column">
              <For
                each={supportLinks}
                render={(x, i) => {
                  return (
                    <li key={i} className="nav-item fs-5">
                      <a className="nav-link text-muted-light" href={x.route}>
                        {x.title}
                      </a>
                    </li>
                  );
                }}
              />
            </ul>
          </Div>

          <Div colLg={2} marginBottom={4} classNames={["ms-lg-auto mb-lg-0"]}>
            <h6 className="text-white">Company</h6>
            <ul className="nav nav-sm nav-x-0 nav-white flex-column">
              <For
                each={companyLinks}
                render={(x, i) => {
                  return (
                    <NavItem key={i} title={x.title} route={x.route} muted />
                  );
                }}
              />
            </ul>
          </Div>

          <Div colLg={2} marginBottom={4} classNames={["ms-lg-auto mb-lg-0"]}>
            <h6 className="text-white">Resources</h6>
            <ul className="nav nav-sm nav-x-0 nav-white flex-column">
              <For
                each={resourceLinks}
                render={(x, i) => {
                  return (
                    <NavItem key={i} title={x.title} route={x.route} muted />
                  );
                }}
              />
            </ul>
          </Div>
        </Div>
      </Div>
      <Div container textAlign="center" classNames={["text-muted-light"]}>
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" height={(313 / 734) * 165} />
        </a>
        <Div marginTop={0}>
          <small>
            © {new Date().getFullYear()} InTouch Receipting. All Rights
            Reserved.
          </small>
        </Div>
      </Div>
    </footer>
  );
};

export default Footer;
