import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";
import Partner from "../components/Partner";
import CallToAction from "../components/CallToAction";

import Destiny from "../img/brands/follett-destiny.png";
import IncidentIQ from "../img/brands/iiQ-logo-lg.png";
import PowerSchool from "../img/brands/powerschool.svg";
import Skyward from "../img/brands/skyward-certified.jpg";
import Sungard from "../img/brands/sungard.jpg";
import SoftwareUnlimited from "../img/brands/softwareunlimited.png";
import Tyler from "../img/brands/tyler.png";
import MealMagic from "../img/brands/mealmagic.png";
import TotalK12 from "../img/brands/totalk12.png";

const Partners = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Partners</title>
        <meta
          name="description"
          content="Integrations and partnerships are at the core of what we do."
        />
      </Helmet>

      <PageHeader title="Partners" subtitle="" />

      <Div container paddingTop={4}>
        <Div row>
          <Div col>
            <Partner
              image={<img src={Destiny} width="200px" alt="Follett Destiny" />}
              lead="Discover.Connect.Learn"
              className="mb-5"
            >
              <p>
                With the Follett Destiny® suite, you can leverage the Library
                Manager and Resource Manager to track and manage all district
                resources. Library Manager is ideal for library-related
                resources and books, while all other resources – from musical
                instruments to sports equipment and beyond – can be tracked in
                Resource Manager. When used as a complete solution, the Follett
                Destiny® suite ensures all students have the resources they need
                to achieve academic success – all while ensuring you know
                exactly where your resources are and who is accountable for them
                at all times.
              </p>
            </Partner>

            <Partner
              image={<img src={IncidentIQ} width="200px" alt="IncidentIQ" />}
              lead="K-12 Help Desk Software & K-12 Asset Management"
              className="mb-5"
            >
              <p>
                Incident IQ is a service management platform built for K-12
                school districts, featuring asset management, help ticketing,
                facilities maintenance solutions, and more.
              </p>
            </Partner>

            <Partner
              image={<img src={PowerSchool} width="200px" alt="PowerSchool" />}
              lead="Powering Brighter Futures"
              className="mb-5"
            >
              <p>
                PowerSchool® is the leading and fastest growing student
                information system (SIS) available today, supporting 12 million
                students in all 50 states and over 65 foreign countries. Last
                year, more schools and districts selected PowerSchool than any
                other SIS product on the market. Today, PowerSchool supports a
                wide range of implementations – including schools as small as 25
                students, districts as large as 80,000 students, and statewide
                deployments.
              </p>
            </Partner>

            <Partner
              image={<img src={Skyward} width="200px" alt="Skyward" />}
              lead="A Better SIS and ERP Experience"
              className="mb-5"
            >
              <p>
                Skyward provides enterprise software solutions for K-12 schools
                and municipalities. Featured products include a student
                information system (SIS) and integrated financial/human
                resources platform (ERP).
              </p>
            </Partner>

            <Partner
              image={<img src={MealMagic} width="200px" alt="Meal Magic" />}
              lead="Our Knowledge and Experience Will Make Your Life Easier"
              className="mb-5"
            >
              <p>
                When knowledge, experience, compliance, support, and
                cutting-edge technologies are important, look to Meal Magic
                Corporation for the software solutions you need for your food
                service department. At Meal Magic Corporation, we have been
                helping schools modernize their cafeterias for over 25 years, so
                you can be confident you are making a great choice when you
                select our products.
              </p>
            </Partner>

            <Partner
              image={<img src={TotalK12} width="200px" alt="TotalK12" />}
              lead=""
              className="mb-5"
            >
              <>
                <div>Imagine a system that ...</div>
                <ul>
                  <li>reduces paperwork</li>
                  <li>speeds lunch lines</li>
                  <li>processes payments automatically</li>
                  <li>reduces phone calls from parents</li>
                  <li>processes free and reduced school lunch applications</li>
                  <li>generates state and federal reports</li>
                  <li>
                    offers centralized management of all cafeterias - even from
                    home
                  </li>
                  <li>works with your current school management software</li>
                </ul>
                <p>
                  No need to imagine any more. The TotalK12, powered by K12
                  Administration, is here. TotalK12 is simple, powerful and
                  affordable.
                </p>
              </>
            </Partner>

            <Partner
              image={<img src={Sungard} width="200px" alt="SunGard" />}
              lead="A LEADER IN K-12 ADMINISTRATIVE SOFTWARE"
              className="mb-5"
            >
              <p>
                SunGard K-12 Education provides secure and reliable solutions
                for student information management, assessment development and
                analysis, curriculum mapping, special education, and finance and
                human resources.
              </p>
            </Partner>

            <Partner
              image={
                <img
                  src={SoftwareUnlimited}
                  width="200px"
                  alt="Software Unlimited"
                />
              }
              lead="Accounting software so easy-to-use, flexible, and cost-effective that it consistently exceeds your expectations"
              className="mb-5"
            >
              <p>
                The mission at Software Unlimited, Inc. is to create, maintain,
                update, and support comprehensive and affordable fund accounting
                solutions tailored to meet the state specific reporting
                requirements for Midwest schools. Our in-house product
                developers, full-service training staff, and knowledgeable
                customer service team guarantee our customers will always
                receive unlimited access to service and support from experienced
                professionals.
              </p>
            </Partner>

            <Partner
              image={<img src={Tyler} width="200px" alt="Tyler Technologies" />}
              lead="Tyler School Solutions — Software for All of Your School Management & Administration Needs"
              className="mb-5"
            >
              <p>
                School districts must work harder than ever to manage the needs
                of their constituents — students, parents, teachers, and
                taxpayers, not to mention local and statewide governing bodies
                and agencies. Now, more than ever, it's critical that schools
                function well as a cohesive system — from the classroom and the
                business office to the transportation department and the
                superintendent's office. With Tyler School Solutions, K – 12
                schools can bridge the silos of information between their
                departments with integrated school district management software.
              </p>
            </Partner>

            {/* <Partner
              image={<img src={IBM} width="200px" alt="IBM" />}
              lead="Industry Leading Hardware: IBM"
              className="mb-5"
            >
              <p>
                IBM has sold millions of it’s IBM POS terminals around the
                world. Unlike business or home office environments, computers in
                retail stores are subject to unstable conditions. As an IBM
                Business Partner (HD Baker also provides POS to grocery stores
                on the west coast), we are able to provide you an IBM retail
                hardened pos computer as an option to the standard office pc.
                Surprisingly, Student Store environments are harsh. These
                conditions will contribute to pre-mature component failure in a
                standard office pc.
              </p>
            </Partner>

            <Partner
              image={<img src={Lenovo} width="200px" alt="Lenovo" />}
              lead="Global Innovation: Lenovo"
              className="mb-5"
            >
              <p>
                Lenovo’s business is built on product innovation, a highly
                efficient global supply chain and strong strategic execution.
                The company develops, manufactures and markets reliable,
                high-quality, secure and easy-to-use technology products and
                services for customers who want technology that does more—
                because people have a lot more to do. Our product lines include
                legendary Think-branded PCs and Idea-branded PCs, as well as
                servers, workstations and a family of mobile internet devices,
                including tablets and smart phones. We have a long-term goal of
                becoming the leading personal technology company in the world.
              </p>
            </Partner> */}
          </Div>
        </Div>
      </Div>

      <CallToAction
        title="See what it's all about!"
        subtitle="Are you ready to improve your workflow? We can help!"
        buttonText="Get Started With InTouch"
        buttonHref={routes.Sales}
      />
    </>
  );
};

export default Partners;
