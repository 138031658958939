import Div from "./Div";
import For from "./For";
import Render from "./Render";

const TestimonialRow = ({
  image,
  quotes,
  person,
  title,
  org,
  className,
}: {
  image: JSX.Element;
  quotes: string[];
  person: string;
  title?: string;
  org: string;
  className?: string;
}) => {
  return (
    <Div flex classNames={[className ?? ""]}>
      <Div flexShrink={0}>{image}</Div>
      <Div flexGrow={1} marginStart={3}>
        <For
          each={quotes}
          render={(quote, i) => {
            return <p key={i}>"{quote}"</p>;
          }}
        />
        <strong>{person}</strong>
        <Render when={title}>
          <div>{title}</div>
        </Render>
        <div>{org}</div>
      </Div>
    </Div>
  );
};

export default TestimonialRow;
