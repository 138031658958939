import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import "../styles/home.scss";

import Div from "../components/Div";
import IconLink from "../components/IconLink";

import Hero from "../img/hero.jpg";

import IntegrationsIcon from "../img/icons3/icons4.svg";
import FineFeeManagementIcon from "../img/icons3/finefee.svg";
import PosIcon from "../img/icons3/cardpayment.svg";
import PaymentsPortalIcon from "../img/icons3/portal.svg";
import RegistrationsIcon from "../img/icons3/ribbon.svg";
import FundraisingDonationsIcon from "../img/icons3/jar.svg";

import FollettDestiny from "../img/brands/follett-destiny.png";
import Powerschool from "../img/brands/powerschool.svg";
import TylerTech from "../img/brands/tyler.png";
import SkywardQmlativ from "../img/brands/skywardqmlativ.png";

const Home = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Leading K-12 School Payments Platform</title>
        <meta
          name="description"
          content="We make it easy for students and parents to make in-person and online payments, while streamlining reporting and auditing for finance managers."
        />
      </Helmet>

      <Div
        classNames={["hero"]}
        flex
        flexAlign="center"
        style={{
          verticalAlign: "middle",
          backgroundImage: `url(${Hero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center 30%",
        }}
      >
        <Div container textColor="white">
          <h1>SIMPLY PUT...</h1>
          <h1>SIMPLY THE BEST</h1>
          <Div marginTop={4}>
            <Link to={routes.Sales} className="btn btn-lg btn-success">
              Learn More
            </Link>
          </Div>
        </Div>
      </Div>

      <Div marginBottom={5}>
        <Div container>
          <Div row marginTop={4} textAlign="center">
            <Div col>
              <Div
                display={5}
                marginY={3}
                classNames={["text-intouch-blue"]}
              >
                INTEGRATED PAYMENTS PLATFORM FOR K-12
              </Div>

              <Div marginBottom={3}>
                Our scalable platform delivers a seamless experience for you,
                your students, parents, and your community by focusing on
                integrations. Integrations are critical to efficient,
                frictionless business processes. We eliminate friction by
                integrating with your various IT systems, such as your student
                information system, library, asset management, general ledger,
                and nutrition/lunch software, creating one simplified and
                streamlined solution.
              </Div>
            </Div>
          </Div>

          <Div row marginTop={3}>
            <Div col={12} colMd={6}>
              <Div
                borderBottom={3}
                paddingBottom={1}
                marginBottom={3}
                textAlign="center"
                classNames={["h5", "text-intouch-blue", "border-intouch-blue"]}
              >
                Finance + Business Managers
              </Div>
            </Div>
            <Div col={12} colMd={6} display="none" displayMd="block">
              <Div
                borderBottom={3}
                paddingBottom={1}
                marginBottom={3}
                textAlign="center"
                classNames={["h5", "text-intouch-blue", "border-intouch-blue"]}
              >
                Parents + Students + Community
              </Div>
            </Div>
          </Div>

          <Div row>
            <IconLink
              href={routes.FeeFineManagement}
              src={FineFeeManagementIcon}
              alt="Fee/Fine Management"
            />
            <IconLink
              href={routes.AccountingIntegration}
              src={IntegrationsIcon}
              alt="Accounting Integration"
            />
            <IconLink
              href={routes.PointOfSale}
              src={PosIcon}
              alt="Point of Sale"
            />

            <Div
              width={100}
              display="block"
              displayMd="none"
              marginTop={3}
              marginTopMd={0}
            >
              <Div
                borderBottom={3}
                paddingBottom={1}
                marginBottom={3}
                textAlign="center"
                classNames={["h5", "text-intouch-blue", "border-intouch-blue"]}
              >
                Parents + Students + Community
              </Div>
            </Div>

            <IconLink
              href={routes.PaymentsPortal}
              src={PaymentsPortalIcon}
              alt="Payments Portal"
            />
            <IconLink
              href={routes.AthleticActivityRegistration}
              src={RegistrationsIcon}
              alt="Athletic & Activity Registration"
            />
            <IconLink
              href={routes.FundraisingDonations}
              src={FundraisingDonationsIcon}
              alt="Fundraising & Donations"
            />
          </Div>

          <Div row marginTop={4}>
            <Div col textAlign="center">
              <Link to={routes.Sales} className="btn btn-lg btn-primary">
                Request A Consultation And Demo
              </Link>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div marginBottom={5} classNames={["bg-intouch-green"]}>
        <Div container>
          <Div row>
            <Div col={12} colMd={6}>
              <Div textColor="white" paddingTop={5}>
                <Div marginBottom={3} classNames={["h2"]}>
                  COMPLETE K-12 SOLUTIONS
                </Div>
                <Div fontSize={5} marginBottom={3}>
                  We make it easy for students and parents to make in-person and
                  online payments, while streamlining reporting and auditing for
                  finance managers.
                </Div>
                <Div>
                  As the market leader for nearly 30 years, InTouch software
                  helps K-12 school districts manage money with real-time
                  insights and strong controls. Our point-of-sale and payments
                  portal are fully integrated with school district systems:
                  student information system, library, asset management, general
                  ledger, and nutrition/lunch software.
                </Div>
              </Div>
            </Div>

            <Div col={12} colMd={6} paddingY={5}>
              <Div classNames={["ratio ratio-16x9"]}>
                <iframe
                  src="https://www.youtube.com/embed/MgzYJZ6zMt0?&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;loop=1"
                  title="Complete K-12 Solutions"
                  allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div marginBottom={5}>
        <Div container>
          <Div row>
            <Div col>
              <Div
                marginBottom={3}
                textAlign="center"
                classNames={["h2", "text-intouch-blue"]}
              >
                SEAMLESS INTEGRATION
              </Div>
              <Div textAlign="center">
                InTouch integrates with the most commonly used
                library/textbook/asset, Student Information Systems (SIS), ERP
                finance, and nutrition/lunch software. Integration reduces
                duplication of efforts, increases speed and accuracy, and
                streamlines workflow while providing a professional and
                up-to-date customer experience.
              </Div>
            </Div>
          </Div>

          <Div row marginY={4} flexAlign="center" flexJustify="center">
            <img
              src={FollettDestiny}
              className="col-12 col-md-3 mb-3 mb-md-0"
              alt="Follett Destiny"
              style={{ maxHeight: "63px", objectFit: "contain" }}
            />
            <img
              src={Powerschool}
              className="col-12 col-md-3 mb-2 mb-md-0"
              alt="PowerSchool"
              style={{ maxHeight: "70px", objectFit: "contain" }}
            />
            <img
              src={TylerTech}
              className="col-12 col-md-3 mb-3 mb-md-0"
              alt="Tyler Technologies"
              style={{ maxHeight: "85px", objectFit: "contain" }}
            />
            <img
              src={SkywardQmlativ}
              className="col=12 col-md-3 mb-3 mb-md-0"
              alt="Skyward - Qmlativ"
              style={{ maxHeight: "85px", objectFit: "contain" }}
            />
          </Div>

          <Div row>
            <Div col textAlign="center">
              <Link to={routes.Integrations} className="btn btn-lg btn-primary">
                Explore More
              </Link>
            </Div>
          </Div>

          <Div row marginTop={4}>
            <Div col textAlign="center" fontSize={7}>
              *All logos and names contained herein are the registered
              trademarks of their respective companies.
              <br />
              The presence of the logo does not imply endorsement of InTouch,
              and the use of their trademarks is informational only.
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default Home;
