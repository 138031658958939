import { ReactNode } from "react";

import Div from "../components/Div";

const ContentSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Div marginBottom={5}>
      <h3 className="text-intouch-bluez fw-bold">{title}</h3>
      <Div marginTop={3}>{children}</Div>
    </Div>
  );
};

export default ContentSection;
