export const routes = {
  Home: `/`,
  About: `/about`,
  AccountingIntegration: `/accounting-integration`,
  AthleticActivityRegistration: `/athletic-activity-registration`,
  Careers: `/careers`,
  Contact: `/contact`,
  CookiePolicy: `/cookie-policy`,
  FeeFineManagement: `/fee-fine-management`,
  FundraisingDonations: `/fundraising-donations`,
  GeneralLedger: `/general-ledger`,
  Integrations: `/integrations`,
  PaymentsPortal: `/payments-portal`,
  Partners: `/partners`,
  PointOfSale: `/point-of-sale`,
  PrivacyPolicy: `/privacy-policy`,
  Registrations: `/registrations`,
  Sales: `/sales`,
  Support: `/support`,
  TermsOfService: `/terms-of-service`,
  Testimonials: `/testimonials`,
  TouchBaseLiteRequest: `/touchbaselite`,
  TouchBaseLiteRequestOld: `/touchbaselite.html`,

  ServiceLevelAgreements: "/service-level-agreements",
  ServiceLevelAgreementsNoHypen: "/servicelevelagreements",

  USServiceLevelAgreement:
    "/service-level-agreements/usservicelevelagreement/*",
  USServiceLevelAgreementNoHyphen:
    "/servicelevelagreements/usservicelevelagreement/*",

  USPaymentTerms: "/uspaymentterms",
  USPaymentTermsAppendix1: "/uspaymentterms/appendix1",

  // external
  CreateHelpTicket:
    "https://projects.intouchreceipting.com/support/#/tickets/new",
  DocumentLibrary: "https://fileserve.intouchreceipting.com/signin.aspx",
  HelpDocs: "https://help.intouchreceipting.com/",
};
