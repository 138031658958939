import Div from "./Div";

import Background from "../img/bg-grid.svg";

const PageHeader = ({
  title,
  subtitle,
  classNames = [],
}: {
  title: string;
  subtitle: string;
  classNames?: string[];
}) => {
  return (
    <Div
      classNames={[...classNames, "bg-intouch-blue"]}
      style={{
        //opacity: ".9",
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Div container>
        <Div row>
          <Div col paddingY={5} textAlign="center" textColor="white">
            <h1 className="display-5">{title}</h1>
            <Div fontSize={5} classNames={["fst-italic"]}>
              {subtitle}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default PageHeader;
