import { Helmet } from "react-helmet";

import ContentSection from "../components/ContentSection";
import PageHeader from "../components/PageHeader";
import Div from "../components/Div";

const About = () => {
  return (
    <Div flex flexColumn height={100}>
      <Helmet titleTemplate="InTouch | %s">
        <title>About</title>
        <meta name="description" content="Purpose-built for schools." />
      </Helmet>

      <PageHeader title="About InTouch" subtitle="" />
      <Div container flexShrink={0}>
        <Div row marginY={3}>
          <Div col={12}>
            <ContentSection title="What We Do">
              <p>
                InTouch is a comprehensive, integrated enterprise platform
                covering district-wide financial and non-financial transactions.
              </p>

              <h5 className="text-intouch-blue">
                Anywhere you have transactions, we are there for you
              </h5>
              <Div marginBottom={3}>
                Our adaptable, portable solution allows you to support your
                district community while maintaining internal financial
                controls.
                <Div marginTop={2}>Use InTouch for:</Div>
                <Div flex marginTop={1}>
                  <Div>
                    <ul>
                      <li>Fines & Fees</li>
                      <li>Nutrition Deposits</li>
                      <li>Concessions</li>
                      <li>Gates</li>
                    </ul>
                  </Div>
                  <Div marginStart={3}>
                    <ul>
                      <li>Fundraising</li>
                      <li>Community Education</li>
                      <li>Pools</li>
                      <li>Registrations</li>
                    </ul>
                  </Div>
                  <Div marginStart={3}>
                    <ul>
                      <li>and more</li>
                    </ul>
                  </Div>
                </Div>
              </Div>

              <h5 className="text-intouch-blue">Integrations</h5>
              <p>
                InTouch integrates with your various IT systems as part of your
                enterprise architecture (library, asset management, general
                ledger, SIS system, nutrition/lunch software, etc). Bringing
                these systems together removes friction from business processes
                while simplifying the experience for your students, parents, and
                community.
              </p>

              <h5 className="text-intouch-blue">Controls & Efficiency</h5>
              <p>
                InTouch will streamline your operations, reduce costly
                duplication of efforts, increase accuracy and financial
                transparency, save hundreds of staff hours, cut costs, and
                provide the kind of internal financial controls that will give
                you peace of mind.
              </p>

              <h5 className="text-intouch-blue">Client Focus</h5>
              <p>
                We strive to provide the most efficient, ethical, safe, and
                secure application to our school district clients across the
                U.S. while helping them serve their communities and adapt to the
                ever-changing technology landscape.
              </p>
            </ContentSection>
            <ContentSection title="A Brief History">
              <Div col>
                <p>
                  Arnold Mortzheim, CPA, and a team of talented software
                  developers and technical staff have dedicated themselves to
                  helping school districts for over 25 years. Working with IBM
                  Grocery, and restaurant POS systems in the ’90s taught us
                  point-of-sale, and systems integration which we brought to
                  early adoptor school districts in the late ’90s. Our first
                  school district client was Central Kitsap in WA State in 1998
                  and is still with us today.
                </p>

                <p>
                  Since those early days, our client-centric philosophy has
                  guided our product development, helping us meet the needs of
                  various district stakeholders and their communities.
                </p>
                <p>
                  We believe in relationships built on mutual trust and respect.
                  Our client focus and dedication have helped us retain 99.9% of
                  our clients since inception. We achieve this by providing
                  highly trained project managers, technology pros, and support
                  staff, including retired school personel. From day one to
                  decades later, the InTouch team has been taking care of school
                  business.
                </p>
              </Div>
            </ContentSection>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default About;
