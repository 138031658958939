import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const PointOfSale = () => {
  return (
    <PageWithHeaderCallToAction
      title="Point of Sale"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Point of Sale</title>
        <meta
          name="description"
          content="Simple, portable payments anywhere you need them."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Simple, portable payments anywhere you need them.
      </Div>

      <h5 className="mb-4">
        InTouch Point of Sale is a fully integrated, comprehensive software
        solution covering district-wide financial transactions with exceptional
        financial controls.
      </h5>

      <Div marginBottom={4}>
        <p>Yeah, we know thats a mouthful, but keep reading!</p>
      </Div>

      <Div row>
        <Div col={12}>
          <ContentSection title="Why InTouch Point of Sale?">
            <p>
              InTouch Point of Sale and our Payments Portal are the cornerstones
              of our platform. They work together seamlessly to provide a
              convenient experience to your students, parents, and staff.
            </p>
            <p>
              Our point of sale was born from our roots in the demanding grocery
              and restaurant environments, and purpose-built for the unique
              challenges of the school district.
            </p>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="Things To Know">
            <ul>
              <li>
                Accept cash, check, and credit card payments anytime, anywhere;
                all with built-in internal controls
              </li>
              <li>
                Parents and students can pay for any item, fine, or fee, from
                any district location in one transaction
              </li>
              <li>Real-time, platform-wide financial reporting</li>
              <li>Easily upload transactions to your GL</li>
              <ul>
                <li>
                  All transactions automatically have the appropriate budget
                  code attached, so when it's time to upload them to the GL, the
                  data is ready for you
                </li>
              </ul>
              <li>
                Seamlessly integrated with our online payment portal
                <ul>
                  <li>Data never needs to be re-entered</li>
                </ul>
              </li>

              <li>Credit card payment integration</li>
            </ul>

            <h5 className="text-intouch-blue">Ease-of-use Features</h5>
            <ul>
              <li>Customizable buttons for quick sales of popular items</li>
              <li>Easy to learn and use with minimal training</li>
              <li>
                Many users are ready to go in under 15 minutes, making it ideal
                for environments like concessions that rely on volunteers
              </li>
            </ul>

            <h5 className="text-intouch-blue">Controls & Accuracy</h5>
            <ul>
              <li>Role-based security per user, controlling who can do what</li>
              <li>
                End-of-period closeouts are fast and accurate, ready for deposit
                at the bank
              </li>
            </ul>

            <h5 className="text-intouch-blue">
              Supported Point of Sale Hardware
            </h5>
            <ul>
              <li>Touchscreen</li>
              <li>Credit card reader</li>
              <li>Receipt printers</li>
              <li>Barcode scanners</li>
              <li>Mouse</li>
            </ul>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="Ways To Use It">
            <ul>
              <li>School bookkeeper</li>
              <li>Gates & concessions</li>
              <li>Student stores</li>
              <li>Bookfairs</li>
              <li>Teachers can collect money in the classrooms</li>
              <li>Sell tickets for activities, school plays & dances</li>
              <li>... and more</li>
            </ul>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default PointOfSale;
