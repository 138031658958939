import { Link } from "react-router-dom";

import Div from "./Div";

const NavItem = ({
  title,
  route,
  show,
  bold,
  muted,
  onClick,
  children,
}: {
  title: string;
  route?: string;
  show?: boolean;
  bold?: boolean;
  muted?: boolean;
  onClick?: () => void;
  children?: JSX.Element[];
}) => {
  var navItem = (
    <Link
      to={route || "#"}
      className={[
        "nav-link",
        children ? "dropdown-toggle" : "",
        bold ? "fw-500" : "",
        muted ? "text-muted-light" : "text-white",
      ].toClassName()}
      onClick={() => onClick && onClick()}
    >
      {title}
    </Link>
  );

  return (
    <li
      className={[
        "nav-item fs-5",
        children ? "dropdown" : "",
        show ? "show" : "",
      ].toClassName()}
    >
      {navItem}
      {children ? (
        <Div classNames={["dropdown-menu", show ? "show" : ""]}>{children}</Div>
      ) : null}
    </li>
  );
};

export default NavItem;
