import { Link } from "react-router-dom";

import Div from "./Div";

const CallToAction = ({
  title,
  subtitle,
  buttonHref,
  buttonText,
}: {
  title: string;
  subtitle: string;
  buttonHref: string;
  buttonText: string;
}) => {
  return (
    <Div marginTop="auto" classNames={["bg-intouch-blue"]}>
      <Div container>
        <Div row padding={4}>
          <Div col textAlign="center" textColor="white">
            <h1 className="display-6">{title}</h1>
            <Div marginTop={3} fontSize={5}>
              {subtitle}
            </Div>
            <Div marginTop={4}>
              <Link to={buttonHref}>
                <button className="btn btn-lg btn-success">{buttonText}</button>
              </Link>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default CallToAction;
