import { Helmet } from "react-helmet";
import Div from "../components/Div";
import PageHeader from "../components/PageHeader";
import USPaymentTermsAppendix1Content from "./USPaymentTermsAppendix1Content";

const USPaymentTerms = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Merchant Payment T&C USA</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <PageHeader title="Merchant Payment T&C USA" subtitle="" />

      <style type="">
        {`
          .t-row {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;
            margin-bottom: 1rem;
          }

          .t-col-1 {
            flex: 0 0 auto;
            width: 4.1667%
          }

          .t-col-2 {
            flex: 0 0 auto;
            width: 6.2500%
          }
        `}
      </style>

      <Div container>
        <Div row marginTop={4}>
          <Div col>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>MERCHANT AGREEMENT: TERMS AND CONDITIONS</strong>
            </Div>
            <p>
              This Merchant Agreement ("Agreement" or "Merchant Agreement") is
              made between Nuvei Technologies Inc. ("Nuvei" or "ISO"),
              Integrated Register Systems, Inc. ("ITR"), Citizens Bank, N.A.
              ("Bank"), and Merchant, as each may be further defined in the
              Application, and shall be effective as of the date on which the
              Application is approved by Servicer. For the purposes of this
              Agreement, Bank Nuvei, and ITR are referred to collectively as
              "Servicer". Subject to the requirements of the Card Brand rules,
              Nuvei, ITR and Bank may allocate their respective duties and
              obligations between themselves as they deem appropriate at their
              sole discretion, and Nuvei, ITR and Bank may jointly or
              individually assert or exercise the rights or remedies provided to
              the Servicer hereunder.
            </p>
            <p>
              <strong>WHEREAS</strong>, Merchant wishes to have the ability to
              initiate and receive payments and has requested that Servicer
              supply the payment processing and related services described in
              this Agreement, including the Application and any Schedules
              attached hereto, which are incorporated into and form a part of
              this Agreement; and
            </p>
            <p>
              <strong>WHEREAS</strong>, Servicer has agreed to supply such
              payment processing services, on the terms set out in this
              Agreement;
            </p>
            <p>
              <strong>NOW, THEREFORE</strong>, in consideration of the mutual
              promises, covenants, undertakings, understandings, and agreements
              set out in this Agreement, Nuvei, Bank, ITR and the Merchant agree
              as follows:
            </p>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART ONE</strong>
            </Div>
            <p>
              CARD ACCEPTANCE - TERMS AND CONDITIONS APPLICABLE TO MERCHANT'S
              ACCEPTANCE OF VISA, DISCOVER, MASTERCARD, AMERICAN EXPRESS TRAVEL
              RELATED SERVICES COMPANY, UNIONPAY AND ANY OTHER CARD
            </p>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>1.</Div>
              <Div col>DEFINITIONS</Div>
            </Div>
            <p>
              <strong>"Account"</strong> means a commercial checking or demand
              deposit account maintained by Merchant for the crediting of
              collected funds and the debiting of fees and charges under this
              Agreement.
            </p>
            <p>
              <strong>"ACH"</strong> means the Automated Clearing House
              paperless entry system controlled by the Federal Reserve Board.
            </p>
            <p>
              <strong>"Acquirer"</strong> means a financial institution or other
              third party that enables the Merchant to (a) accept payment by
              Cardholders using Cards; and (b) receive value in respect of Card
              payments. An Acquirer may be Bank, a third party which is in an
              arrangement with Nuvei, or Nuvei, when it utilizes its membership
              with a Card Brand in providing Services.
            </p>
            <p>
              <strong>"Agreement"</strong> means the Application, these Terms
              and Conditions, all Schedules attached to these Terms and
              Conditions or the Application, and any other documents related to
              such Agreement.
            </p>
            <p>
              <strong>"Alternative Payment Method" or "APM"</strong> means a
              payment method (other than Cards) which Nuvei may provide to
              Merchant, when available and dependent on the Merchant's region
              (as notified by Nuvei to the Merchant from time to time).
            </p>
            <p>
              <strong>"APMP"</strong> means a third party which regulates or is
              responsible for processing any APM(s) and which is in an
              arrangement with Nuvei which enables Nuvei and the Merchant to (a)
              accept payment by End Users using APMs; and (b) receive value in
              respect of such payments.
            </p>
            <p>
              <strong>"Applicable Law"</strong> means all laws, statutes,
              regulations, rules, codes, directives and ordinances of
              regulators, authorities, courts and government bodies having
              jurisdiction over a party and/or the subject matter, including but
              not limited to those relating to anti- money laundering and
              terrorist financing regulations, anti-bribery laws, consumer
              protection, distance selling, electronic business, consumer credit
              laws, daily fantasy sports, sports betting, gambling, gaming and
              lotteries laws, Data Protection Laws, all of the above to the
              extent applicable to a party, to the performance or business of
              that party or to the services provided by or on behalf of that
              party.
            </p>
            <p>
              <strong>
                "Application", "Application Form" or "Merchant Application"
              </strong>{" "}
              means the Servicer application form completed by the Merchant, and
              which references these Terms and Conditions.
            </p>
            <p>
              <strong>"Assessment"</strong> means any and all assessments, fees,
              fines, penalties, reimbursements or charges (may be titled 'issuer
              reimbursement', 'filing fee', 'administrative fee', 'technical
              fee', 'review fee', 'arbitration decision' or otherwise) of any
              nature which may be directly or indirectly assessed or imposed:
              (i) on the Merchant by Servicer; and/or (ii) on the Merchant
              and/or Servicer by Card Brands, Card Issuers, Acquirers, APMP or
              regulators, authorities, courts or government bodies, having
              jurisdiction over the parties and/or the subject matter of this
              Agreement, as a result of the Merchant's actions, omissions,
              performance and/or use of the Services including but not limited
              to the Merchant's failure to comply with the provisions of this
              Agreement, the Rules or Applicable Law. For the sake of clarity,
              Assessments shall not include assessments, fees, fines, penalties
              or charges imposed as a result of an act or omission of Nuvei.
            </p>
            <p>
              <strong>"Authorization"</strong> means the issuance of a request
              to charge a Payment Method to the respective Card Brand, Card
              Issuer or APMP and the subsequent approval (or decline) of a
              Transaction by the respective Card Brand, Card Issuer or APMP
            </p>
            <p>
              <strong>"Business Day"</strong> means a day other than Saturday or
              Sunday on which banks in New York, NY are open for normal business
            </p>
            <p>
              <strong>"Card"</strong> means (i) a valid credit or debit card in
              the form issued under license from a Card Brand; or (ii) any other
              valid credit or debit card accepted by Merchant by agreement with
              Servicer.
            </p>
            <p>
              <strong>"Card Brand"</strong> means Visa U.S.A., Inc., Visa
              International, Inc., Discover Financial Services ("Discover"),
              Mastercard International, Inc., American Express Travel Related
              Services Company, Inc., China UnionPay Co. Ltd., any affiliate of
              the foregoing, or any other Card networks that provide Cards
              accepted by Merchant pursuant to an agreement with Servicer.
            </p>
            <p>
              <strong>"Card Issuer"</strong> means the financial institution or
              company which has provided a Card to a Cardholder.
            </p>
            <p>
              <strong>"Cardholder"</strong> (sometimes referred to as "Card
              Member" in certain Card Brand materials) shall mean any person
              authorized to use the Cards or the accounts established in
              connection with a Card.
            </p>
            <p>
              <strong>"Cardholder Information"</strong> means any non-public,
              sensitive information about a Cardholder, including any
              combination of Cardholder name plus the Cardholder's social
              security number, driver's license or other identification number
              or credit or debit card number, or other Servicer account number.
            </p>
            <p>
              <strong>"Chargeback" </strong>means any direct or indirect
              dispute, reversal or debit of a Transaction by an End User, Card
              Brand, Acquirer, APM or APMPs, because of such Transaction being
              invalid, disputed, unauthorized (or there are grounds to believe
              it was not authorized), suspicious, the goods or services were not
              delivered at all or as agreed, or otherwise for any reason.
              Chargebacks can be procedural or substantive.
            </p>
            <p>
              <strong>"Confidential Information"</strong> means all confidential
              or proprietary information of a party, designated as such or which
              is reasonably expected to be treated in a confidential manner,
              whether in written, oral, electronic or other form, including
              without limitation any information of a technical, business or
              other nature including without limitation the existence and the
              content of business and contractual relations between the parties
              and any and all intellectual property, trade secrets, techniques,
              know-how, inventions, technology, systems, software, designs,
              drawings, specifications, documentation, diagrams, economic and
              financial information and analyses, processes and procedures
              including but not limited to security procedures, sales and
              marketing techniques plans and materials, price lists and pricing
              policies. Confidential Information shall also include all
              personal, confidential or proprietary information of third parties
              (investors, partners, vendors, customers, consumers, employees
              etc.) including such third parties' names and means of
              identification. Confidential Information does not include
              information that: (1) is or subsequently becomes publicly
              available (through no fault of the recipient); (2) the recipient
              lawfully possesses before its disclosure; (3) is received by the
              recipient from a third party that is not obligated to keep it
              confidential or (4) is independently developed without reliance on
              the discloser's Confidential Information. For purpose of this
              definition, Confidential Information of other merchants, Card
              Brands, Acquirers, APMs and service providers used in the
              provision of Services shall be considered as Servicer Confidential
              Information.
            </p>
            <p>
              <strong>"Cooling Off Period"</strong> shall have the meaning set
              forth in Section 5.2 (Termination).
            </p>
            <p>
              <strong>"Credit Voucher"</strong> means a document executed by a
              Merchant evidencing any refund or price adjustment relating to
              Cards to be credited to a Cardholder account.
            </p>
            <p>
              <strong>"Data Protection Laws"</strong> means (i) the General Data
              Protection Regulation ((EU) 2016/679) (GDPR); and (ii) any
              federal, state or local laws, regulations and secondary
              legislation enacted from time to time in the United States
              relating to data protection, the use of information relating to
              individuals, the information rights of individuals and/or the
              processing of Personal Data.
            </p>
            <p>
              <strong>"Deductions"</strong> means any and all of the following:
              (1) fees and charges to Servicer, Card Brands, Acquirers and/or
              APMPs as per the provisions of this Agreement; (2) Chargebacks,
              refunds, credits, payments imposed and any other amounts deducted
              from settled Transactions by the Card Brands, Acquirers and/or
              APMPs; (3) Assessments; (4) the Reserve amounts, if any, and any
              amount required to maintain the Reserve at the designated level;
              (5) amounts of overpayment, however made; (6) payments made in
              respect of invalid Transactions; and (7) any amounts owed or due
              to Servicer, Card Brands, Acquirers and/or APMPs or recoverable by
              Servicer and/or on behalf of Card Brands, Acquirers and/or APMPs
              under this Agreement or otherwise.
            </p>
            <p>
              <strong>"End User"</strong> means (i) a Cardholder, or (ii) a
              person that purchases goods or services from the Merchant through
              an ACH Transaction or the use of an APM.
            </p>
            <p>
              <strong>"Imprint"</strong> means (i) an impression on a Sales
              Draft manually obtained from a Card through the use of an
              imprinter, or (ii) the electronic equivalent obtained by swiping a
              Card through a terminal and electronically capturing Card Data and
              printing a Sales Draft.
            </p>
            <p>
              <strong>"KYC Requirements"</strong> shall have the meaning set
              forth in Section 2.9.1.
            </p>
            <p>
              <strong>"Marks"</strong> mean brands, names, logos, trademarks,
              trade names or service marks.
            </p>
            <p>
              <strong>"Mid or Non-Qualifying Transaction"</strong> means any
              sale Transaction that fails to qualify for lowest interchange rate
              assigned by the applicable Card Brand for Merchant's standard card
              industry code and which may be charged fees as set forth in the
              Application.
            </p>
            <p>
              <strong>"Payment Method"</strong> means a Card, ACH or APM.
            </p>
            <p>
              <strong>"Personal Data"</strong> shall have the meaning set forth
              in the Data Protection Schedule.
            </p>
            <p>
              <strong>"Platform"</strong> means the technology, hardware and
              software upon and in conjunction with which certain Services are
              provided by Nuvei and its affiliated entities, any code or
              software (payment page, SDK, API etc.) which may be provided to
              the Merchant or for the Merchant's use under this Agreement, any
              web interface to the Services and to Nuvei online systems, and any
              work products created and/or delivered and related documentation
              in connection with this Agreement.
            </p>
            <p>
              <strong>"Reserve Account"</strong> shall have the meaning set
              forth in Section 4.6.
            </p>
            <p>
              <strong>"Remittance"</strong> means a transfer of money to
              Merchant for the amount actually collected by Servicer or APMPs
              with respect to Merchant's Transactions processed, less
              Deductions.
            </p>
            <p>
              <strong>"Rules"</strong> means all current and future by-laws,
              rules, regulations, policies, procedures and guidelines issued by
              the respective Acquirers, APMPs, Card Brands, Nacha (or National
              Automated Clearing House Association) and any other relevant
              payment provider and, where applicable, includes any direct
              engagement between Merchant and such entities.
            </p>
            <p>
              <strong>"Sales Draft"</strong> means the electronic or paper
              record, whether electronically or manually imprinted, evidencing a
              sale Transaction.
            </p>
            <p>
              <strong>"Services"</strong> means any of the Services which are
              set forth in Section 2.2 of this Agreement or any additional
              Services as shall be mutually agreed by the Merchant and Servicer
              from time to time.
            </p>
            <p>
              <strong>"Terminal"</strong> refers to the point-of-sale device
              into which Cards are swiped or dipped for purposes of processing
              Transactions, including any mobile card reader that connects to a
              mobile device. If Merchant has elected in the Application to rent
              a Terminal from Nuvei, the additional terms contained in Appendix
              1 - Equipment Rental Agreement shall apply to Merchant's Terminal
              lease.
            </p>
            <p>
              <strong>"Transaction"</strong> means any payment or refund made by
              the use of a Payment Method or its unique identifier (e.g. Card
              number or otherwise) to debit or credit the End User's Payment
              Method account, and any process undertaken by Servicer or the
              respective Card Brand or APMP following a request from the
              Merchant to collect any payment from the End User on the
              Merchant's behalf or perform any related action in relation to any
              of these activities. There can be several types of Transactions in
              each payment, namely registration of the Payment Method,
              Authorization, settlement, credit, void, etc.
            </p>
            <p>
              <strong>"Voice Authorization"</strong> means a direct phone call
              to a designated number to obtain credit approval on a Transaction
              from the Card Issuer, whether by voice or voice-activated systems.
            </p>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.</Div>
              <Div col>SUPPLY OF SERVICES</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.1.</Div>
              <Div col>
                Merchant wishes to receive the Services as described in this
                Agreement from Servicer. Services shall be provided by Servicer
                in its sole discretion with respect to each Card Brand and APMP
                with which, and for as long as, Servicer has an agreement with
                such Card Brand or APMP, and the Parties have signed the
                respective pricing and terms and conditions as set forth in the
                Application. The form of Service and contractual structure and
                provisions may vary as shall be further provided in the
                Application. In certain instances, the aggregation of funds
                and/or remittance to the Merchant may be provided by Bank and
                APMP and not by Nuvei. Services and rights of use are provided
                strictly to the Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.2.</Div>
              <Div col>
                <p>
                  In consideration of the fees and subject to the Merchant's
                  compliance with the provisions of this Agreement, the Merchant
                  orders and Servicer shall provide the Merchant with the
                  following Services:
                </p>
                <p>
                  (i) Requesting Authorization for Transactions, processing and
                  settling Transactions and Remittance of funds;
                </p>
                <div>
                  (ii) Access to an online reporting and actions interface
                  forming part of the Platform.
                </div>
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.3.</Div>
              <Div col>
                <p>
                  Remittances shall be remitted to the Merchant in accordance
                  with the provisions of this Agreement. Remittance is always
                  subject to receipt by the party remitting funds from the
                  respective Payment Method (Acquirer, APMP, Card Brand or
                  otherwise, including but not limited to the payer's payment
                  method).
                </p>
                <p>
                  (i) Remittances may be postponed or delayed due to weekends or
                  banking holidays in the respective jurisdictions of Servicer,
                  Merchant, the respective Card Brand, Acquirers, APMPs or other
                  Payment Methods, or due to any technical delays or
                  malfunctions of the relevant systems.
                </p>
                <div>
                  (ii) Servicer may at any time and without notice use third
                  parties for the purpose of payment of Remittances. All
                  payments to the Merchant's account by third parties shall be
                  deemed payment by Servicer, for all intents and purposes.
                  Notwithstanding, where Remittances are made directly by such
                  third parties, such transfer of funds shall be subject to the
                  third party's practices and the third party may deduct wire
                  fees for each remittance in accordance with its terms.
                  Servicer shall not have any liability to Merchant with respect
                  to such payments by such third parties.
                </div>
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.4.</Div>
              <Div col>
                MERCHANT ACKNOWLEDGES, UNDERSTANDS AND ACCEPTS THAT NO
                REMITTANCE OF ANY TRANSACTION SHALL BE FINAL SO LONG AS THE
                TRANSACTION IS SUBJECT TO REFUNDS, CHARGEBACK, INVESTIGATION OF
                FRAUD OR REPAYMENT, THAT SERVICER MAY REVOKE PRIOR PROVISIONAL
                SETTLEMENTS AND THAT ALL SETTLEMENTS, REMITTANCE AND CREDITS BY
                SERVICER ARE PROVISIONAL AND MADE CONDITIONALLY AND SUBJECT AT
                ALL TIMES TO DEDUCTIONS OR ANY OTHER RIGHT TO SET OFF REGARDLESS
                OF TIME OF DEDUCTION (IF, FOR EXAMPLE, A DEDUCTION IS DUE AFTER
                THE REMITTANCE HAS BEEN MADE).
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.5.</Div>
              <Div col>
                Servicer shall have the right, upon a thirty days' notice
                (unless a shorter term is mandated by Card Brand(s), Acquirers
                and/or APMPs), to change the terms, conditions or specifications
                of any of the Services (i) to comply with the request of any
                Card Brand(s), Acquirers or APMPs; or (ii) as required in
                connection with changes in Applicable Law, the Rules or any
                other regulatory guidelines which affect Servicer's ability to
                provide Services or the Merchant's ability to use or receive
                Services. Changes in the Services which are made at Merchant's
                request and the respective effect on fees (if any) shall be
                addressed separately.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.6.</Div>
              <Div col>
                Without derogating from any other right available to Servicer
                under this Agreement, Applicable Law or otherwise, it is agreed
                that Servicer has the right, in its sole discretion, to suspend
                processing in any jurisdiction at any time and for any period of
                time on the basis of risk management considerations or as
                required to remain in compliance with any Rules or Applicable
                Law.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.7.</Div>
              <Div col>
                The Merchant must ensure that its systems and the Transactions
                processed comply at all times with all reasonable technical,
                communications, implementation and integration requirements as
                provided by Servicer from time to time. Such compliance is
                mandatory to Servicer's ability to provide Services as described
                and Merchant should not make alterations to the Merchant's
                systems which may affect integration with Servicer without first
                consulting with Servicer and properly testing the change.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.8.</Div>
              <Div col>
                Certain components of the Services may be provided by or rely on
                third parties (e.g. Card Brands, Acquirers, APMPs, internet
                service providers, Servicers, processing networks, money
                transfer systems, external databases etc.) and such Services are
                also subject to such third party's performance, availability and
                terms and conditions and Servicer will not be responsible for
                the performance, availability or service levels (or lack
                thereof) of these third parties.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.9.</Div>
              <Div col>Documentation and Information.</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>2.9.1.</Div>
              <Div col>
                Provision of Documentation and Information. The Merchant shall
                be entitled to use the Services after and for as long as it has
                satisfied certain Servicer verification, know-your-Merchant and
                risk based related requirements ("KYC Requirements"). KYC
                Requirements may be updated or supplemented from time to time
                and may vary between Services.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>2.9.2.</Div>
              <Div col>
                Changes in Documentation or Information. The Merchant undertakes
                to notify Nuvei promptly of any changes which may occur
                regarding any documentation and information it provided
                including but not limited with respect to the Merchant's
                business (goods and services, tradenames or URLs, geographical
                targets, Account details, etc.), the Merchant's location
                (physical address), equity or beneficial ownership and fixed
                place of business through which it conducts its business, as
                such address was disclosed to and approved by Servicer, as well
                as with respect to the Merchant's financial standing and ability
                to meet its obligations in this Agreement. In certain cases,
                Servicer may approach Merchant as to such changes if they come
                to Servicer's attention and the Merchant shall cooperate in
                providing information to Servicer accordingly. Said notice shall
                be in writing and shall include all relevant details and updated
                documents and information. The Merchant acknowledges and agrees
                that Servicer shall have the right to examine these changes and
                determine whether such changes affect Servicer's decision to
                provide Services or the manner in which Services are provided to
                the Merchant and to notify the Merchant accordingly.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>2.9.3.</Div>
              <Div col>
                Full Cooperation. The Merchant shall fully cooperate with
                Servicer's initial and ongoing requests for documentation and
                information and undertakes to assist Servicer in the
                verification and compliance with the KYC Requirements as shall
                be required, including by providing any required documentation
                and other information, executing any required agreements,
                instruments and other certifications.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>2.9.4.</Div>
              <Div col>
                Essentiality of Cooperation. The Merchant acknowledges that
                Merchant's meeting these provisions is essential to Servicer's
                ability to provide Services and understands that Merchant's
                failure to comply in a timely manner entitles Servicer to
                suspend the Merchant's access to part or all of the Services
                with immediate effect. The Merchant acknowledges that funds may
                be withheld until such time as all the documentation that has
                been requested has been provided.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>2.10.</Div>
              <Div col>
                Disclaimer. THE MERCHANT RECOGNIZES THAT SERVICER PROVIDES
                SERVICES ON AN "AS-IS" AND "AS AVAILABLE" BASIS. EXCEPT AS
                EXPRESSLY PROVIDED IN THIS AGREEMENT AND TO THE EXTENT PERMITTED
                BY APPLICABLE LAW, SERVICER SPECIFICALLY AND EXPLICITLY
                DISCLAIMS ANY AND ALL WARRANTIES OF ANY TYPE OR NATURE
                WHATSOEVER WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
                INCLUDING, WITHOUT LIMITATION: (1) WARRANTIES OF
                MERCHANTABILITY, SUITABILITY, SATISFACTORY QUALITY,
                NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE OR USE, OR
                ACCURACY IN RELATION TO OR ARISING OUT OF OR IN CONNECTION TO
                ANY INFORMATION PROVIDED; OR (2) ANY WARRANTIES OF ANY NATURE
                RELATING TO THE PLATFORM, SERVICES OR OTHERWISE TO SERVICER'S
                PERFORMANCE. SERVICER MAKES NO WARRANTIES OR REPRESENTATIONS
                ABOUT THE ACCURACY OR COMPLETENESS OF THE RESPECTIVE PLATFORM,
                SERVICES AND PRODUCTS OR THAT THE RESPECTIVE PLATFORM, SERVICES
                AND PRODUCTS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR
                FREE OR THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY WILL BE
                CORRECTED.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.</Div>
              <Div col>TRANSACTION SPECIFIC GUIDELINES</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.1.</Div>
              <Div col>
                <p>
                  Honoring Cards. Merchant will accept all valid Cards when
                  properly presented by Cardholders in payment for goods or
                  services, subject to applicable Card Brand rules requiring
                  Merchant to elect whether it will accept credit only, debit
                  only or both debit and credit Cards. Merchant's election is
                  set forth in the Application Form. Merchant may not establish
                  minimum or maximum amounts for Card sales as a condition for
                  accepting any Card.
                </p>
                <p>
                  Merchant may not require any Cardholder to pay as a surcharge
                  any part of any discount or charge imposed upon Merchant by
                  this Agreement, whether through any increase in price or
                  otherwise require a Cardholder to pay any charge or price as a
                  condition of sale that is not also required from a customer
                  paying cash. Notwithstanding the foregoing sentence, Merchant
                  may impose a surcharge on certain Card Transactions, but only
                  where permitted by Applicable Law and the Card Brand rules.
                  Merchant (and not Servicer) is solely responsible for ensuring
                  that it is permitted by Appliable Law and the Card Brand rules
                  to impose a surcharge. Merchant may be required to provide
                  notification to the Card Brands if it imposes a surcharge and
                  hereby authorizes Servicer to provide such notification and
                  furnish any necessary documentation, on Merchant's behalf, to
                  the Card Brands.
                </p>
                <div>
                  However, Merchant may not, by this term, be prevented from
                  offering discounts to Cardholders for cash purchases. Merchant
                  may not engage in a Transaction (other than a mail, internet,
                  telephone order, or preauthorized sale to the extent permitted
                  under this Agreement) if the person seeking to charge the
                  purchase to his or her Card account does not present the Card
                  to permit Merchant to compare the signature on the Card to the
                  signature on the Sales Draft and obtain an Imprint or
                  otherwise use the physical Card to complete the Transaction.
                </div>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.2.</Div>
              <Div col>
                Advertising. Merchant will prominently display the promotional
                materials provided by Servicer in its place(s) of business.
                Merchant's use of promotional materials and use of any Marks
                associated with a Card is limited to informing the public that
                the Card will be accepted at Merchant's place(s) of business.
                Merchant's use of promotional materials and Marks is subject to
                the Servicer's direction. Merchant may use promotional materials
                and Marks only during the term of this Agreement and will
                immediately cease use and return any inventory to Servicer upon
                any termination of this Agreement. Merchant may not use any
                promotional materials or Marks associated with the Card Brands
                in any way which suggests or implies that any of the Card Brands
                endorses any goods or services other than payment Card services.
                If relevant, Merchant is required to display UnionPay's service
                hotline number at Merchant's place of business.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.3.</Div>
              <Div col>
                Card Acceptance. When accepting a Card, Merchant will follow the
                steps provided by Servicer for accepting Cards and will: (a)
                determine in good faith and to the best of its ability that the
                Card is valid on its face; (b) obtain Authorization from the
                Card Issuer to charge the Cardholder's account; (c) unless the
                Sales Draft is electronically generated or is the result of a
                mail, internet, phone or preauthorized order, (i) obtain an
                Imprint of the Card including embossed data from the merchant
                imprinter plate; and (ii) obtain the Cardholder's signature on
                the Sales Draft and compare that signature to the signature on
                the Card; (d) enter a description of the goods or services sold
                and the price thereof (including any applicable taxes); (e)
                deliver a true and completed copy of the Sales Draft to the
                Cardholder at the time the goods are delivered or services
                performed, or, if the Sales Draft is prepared by a point-of-sale
                terminal, at the time of the sale; (f) offer the Sales Draft to
                Servicer for purchase according to Servicer's procedures and the
                terms of this Agreement; and (g) make a Card Imprint, if the
                Transaction is not based upon a mail, internet, phone or
                pre-authorized order.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.4.</Div>
              <Div col>
                Authorization. Merchant will obtain an Authorization for all
                Card sales. If Merchant cannot, for any reason, obtain an
                electronic Authorization through the use of a terminal, Merchant
                will request a Voice Authorization from Servicer's designated
                authorization center and will legibly print the authorization
                number on the Sales Draft. Merchant will not obtain or attempt
                to obtain Authorization from Servicer's authorization center
                unless Merchant intends to submit to Servicer a Transaction for
                the authorized amount if Authorization for the Transaction is
                given. Merchant may not divide a single Transaction between two
                or more Sales Drafts on a single Card to avoid Authorization
                limits that may be set by the Card Issuer. The Merchant shall
                not use Sales Drafts (paper or electronic), for purposes outside
                of the scope of this Agreement, nor shall a third party not
                included in this Agreement be allowed to use them. Merchant
                acknowledges that an Authorization provides only that the
                Cardholder account has sufficient credit available to cover the
                amount of the current sale and that an Authorization is not a
                guarantee that the Transaction will not be subject to dispute or
                Chargeback and does not warranty the Cardholder's identity.
                Merchant may not attempt to obtain an Authorization by
                successively decreasing the sale amount. Servicer may refuse to
                purchase or process any Sales Draft presented by Merchant: (a)
                unless a proper Authorization or approval code has been recorded
                on the Sales Draft; (b) if Servicer determines that the Sales
                Draft is or is likely to become uncollectible from the
                Cardholder to which the Transaction would otherwise be charged;
                or (c) if Servicer has reason to believe that the Sales Draft
                was prepared in violation of any provision of this Agreement.
                Merchant will use, and may not circumvent, fraud identification
                tools requested by Servicer, including address verification
                service checks and CVV2/CVC2/CID processing, and acknowledges
                that the use of these tools may prevent Merchant from accepting
                certain Cards as payment. Merchant acknowledges that its use of
                fraud identification tools may not prevent fraudulent Card usage
                and agrees that any fraudulent Transaction may ultimately result
                in a Chargeback, for which Merchant retains full liability under
                this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.5.</Div>
              <Div col>
                Retention and Retrieval of Cards. Merchant will use its best
                efforts, by reasonable and peaceful means, to retain or recover
                a Card when receiving such instructions when making a request
                for Authorization or if Merchant has reasonable grounds to
                believe the Card is counterfeit, fraudulent or stolen.
                Merchant's obligations under this Section does not authorize a
                breach of the peace or any injury to persons or property, and
                Merchant will hold Servicer harmless from any claim arising from
                any injury to person or property or other breach of the peace in
                connection with the retention or recovery of a Card.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.6.</Div>
              <Div col>
                Multiple Transaction Records; Partial Consideration. Merchant
                may not prepare more than one Sales Draft for a single sale or
                for a single item but will include all items of goods and
                services purchased in a single Transaction in the total amount
                on a single Sales Draft except under the following
                circumstances: (a) for purchases in separate departments of a
                multiple department store; (b) for partial payment, installment
                payment, delayed delivery or an advance deposit; or (c) for
                delayed or amended charges governed by rules for travel and
                entertainment merchants and Transactions.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.7.</Div>
              <Div col>
                Telephone Orders, Mail Orders, Internet, Preauthorized Orders
                and Installment Orders. Unless Merchant has been approved by
                Servicer to accept mail, internet or telephone orders, Merchant
                warrants that it is a walk-in trade business, located in a
                retail business place where the public moves in and out freely
                in order to purchase merchandise or obtain services. If Servicer
                determines Merchant has accepted unapproved Card Transactions
                which are placed by telephone, generated through telephone
                solicitation, mail order or other means that does not create a
                Sales Draft that bears the Card Imprint and Cardholder's
                signature, this Agreement will be immediately terminated and the
                value of all Sales Drafts collected from the first day of
                processing may be charged back to Merchant and all funds
                therefrom held as provided in Section 4.6 (Reserve Account).
                Unless approved by Servicer, this Agreement does not contemplate
                regular acceptance of Cards for sales accepted by mail, internet
                or telephone nor through preauthorized orders. If an occasional
                Card Transaction is made by mail, phone or preauthorized order,
                the Sales Draft may be completed without the Cardholder's
                signature or an Imprint, but in such case Merchant will create a
                sales slip containing Cardholder data, an Authorization number,
                the sale amount and the letters "MO", "TO" or "PO", as
                appropriate. Receiving an Authorization will not relieve the
                Merchant of liability for Chargeback on any Transaction for
                which the Merchant did not obtain an Imprint or the Cardholder's
                signature.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.8.</Div>
              <Div col>
                Lodging and Vehicle Rental Transactions. Merchant must estimate
                and obtain Authorization for the amount of the Transaction based
                upon the Cardholder's intended length of stay or rental.
                Additional Authorization must be obtained and recorded for
                charges actually incurred in excess of the estimated amount.
                Regardless of the terms and conditions of any written
                preauthorization form, the Sales Draft amount for any lodging or
                vehicle rental Transaction must include only that portion of the
                sale, including any applicable taxes, evidencing a bona fide
                rental of real or personal property by Merchant to the
                Cardholder and may not include any consequential charges.
                Nothing contained herein is intended to restrict Merchant from
                enforcing the terms and conditions of its preauthorization form
                through means other than a Card Transaction.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.9.</Div>
              <Div col>
                Returns and Adjustments; Credit Vouchers. Merchant's policy for
                the exchange or return of goods sold and the adjustment for
                services rendered will be established and posted in accordance
                with operating regulations of the applicable Card Brand's
                regulations. Merchant will disclose, if applicable, to a
                Cardholder before a Card sale is made, that if merchandise is
                returned: (a) no refund, or less than a full refund, will be
                given; (b) returned merchandise will only be exchanged for
                similar merchandise of comparable value; (c) only a credit
                toward purchases will be given; or (d) special conditions or
                circumstances apply to the sale (e.g., late delivery, delivery
                charges, or other non-credit terms). If Merchant does not make
                these disclosures, a full refund in the form of a credit to the
                Cardholder's Card account must be given. Disclosures must be
                made on all copies of Sales Drafts or invoices in letters
                approximately 1/4" high in close proximity to the space provided
                for the Cardholder's signature or on an invoice issued at the
                time of the sale or on an invoice being presented for the
                Cardholder's signature. Any change in Merchant's return or
                cancellation policy must be submitted in writing to Servicer not
                less than 14 days prior to the change. Servicer may refuse to
                process any Sales Draft made subject to a revised return or
                cancellation policy of which Servicer has not been notified as
                required herein.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.10.</Div>
              <Div col>
                Cash Payments. Merchant may not receive any payments from a
                Cardholder for charges included in any Transaction resulting
                from the use of any Card nor receive any payment from a
                Cardholder to prepare and present a Transaction for the purpose
                of affecting a deposit to the Cardholder's Card account.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.11.</Div>
              <Div col>
                Cash Advances; Scrip Purchases. Merchant may not deposit any
                Transaction for the purpose of obtaining or providing a cash
                advance either on Merchant's Card or the Card of any other party
                and may not accept any Card at a scrip terminal, and either
                action will be grounds for Servicer's immediate termination of
                this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.12.</Div>
              <Div col>
                Duplicate Transactions. Merchant may not deposit duplicate
                Transactions. Servicer may debit Merchant for any adjustments
                for duplicate Transactions and Merchant is liable for any
                Chargebacks resulting therefrom.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.13.</Div>
              <Div col>
                Deposit of Fraudulent Transactions. Merchant may not accept or
                deposit any fraudulent Transaction and may not under any
                circumstances present for processing or credit, directly or
                indirectly, a Transaction which originated with any other
                merchant or any other source other than Transactions arising
                from bona fide purchases from Merchant for the goods and
                services for which Merchant has been approved under this
                Agreement. If Merchant deposits any prohibited Transaction,
                Servicer may: (a) immediately terminate this Agreement; (b)
                withhold funds and demand an escrow as provided in this
                Agreement; (c) report Merchant to the Card Brands under Section
                5.4. Merchant's employees' actions are chargeable to Merchant
                under this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.14.</Div>
              <Div col>
                Merchant violations. The following actions are prohibited for
                Merchant: (i) alteration of the amount on Transaction receipts,
                split Transactions, cash out, acceptance of credit cards listed
                in the card recovery bulletin, excessive usage above the
                authorized limit, insufficient signature and expiry date
                checking, refund in cash, late presentment, submitting false
                Transactions to Servicer, obtaining extra credit from a Card
                Issuer by using transaction types of pre-authorization, purchase
                cancellation & purchase cancellation reversal, pre-authorization
                completion cancellation & pre-authorization completion
                cancellation reversal etc.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.15.</Div>
              <Div col>
                Collection of Pre-existing Debt. Merchant may not prepare and
                present to Servicer for purchase any Transaction representing
                the refinancing of an existing Cardholder obligation including,
                but not limited to, obligations: (a) previously owed to
                Merchant; (b) arising from the dishonor of a Cardholder's
                personal check or relating to a Chargeback; or (c) representing
                the collection of any other pre-existing indebtedness, including
                collection of delinquent accounts on behalf of third parties.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.16.</Div>
              <Div col>
                <p>
                  Data Security/Personal Cardholder Information. Merchant may
                  not, as a condition of sale, impose a requirement on
                  Cardholders to provide any personal information as a condition
                  for honoring Cards unless such information is required to
                  provide delivery of goods or services, or Merchant has reason
                  to believe the identity of the person presenting the Card may
                  be different than that of the Cardholder. Merchant will not,
                  under any circumstances, release, sell or otherwise disclose
                  any Cardholder Information to any person other than Servicer
                  or the applicable Card Brand, except as expressly authorized
                  in writing by the Cardholder, or as required by Applicable
                  Law.
                </p>
                <p>
                  a) Safeguards. Merchant will maintain appropriate
                  administrative, technical and physical safeguards for all
                  Cardholder Information. These safeguards will: (a) ensure the
                  confidentiality of Cardholder Information; (b) protect against
                  any anticipated threats or hazards to the security or
                  integrity of Cardholder Information; (c) protect against
                  unauthorized access to or use of Cardholder Information that
                  could result in substantial harm or inconvenience to any
                  Cardholder; and (d) properly dispose of all Cardholder
                  Information to ensure no unauthorized access to Cardholder
                  Information. Merchant will maintain all such safeguards
                  applicable to Merchant or Servicer in accordance with
                  Applicable Law, rules, regulations and guidance. Merchants
                  transacting in electronic commerce must: offer Cardholders
                  secure transaction methods such as SSL or 3-D Secure; install
                  and maintain network firewalls; regularly update security
                  patches; restrict and track employee access to all data
                  relating to Cardholders and Card Transactions ("Data");
                  encrypt all stored Data sent over open networks; use only
                  approved or validated payment software applications; establish
                  policies for properly managing use and allocation of
                  passwords; and consistently assess and revise security systems
                  and processes.
                </p>
                <p>
                  b) Compliance with Card Brand Rules. Merchant represents,
                  warrants and covenants that it is and will remain throughout
                  the term of this Agreement in compliance with Card Brand
                  bylaws, operating regulations and rules related to data
                  security, data integrity and the safeguarding of Cardholder
                  Information including the Payment Card Industry Data Security
                  Standard ("PCI")
                  (https://www.pcisecuritystandards.org/security_standards/),
                  Visa's Customer Information Security Program ("CISP")
                  (http://usa.visa.com/merchants/risk_management/cisp.html),
                  Discover's Information Security and Compliance (DISC) program
                  (http://www.discovernetwork.com/merchants/data-security/disc.html),
                  Mastercard's Site Data Protection Program ("SDP")
                  (http://www.Mastercard.com/us/company/en/whatwedo/site_data_protection.html)
                  and the American Express Data Security Requirements ("DSR")
                  (http://www.americanexpress.com/dsr) in effect, and Merchant
                  will cause all of its service providers, subcontractors and
                  agents to comply with PCI, SDP, CISP, DSR and DISC
                  requirements at all times. Merchant will report any
                  non-compliance immediately to Servicer. To accomplish the
                  foregoing, Merchant will encrypt all debit, credit or stored
                  value card numbers whether in storage, transport or backup and
                  will not store data security codes on its systems, network or
                  software.
                </p>
                <p>
                  c) Annual Certification. Merchant will provide an annual
                  certification to Servicer if requested by Servicer (in a form
                  acceptable to Servicer) certifying compliance with the data
                  security provisions of this Agreement, including compliance
                  with applicable Card Brand requirements such as PCI, SDP,
                  CISP, DSR and DISC. Merchant will provide annual
                  certifications for Merchant's service providers,
                  subcontractors and agents.
                </p>
                <p>
                  d) Information Use Limitations. Merchant may not sell,
                  disclose, or otherwise make Cardholder Information available,
                  in whole or in part, in a manner not provided for in this
                  Agreement, without Servicer's prior written consent. Merchant
                  may, however, disclose Cardholder Information to its service
                  providers, subcontractors and agents who have a need to know
                  such information to provide the Services described in this
                  Agreement, provided that those individuals or entities have
                  assumed confidentiality obligations in accordance with this
                  Agreement, or as may be required by legal process or
                  Applicable Law, rules, regulations and guidance and have
                  entered into a written agreement with Merchant containing
                  Merchant's and such individuals' or entities' agreement to the
                  foregoing data security provisions including compliance with
                  Card Brand rules, regulations or bylaws.
                </p>
                <p>
                  e) Response to Unauthorized Access. Merchant will notify
                  Servicer within 24 hours after it knows of any breach in
                  security resulting in an unauthorized access to Cardholder
                  Information. Merchant will provide any assistance that
                  Servicer, the issuing bank of any Cardholder, and their
                  regulators and the Card Brands deem necessary to contain and
                  control the incident to prevent further unauthorized access to
                  or use of Cardholder Information. Such assistance may include,
                  but not be limited to, allowing Servicer and Card Brands to
                  use its risk information for normal business practices,
                  preserving records and other evidence and compiling
                  information to enable Servicer and the issuing bank(s) or the
                  Card Brands to investigate the incident and provide assistance
                  and cooperation to: (a) file suspicious activity reports (as
                  applicable); (b) notify their regulators (as applicable); and
                  (c) notify the affected Cardholder (as required). Unless the
                  unauthorized access was due to Servicer's acts or omissions,
                  Merchant will bear the cost of notifying affected Cardholder.
                  Servicer has the right to immediately stop all Transactions,
                  enforce rectification and terminate the Merchant Agreement
                  immediately for cause if Merchant trespasses the transaction
                  security protocol or uses a mobile point of sale, including a
                  smartphone, tablet, or any other dedicated wireless device
                  that performs the functions of a cash register or electronic
                  point of sale terminal without Servicer's authorization.
                </p>
                <p>
                  f) Terminal Security. Merchant shall be liable for all
                  Transactions initiated through any terminal used to originate
                  Transactions pursuant to this Agreement, including
                  Transactions processed without Merchant's permission by third
                  parties that use Merchant's credentials or password, or who
                  otherwise unlawfully access Merchant's terminal(s). Merchant
                  shall be liable for any monetary losses that result from
                  Transactions made using Merchant's terminal(s) or credentials,
                  but without Merchant's permission. Merchant is strictly
                  responsible for establishing and maintaining the procedures to
                  safeguard against unauthorized transmissions. Merchant
                  represents and warrants that no individual will be allowed to
                  initiate Transactions in the absence of proper supervision and
                  safeguards and agrees to take reasonable steps to maintain the
                  confidentiality of any passwords, codes, security devices, and
                  related instructions provided by Nuvei. If Merchant believes
                  that any such passwords, codes, security devices, and related
                  instructions have been known or accessed by unauthorized
                  persons, Merchant agrees to notify Nuvei immediately followed
                  by written confirmation. The occurrence of unauthorized access
                  will not affect Merchant's liability for any Transactions
                  processed in good faith by Nuvei, any Card Brand, or Bank,
                  whether prior to receipt of such notification or during any
                  period of time reasonably needed to prevent the occurrence of
                  unauthorized Transactions following notification.
                </p>
                <p>
                  g) Miscellaneous. Merchant may not make a claim against
                  Servicer or hold Servicer liable for the acts or omissions of
                  other merchants, service providers, Card Brands, financial
                  institutions or others that do not have a written contractual
                  relationship with Servicer or over which Servicer has no
                  control. These provisions supplement, augment and are in
                  addition to obligations of indemnification, audit,
                  confidentiality and other similar provisions contained in this
                  Agreement. This Section 3.16 and each of its subsections will
                  survive this Agreement's termination. Merchant may not store
                  in any system or in any manner discretionary Card read data
                  including without limitation CVV2/CVC2/CID data, PIN data,
                  address verification data or any other information prohibited
                  by Card Brand rules. Merchant agrees that Servicer may
                  disclose to any Card Brand information regarding Merchant and
                  Merchant's Transactions to any Card Brand, and that such Card
                  Brand may use such information to perform its responsibilities
                  in connection with its duties as a Card Brand, promote the
                  Card Brand, perform analytics and create reports, and for any
                  other lawful business purposes, including commercial marketing
                  communications purposes within the parameters of Card Brand
                  Card acceptance, and transactional or relationship
                  communications from a Card Brand. A Card Brand may use the
                  information about Merchant obtained in this Agreement at the
                  time of setup to screen and/or monitor Merchant in connection
                  with the Card Brand marketing and administrative purposes.
                  Merchant agrees it may receive messages from a Card Brand,
                  including important information about Card Brand products,
                  services, and resources available to its business. These
                  messages may be sent to the mailing address, phone numbers,
                  email addresses or fax numbers of Merchant. Merchant may be
                  contacted at any telephone numbers, including cellular phone
                  numbers, and the communications sent may include autodialed
                  short message service (SMS or "text") messages or automated or
                  prerecorded calls. Merchant agrees that it may be sent fax
                  communications. As a service provider, ISO acknowledges that
                  it is responsible for the security of Cardholder data it
                  possesses, stores, processes or transmits on behalf of
                  Merchant.
                </p>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.17.</Div>
              <Div col>
                Compliance with Card Brand Rules. Merchant will comply with and
                conduct its Card activities in accordance with all applicable
                Card Brand rules and regulations. Failure to comply with such
                rules and regulations may result in Merchant being terminated
                for cause and listed on various Card Brand and industry
                databases, including the Consortium Merchant Negative File
                (CMNF), the CTMF (Combined Terminated Merchant File) and the
                Merchant Alert to Control High Risk Merchants file ("MATCH").
                Merchant may not: (a) accept Cardholder payments for previous
                Card charges incurred at the Merchant location; (b) establish a
                minimum or maximum Transaction amount as a condition for
                honoring a Card; (c) require a Cardholder to complete a postcard
                or similar device that includes the Cardholder's account number,
                card expiration date, signature, or any other card account data
                in plain view when mailed; (d) add any surcharge to
                Transactions; (e) add any tax to Transactions, unless Applicable
                Law expressly requires that Merchant be permitted to impose a
                tax (any tax amount, if allowed, must be included in the
                Transaction amount and not collected separately); (f) enter into
                interchange any transaction receipt for a Transaction that was
                previously charged back to Servicer and subsequently returned to
                Merchant, irrespective of Cardholder approval (Merchant may
                pursue payment from the Cardholder outside the Card Brand
                system); (g) accept a card for an unlawful Internet gambling
                transaction; (h) request or use an account number of any purpose
                other than as payment for its goods or services; (i) disburse
                funds in the form of travelers cheques, if the sole purpose is
                to allow the Cardholder to make a cash purchase of goods or
                services from Merchant; (j) disburse funds in the form of cash,
                unless: (i) Merchant is a lodging or cruise line merchant
                disbursing cash to a Cardholder, (ii) Merchant is dispensing
                funds in the form of travelers cheques, Cards, or foreign
                currency, or (iii) Merchant is participating in the Card Brand
                cash back service; (k) accept a Card for the purchase or scrip;
                (l) accept a Card for manual cash disbursement; (m) accept a
                Card to collect or refinance existing debt that has been deemed
                uncollectible by the Merchant providing the associated goods or
                services; (n) enter into a Transaction that represents
                collection of a dishonored check; or (o) charge a convenience
                fee which does not meet the criteria set by the Rules. Merchant
                will pay all Assessments which are, in Servicer's discretion,
                attributable to Merchant's Transaction processing or business.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.18.</Div>
              <Div col>
                Merchant will notify Servicer immediately if it intends to (a)
                transfer or sell any substantial part of its total assets, or
                liquidate; (b) change the basic nature of its business,
                including selling any products or services not related to its
                current business; (c) change ownership or transfer control of
                its business; (d) enter into any joint venture, partnership or
                similar business arrangement whereby any person or entity not a
                party to this Agreement assumes any interest in Merchant's
                business; (e) alter in any way Merchant's approved monthly
                volume, average, or maximum ticket; (f) changes its return
                policies or to another fulfillment house different from those
                identified in the Merchant Application; or (g) changes to its
                Account. Merchant will notify Servicer promptly in writing if it
                becomes subject to any voluntary or involuntary bankruptcy or
                insolvency petition or proceeding. Merchant's failure to provide
                notice as required above may be deemed a material breach and
                will be sufficient grounds for termination of Merchant and for
                Servicer's exercise of all its rights and remedies provided by
                this Agreement. If any change listed above occurs, Servicer may
                immediately terminate this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.19.</Div>
              <Div col>
                Merchant's Warranties. Merchant represents and covenants that:
                (a) all information contained in the Merchant Application or any
                other documents delivered to Servicer in connection therewith is
                true and complete and properly reflects Merchant's business,
                financial condition and principal partners, owners or officers;
                (b) Merchant has power to execute, deliver and perform this
                Agreement, and this Agreement is duly authorized, and will not
                violate any provisions of law, or conflict with any other
                agreement to which Merchant is subject; (c) Merchant holds all
                licenses, if any, required to conduct its business and is
                qualified to do business in every jurisdiction where it is
                required to do so; (d) there is no action, suit or proceeding at
                law or in equity now pending or to Merchant's knowledge,
                threatened by or against or affecting Merchant which would
                substantially impair its right to carry on its business as now
                conducted or adversely affect its financial condition or
                operations; (e) each Sales Draft presented to Servicer for
                collection is genuine and is not the result of any fraudulent or
                prohibited Transaction or is not being deposited on behalf of
                any business other than Merchant as authorized by this
                Agreement; (f) each Sales Draft is the result of a bona fide
                Card Transaction for the purchase of goods or services from
                Merchant by the Cardholder in the total amount stated on the
                Sales Draft; (g) Merchant has performed or will perform all of
                its obligations to the Cardholder in connection with the Card
                Transaction evidenced thereby; (h) Merchant has complied with
                Servicer's procedures for accepting Cards, and the Card
                Transaction itself does not involve any element of credit for
                any other purposes other than as set forth in this Agreement,
                and is not subject to any defense, dispute, offset or
                counterclaim which may be raised by any Cardholder under the
                Card Brands' rules, the Consumer Credit Protection Act (15 USC
                &sect;1601) or other relevant state or federal statutes or
                regulations; and (i) any Credit Voucher which it issues
                represents a bona fide refund or adjustment on a Card sale by
                Merchant with respect to which a Sales Draft has been accepted
                by Servicer.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>3.20.</Div>
              <Div col>
                False Statements. During the term of this Agreement and for two
                years following its expiration or termination, Merchant shall
                not make, publish or communicate to any person or entity or in
                any public forum any false statements (written or oral) about
                Servicer, its directors, officers or employees, or its products
                or services, where such statements harm or are likely to harm
                the goodwill, reputation, stature or profitability of Servicer
                or deter others from doing business with Servicer.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.</Div>
              <Div col>PRESENTMENT, PAYMENT, CHARGEBACKS, RESERVE ACCOUNT</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.1.</Div>
              <Div col>
                Acceptance. Servicer will accept from Merchant all Sales Drafts
                deposited by Merchant under the terms of this Agreement and will
                present the same to the appropriate Card Issuers for collection
                against Cardholder accounts. Merchant must transmit Sales Drafts
                and Credit Vouchers to Servicer or its processing vendor on the
                same or next business day immediately following the day that
                such Sales Drafts and Credit Vouchers have been originated. All
                presentment and assignment of Sales Drafts, collection therefor
                and reassignment or rejection of such Sales Drafts are subject
                to the terms of this Agreement and regulations of the Card
                Brand.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.2.</Div>
              <Div col>
                Servicer will only provisionally credit the value of collected
                Sales Drafts to Merchant's Account and reserves the right to
                adjust amounts collected to reflect the value of Chargebacks
                (actual and anticipated), fees, penalties, late submission
                charges, reserve deposits, negative Sales Draft batch deposits
                and items for which Servicer does not receive final payment.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.3.</Div>
              <Div col>
                Endorsement. By presenting Sales Drafts to Servicer for
                collection and payment, Merchant agrees to sell and assign all
                its right, title and interest in each Sales Draft completed in
                conformity with Servicer's acceptance procedures and constitutes
                an endorsement by Merchant to Servicer of such Sales Drafts.
                Servicer may supply such endorsement on Merchant's behalf.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.4.</Div>
              <Div col>
                Prohibited Payments. Bank may receive payment of any Sales Draft
                presented by Merchant and paid by Bank unless and until there is
                a Chargeback. Unless specifically authorized in writing by
                Servicer, Merchant may not collect or attempt to collect any
                Sales Draft, including Chargebacks, and will hold in trust for
                Bank and promptly deliver in kind to Servicer any payment
                Merchant receives, in whole or in part, of the amount of any
                accepted Transaction, together with the Cardholder's name and
                account number and any correspondence accompanying payment.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.5.</Div>
              <Div col>
                Chargebacks. Merchant will accept for chargeback any sale for
                which the Cardholder disputes the validity of the sale according
                to prevailing Card Brand regulations, or a Card Issuer or
                Servicer determines that Merchant has in any way failed to
                comply with Card Brand regulations or Servicer's procedures in
                accepting a Card and presenting the resulting Sales Draft to
                Servicer for purchase. Section 3.3 notwithstanding, Servicer may
                charge back the amount of a Card sale for which the Cardholder
                disputes having authorized the charge if Merchant failed to
                obtain the Card Imprint or the Cardholder's signature. Merchant
                may not initiate a sale Transaction in an attempt to collect a
                Chargeback. Merchant will pay the current published fee of
                $25.00 for each Chargeback.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>4.6.</Div>
              <Div col>
                Reserve Account. Although Servicer has determined that Merchant
                does not need to establish a reserve ("Reserve Account") to
                begin processing Transactions, Servicer retains the right to
                impose a Reserve Account requirement upon Merchant, in its
                reasonable discretion to mitigate against a potential risk of
                loss or excessive Chargebacks. Provided, however, such a reserve
                shall be limited to the amount reasonably necessary to mitigate
                against the risks for which the Reserve Account was imposed and
                only for a period of time reasonably necessary to protect
                against such loss. If a Reserve Accounts is required, Merchant
                acknowledges and agrees that Servicer may either require
                Merchant to fund a Reserve Account or, in Servicer's reasonable
                discretion, Servicer may withhold amounts from settlement to be
                held in a Reserve Account by Servicer. Merchant further
                acknowledges and agrees that its Reserve Account may be
                commingled with reserve funds held for other merchants. Amounts
                held in the Reserve Account will remain in the Reserve Account
                until the risk of loss or other circumstances reasonably
                supporting Servicer's imposition of the reserve have passed,
                which shall not exceed 180 days post termination of this
                Agreement. Merchant acknowledges and agrees that this Reserve
                Account may be used to fund any and all Chargebacks or returned
                items that Merchant fails to satisfy as required in this
                Agreement. In the event that Servicer exercises its right to
                establish a Reserve Account pursuant to this Section, Merchant
                may, subject to the following provisions, terminate the
                Agreement upon 30 days advance written notice to Servicer
                provided Servicer receives such written notice from Merchant of
                its intention to so terminate within 90 days of the date on
                which Servicer establishes the Reserve Account. Upon Servicer's
                receipt of Merchant's written notice pursuant to the immediately
                preceding sentence, Servicer may, at its option and as
                applicable, return any funds held in a Reserve Account to
                Merchant, and, in the event Servicer elects to return the
                reserve to Merchant, Merchant shall not have the right to
                terminate this Agreement pursuant to this Section and this
                Agreement shall remain in full force and effect notwithstanding
                Merchant's written notice to terminate.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>4.6.1.</Div>
              <Div col>
                In the event a reserve is held, Merchant acknowledges and agrees
                that, until such time as all of the amounts owed by Merchant and
                its obligations, including its obligation to pay all
                chargebacks, are paid to Servicer in full or Merchant has
                otherwise provided security for such obligations, all funds in
                the Reserve Account shall be considered to be held by Servicer
                for Servicer's sole interest, benefit, and protection, shall be
                considered to be the property of Servicer, and shall not be
                considered to be held for the benefit of Merchant or be
                considered to be an asset for or property of Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>4.6.2.</Div>
              <Div col>
                Notwithstanding any of the foregoing and provided Merchant is
                not a governmental entity, as an additional and cumulative right
                under this Agreement, if Servicer reasonably believes that
                Merchant will in the future owe any such amounts under this
                Agreement, including for returns, anticipated chargebacks,
                fines, fees, or any other item described in this Agreement,
                Servicer may also identify, sequester, segregate or transfer to
                itself (including its own bank account(s)) any portion of the
                Reserve that Servicer reasonably believes is needed to pay such
                obligation and may hold and use such amount for its own benefit
                and protection (as opposed to any such amount being held for
                Merchant's benefit or the benefit of any third party). Merchant
                shall not have any possessory or equitable interest in any funds
                identified, sequestered, segregated or transferred pursuant to
                this Section.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>4.6.3.</Div>
              <Div col>
                The aforementioned rights and remedies are not intended to be
                exclusive and are intended to be cumulative of all of Servicer's
                other rights and remedies under this Agreement and applicable
                law.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.</Div>
              <Div col>TERM AND TERMINATION</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.1.</Div>
              <Div col>
                Term. This Agreement will be effective once Servicer accepts it,
                and unless otherwise terminated in accordance with Section 5.2
                below, will continue on a month-to-month basis until Merchant
                provides written notice of non-renewal at least fifteen (15)
                days before the end of the then-current monthly term.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.2.</Div>
              <Div col>
                <p>Termination.</p>
                <p>
                  a) Without Cause. Servicer may terminate this Agreement,
                  without cause, upon 30 days advance written notice to
                  Merchant. Merchant may terminate this Agreement without prior
                  notice in the three (3) day period following Servicer's
                  acceptance of the Merchant Application (the "Cooling Off
                  Period").
                </p>{" "}
                <p>
                  b) For Cause. Servicer may terminate this Agreement in its
                  sole discretion, effective immediately, upon written or verbal
                  notice, or by closing Merchant's point-of-sale terminal, if
                  Servicer reasonably determines that any of the following
                  conditions exists: (i) Merchant has violated any provision of
                  this Agreement; (ii) there is a material adverse change in
                  Merchant's financial condition; (iii) if any case or
                  proceeding is commenced by or against Merchant under any
                  Applicable Law dealing with insolvency, bankruptcy,
                  receivership or other debt relief; (iv) any information which
                  Merchant provided to Servicer, including Application Form
                  information, was false, incomplete or misleading when
                  received; (v) at any time during the term of this Agreement,
                  Merchant has had a monthly ratio of Chargebacks to total
                  Transactions exceeding Card Brand requirements or 1%, or
                  Chargebacks exceed 3% of any monthly dollar amount of total
                  Transactions; (vi) an overdraft in the Account exists for more
                  than three days; (vii) Merchant or any of Merchant's officers
                  or employees has been involved in processing Transactions
                  arising from fraudulent or otherwise unauthorized
                  transactions;(viii) Merchant is or will be unable or unwilling
                  to perform its obligations under this Agreement or Applicable
                  Law; (ix) Merchant has failed to timely pay Servicer any
                  amount due; (x) Merchant has failed to promptly perform or
                  discharge any obligation under its Account or the Reserve
                  Account; (xi) any of Merchant's representations or warranties
                  made in connection with this Agreement was not true or
                  accurate when given; (xii) Merchant has defaulted on any
                  agreement it has with the Servicer; (xiii) Servicer is served
                  with legal process seeking to attach or garnish any of
                  Merchant's funds or property in Servicer's possession, and
                  Merchant does not satisfy or appeal the legal process within
                  15 days of such service; (xiv) any Card Brand rules are
                  amended in any way so that the continued existence of this
                  Agreement would cause Servicer to be in breach of those rules;
                  (xv) if any circumstances arise regarding Merchant or its
                  business that create harm or loss of goodwill to any Card
                  Brand; (xvi) termination is necessary to prevent loss to
                  Servicer or Card Issuers; (xvii) Merchant's type of business
                  indicated on the Application Form or as conducted by Merchant
                  could endanger the Servicer's safety or soundness; (xiii)
                  Merchant's owner, officer, or corporate entity has a separate
                  relation- ship with the Servicer and that relationship is
                  terminated, (xix) Merchant appears on any Card Brand's
                  security reporting; or (xx) Servicer's security for repayment
                  becomes impaired, (xxi) failure to comply with the Servicer's
                  requirements repeatedly and refusal to remedy any violations;
                  (xxii) Merchant delays the response to inquiries or the
                  fulfillment of retrieval requests deliberately or without good
                  reason; or (xxiii) Merchant incurs an increased risk due to
                  other activities of the Merchant; or (xxiv) a Card Brand
                  prohibits Merchant from operating hereunder.
                </p>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.3.</Div>
              <Div col>
                Effect of Bankruptcy. Any account or security held by Servicer
                will not be subject to any preference, claim or stay by reason
                of bankruptcy or similar law. The parties expressly agree that
                the acquisition of Card Transactions hereunder is a financial
                accommodation and if Merchant becomes a debtor in any bankruptcy
                or similar proceeding, this Agreement may not be assumed or
                enforced by any other person and Servicer will be excused from
                performance hereunder.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.4.</Div>
              <Div col>
                Effect of Termination. When termination becomes effective, the
                parties' rights and obligations existing under this Agreement
                survive. If this Agreement is terminated, regardless of cause,
                Servicer may withhold and discontinue the disbursement for all
                Cards and other Merchant Transactions in the process of being
                collected and deposited. If Merchant is terminated for cause,
                Merchant acknowledges that Servicer may be required to report
                Merchant's business name and the names and other identification
                of its principals to the MATCH file maintained by the Card
                Brands and/or the CMNF file maintained by Discover. Merchant
                expressly agrees and consents to such reporting if Merchant is
                terminated for any reason requiring listing on the MATCH file or
                CMNF. Merchant waives and will hold harmless Servicer from any
                claims that Merchant may raise as a result of Servicer's MATCH
                file reporting, or Discover's CMNF reporting. Merchant will also
                immediately cease requesting Authorizations. If Merchant obtains
                any Authorization after termination, the fact that any
                Authorization was requested or obtained will not reinstate this
                Agreement. Further, Merchant will return all Servicer property,
                forms, or equipment. All obligations for Transactions prior to
                termination (including payment for Chargebacks and Servicer's
                expenses relating to Chargebacks) survive termination. Servicer
                is not liable to Merchant for damages (including prospective
                sales or profits) due to termination. Following termination,
                Merchant will upon request provide Servicer with all original
                and electronic copies of Sales Drafts and Credit Vouchers that
                have been retained by Merchant as of the date of termination.
                Upon termination, any amounts due to Servicer will accelerate
                and be immediately due and payable, without any notice,
                declaration or other act whatsoever by Servicer. Servicer shall
                terminate the acceptance of Cards by Merchant upon the
                termination of the Merchant Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>5.5.</Div>
              <Div col>
                Data After Termination. The delivery of data to Merchant by
                Servicer further to the expiration or termination of this
                Agreement shall be made at a cost and on terms and conditions to
                be then agreed upon. If Merchant does not request the delivery
                of data within 30 days of the expiration or termination hereof
                or if Merchant and Servicer fail to agree on such cost, terms
                and conditions, Servicer shall, unless prohibited by Applicable
                Law or the Card Brand rules, have the right to delete any such
                data found in Servicer's systems or otherwise under Servicer's
                control.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.</Div>
              <Div col>MISCELLANEOUS</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.1.</Div>
              <Div col>
                Account Monitoring. Merchant acknowledges that Servicer will
                monitor Merchant's daily deposit activity. Servicer may upon
                reasonable grounds suspend disbursement of Merchant's funds for
                any reasonable period of time required to investigate suspicious
                or unusual deposit activity. Servicer will make good faith
                efforts to notify Merchant promptly following suspension.
                Servicer is not liable to Merchant for any loss, either direct
                or indirect, which Merchant may attribute to any suspension of
                funds disbursement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.2.</Div>
              <Div col>
                Forms. Merchant will use only the forms or modes of transmission
                of Sales Drafts and Credit Vouchers that are provided or
                approved in advance by Servicer, and Merchant may not use such
                forms other than in connection with Card Transactions.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.3.</Div>
              <Div col>
                Indemnification. (a) Merchant shall at its expense and upon
                demand indemnify, defend and hold harmless Bank and its
                officers, directors, members, shareholders, partners, employees,
                agents, subcontractors, representatives and any Card Brand (each
                an "Indemnified Party") from any liability of any kind, loss,
                claim, damage, interest, fine, penalty, fee, charge, cost and/or
                expense (including reasonable and properly incurred legal and
                other professional fees, costs and/or expenses) (collectively
                "Losses"), which an Indemnified Party suffers or incurs in
                relation to any action, cause of action, dispute, controversy,
                complaint, suit, litigation, proceeding, claim, demand or
                assessment, fine or similar charge whether arising in contract,
                tort or otherwise (collectively "Claim") brought against an
                Indemnified Party by a third party where such Claim is caused by
                Merchant or arises out of any material breach of this Merchant
                Agreement by Merchant or as a result of, or in connection with
                Merchant's misrepresentation, material breach or failure to
                comply with the Rules or Applicable Law, and/or with respect to
                Merchant's websites, trademarks, products or services, and where
                relevant to Bank's use of the above, and including without
                limitation, in relation to actual or alleged infringement,
                misappropriation, or violation of any third-party intellectual
                property rights.Notwithstanding the preceding, Merchant is not
                liable to to indemnity an Indemnified Party if such Losses are
                caused by, related to or arise out of such Indemnified Party's
                recklessness or willful misconduct, or the Indemnified Party's
                material breach of this Agreement. Merchant will promptly
                reimburse Bank for any Assessments imposed by the Card Brand in
                connection with this Agreement, including the data security
                provisions, and authorizes Bank to deduct any such sums from
                amounts to be cleared and settled with Merchant.(b) As to
                Merchant, ITR, and Nuvei, each party shall at its expense and
                upon demand indemnify, defend and hold harmless the other
                party's officers, directors, members, shareholders, partners,
                epmloyees, agents, subcontractors, and representatives (each
                "Other Indemnified Party") from any Claim brought against an
                Other Indemnified Party by a third party where such Claim is
                caused by such party or arises out of any material breach of
                this Merchant Agreement by such party or as a result of, or in
                connection with such party's misrepresentation, material breach
                or failure to comply with the Rules or Applicable Law, and/or
                with respect to such party's websites, trademarks, products or
                services, in relation to actual or alleged infringement,
                misappropriation, or violation of any third-party intellectual
                property rights. Notwithstanding the preceding, none of ITR,
                Nuvei, or Merchant is liable to indemnity an Other Indemnified
                Party if such Losses are caused by, related to or arise out of
                such Other Indemnified Party's recklessness or willful
                misconduct, or the Other Indemnified Party's material breach of
                this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.4.</Div>
              <Div col>
                Records. In addition to any records Merchant routinely furnishes
                to Servicer under this Agreement, Merchant will preserve a copy
                of Sales Drafts and Credit Vouchers, in paper or electronic
                form, and any written authorization of the Cardholder for at
                least two years after the date Merchant presents the Transaction
                to Servicer. Merchant shall bear financial losses incurred due
                to inappropriate retention or loss of Sales Drafts or Credit
                Vouchers.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.5.</Div>
              <Div col>
                Requests for Copies. Immediately after Merchant receives the
                request by Servicer, Merchant will provide to Servicer either
                the original or a legible copy (in a size comparable to the
                actual Sales Draft) of the paper Sales Draft and any other
                documentary evidence available to Merchant that Servicer
                reasonably requests to meet Servicer's obligations under
                Applicable Law (including its obligations under the Fair Credit
                Billing Act) or otherwise to respond to questions concerning
                Cardholder accounts.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.6.</Div>
              <Div col>
                Compliance with Rules and Applicable Law. Merchant will comply
                with all Rules and Applicable Law, including without limitation
                all state and federal consumer credit and consumer protection
                statutes and regulations.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.7.</Div>
              <Div col>
                <p>
                  Fees and Charges. Merchant will pay to Servicer the fees and
                  charges set forth in the Application including any additional
                  charges applied to Transactions that fail to meet Card Brand
                  requirements for the lowest interchange levels. "Cost Plus
                  Surcharge", if it appears in the Application, is calculated by
                  adding: (i) the interchange fee; plus (ii) applicable
                  surcharge fees; plus (iii) up to 100 basis points for credit
                  card Transactions and 100 basis points for debit card
                  Transactions (as the case may be); plus (iv) access fees, dues
                  and Assessments as determined by Servicer.{" "}
                </p>
                <p>
                  Where applicable, Merchant shall also pay the following fees:
                  Chargebacks: $25.00 per Chargeback; Voice/ARU Authorization:
                  $0.95 per authorization request; ACH Modifications: $25.00 per
                  event; ACH Return (applicable to ACH returns not subject to
                  any ACH guarantee): $25 per return; Operator Assisted Voice
                  Auth.: $2.95 per call; Dispute Resolution Fee: $25; Third
                  Party Helpdesk Calls: $5.00 per call; Unsupported Terminals:
                  $20 per call; Security/Compliance Fee: $150.00 annually, if
                  applicable; PCI Non-Compliance Monthly Fee: $29.99; monthly
                  PCI Non-Compliance Assessment of .17% applied to all
                  Transactions; Handling Fee: $69 for accounts approved yet
                  inactivated, and a Pre-Arbitration Fee: $40 per event,
                  triggered by either of the following: 1) representment by
                  Merchant in the course of the Visa allocation process, or 2)
                  outside of the visa allocation process, denial of Merchant's
                  representment by the Issuer. A <em>Risk</em> Fee of up to 50
                  basis points on all Transactions processed may apply if the
                  Merchant account is designated as high risk in nature, to
                  offset the additional risk associated with this type of
                  account. Merchant will be notified if the <em>Risk Fee</em>{" "}
                  applies, in which case the fee will also appear on Merchant's
                  monthly statements. A monthly minimum of $25 or 100 basis
                  points on Merchant's approved monthly volume, whichever is
                  greater, shall apply.
                </p>
                <p>
                  Account will be debited through ACH or withheld from daily
                  payments to Merchant for such amounts and for any other fees,
                  charges or adjustments incurred or owed by Merchant hereunder.
                  Upon thirty (30) days written notice to Merchant, Servicer may
                  change fees, including without limitation by adding fees for
                  additional services utilized by Merchant and/or increasing
                  fees for any adverse change to Merchant's risk profile, as
                  reasonably determined by Servicer.
                </p>{" "}
                <p>
                  If Servicer receives any subpoena for the production of
                  documents, including any Civil Investigative Demand, or any
                  lien, levy, garnishment or other third-party claim pertaining
                  to any amounts due to Merchant pursuant to the terms of this
                  Agreement (collectively "Third Party Payment Demand"), in
                  addition to all other rights and remedies available to
                  Servicer herein, including but not limited to Servicer's right
                  to terminate this Agreement for cause, Merchant shall pay a
                  $250 fee for each subpoena as well as for each and every month
                  or partial month in which Servicer is obligated to reply to,
                  remit funds to, or otherwise administer such Third Party
                  Payment Demand.
                </p>
                <div>
                  ISO will provide Merchant with monthly electronic statements
                  reflecting the fees and charges to be paid by Merchant.
                  Merchant must review the statements and inform Servicer of any
                  errors within 60 days of the date of the statement. Servicer
                  will have no obligation to provide refunds for errors that the
                  Merchant fails to report within such 60-day period.
                </div>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.8.</Div>
              <Div col>Inentionally Omitted.</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.9.</Div>
              <Div col>
                Modifications to Agreement. This Agreement is subject to
                amendment to conform with Card Brand regulations, as amended
                from time to time. From time to time, Servicer may amend any
                provision or provisions of this Agreement, including, without
                limitation, those relating to the discount rate or to other fees
                and charges payable by Merchant by sending 90 days' written
                notice to Merchant of the amendment prior to the effective date
                of the amendment, and the amendment will become effective unless
                Servicer receives Merchant's notice of written termination of
                this Agreement before such effective date. Amendments required
                due to changes in any of the Rules, Applicable Law or a judicial
                decision, may become effective on such shorter period of time as
                Servicer may specify if necessary, to comply with the applicable
                rule, regulation, law or decision.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.10.</Div>
              <Div col>
                Warranty Disclaimer. SERVICER MAKES NO WARRANTIES REGARDING THE
                USE, OPERATION OR PERFORMANCE OR NON- PERFORMANCE OF SOFTWARE
                AND SYSTEMS UTILIZED FOR THIS AGREEMENT, WHETHER EXPRESS OR
                IMPLIED, AND SERVICER EXPRESSLY DISCLAIMS ALL IMPLIED
                WARRANTIES, INCLUDING ANY WARRANTY OF MERCHANTABILITY OR FITNESS
                FOR A PARTICULAR PURPOSE. SERVICER MAKES NO REPRESENTATIONS OR
                WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SERVICES IT
                PROVIDES HEREUNDER.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.11.</Div>
              <Div col>
                Limitation of Liability. SERVICER'S LIABILITY WITH RESPECT TO
                ANY CARD TRANSACTION MAY NOT EXCEED THE AMOUNT OF THE SALES
                DRAFT IN CONNECTION WITH THE TRANSACTION, LESS ANY APPLICABLE
                FEES AND CHARGES. IF THERE ARE ERRORS, OMISSIONS, INTERRUPTIONS
                OR DELAYS RESULTING FROM BANK'S OR ISO'S PERFORMANCE OR ANY
                FAILURE TO PERFORM, BANK'S AND ISO'S LIABILITY SHALL BE LIMITED
                TO CORRECTING SUCH ERRORS, IF COMMERCIALLY REASONABLE. NEITHER
                BANK NOR ISO SHALL BE LIABLE FOR ANY LOST PROFITS, REVENUES, OR
                BUSINESS OPPORTUNITIES, NOR ANY EXEMPLARY, PUNITIVE, INCIDENTAL,
                INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES (WHETHER DIRECT OR
                INDIRECT) TO MERCHANT OR TO ANY THIRD PARTY IN CONNECTION WITH
                OR ARISING OUT OF THIS AGREEMENT OR ANY OF THE SERVICES TO BE
                PERFORMED BY BANK OR ISO PURSUANT TO THIS AGREEMENT. MERCHANT
                WAIVES ALL CLAIMS AGAINST SERVICER FOR ANY LOSS, CLAIM, DEMAND,
                PENALTY, ACTION, DELAY, COST OR EXPENSE (INCLUDING REASONABLE
                ATTORNEYS' FEES) OF ANY KIND UNLESS MERCHANT PROVIDES WRITTEN
                NOTICE TO SERVICER OF THE OCCURRENCE THAT GAVE RISE TO THE
                ALLEGED LIABILITY WITHIN 30 DAYS AFTER MERCHANT KNEW OR SHOULD
                HAVE KNOWN OF THE OCCURRENCE. IN ADDITION TO WHAT IS PROVIDED
                HEREIN, (A) BANK'S CUMULATIVE LIABILITY TOWARDS MERCHANT FOR ANY
                REASON WHATSOEVER SHALL NOT EXCEED THE AMOUNT OF FEES RECEIVED
                BY SERVICER PURSUANT TO THIS AGREEMENT, FOR SERVICES PERFORMED
                IN THE IMMEDIATELY PRECEDING SIX (6) MONTHS; AND (B) NUVEI'S AND
                ITR'S CUMULATIVE LIABILITY TOWARDS MERCHANT FOR ANY REASON
                WHATSOEVER SHALL NOT EXCEED THE AMOUNT OF FEES RECEIVED BY
                SERVICER PURSUANT TO THIS AGREEMENT, FOR SERVICES PERFORMED IN
                THE IMMEDIATELY PRECEDING TWELVE (12) MONTHS. MERCHANT WILL
                INDEMNIFY AND HOLD SERVICER HARMLESS FROM ANY CLAIM RELATING TO
                ANY SALES DRAFT PAID FOR BY SERVICER AS MAY BE MADE BY ANYONE BY
                WAY OF DEFENSE, DISPUTE, OFFSET, COUNTERCLAIM OR AFFIRMATIVE
                ACTION, OR FOR ANY DAMAGES OF OR LOSSES THAT SERVICER MAY INCUR
                AS A RESULT OF MERCHANT'S BREACH OF THIS AGREEMENT. FURTHER,
                MERCHANT WILL REIMBURSE SERVICER FOR ALL EXPENSES AND COSTS,
                INCLUDING ATTORNEYS' FEES, WITH REGARD THERETO. NOTWITHSTANDING
                ANYTHING TO THE CONTRARY IN THIS AGREEMENT, THE BANK'S
                OBLIGATION UNDER THIS AGREEMENT SHALL BE LIMITED TO SPONSORSHIP
                AND SETTLEMENT. CARD BRAND ASSESSMENTS AND OTHER AMOUNTS FOR
                WHICH MERCHANT IS LIABLE UNDER THIS AGREEMENT (INCLUDING AMOUNTS
                IMPOSED BY A REGULATORY AUTHORITY THAT ARE SPECIFICALLY DUE TO
                MERCHANT'S FAILURE TO PERFORM ITS OBLIGATIONS UNDER THIS
                AGREEMENT) ARE NOT EXCLUDED BY THIS SECTION.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.12.</Div>
              <Div col>
                Waiver. Servicer's failure by Servicer to enforce one or more of
                the provisions of this Agreement will not constitute a waiver of
                the right to enforce the same or other provision in the future.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.13.</Div>
              <Div col>
                <p>
                  Written Notices. All written notices and other written
                  communications required or permitted under this Agreement will
                  be deemed delivered immediately when hand-delivered or sent
                  via facsimile or email with confirmation of delivery, and upon
                  mailing when sent first class mail, postage prepaid. Notices
                  shall be addressed as follows:
                </p>
                <p>
                  a) If to ISO: Nuvei Technologies Inc., 1375 N Scottsdale Rd
                  Suite 400, Scottsdale, AZ 85257, United States, Facsimile:
                  877-669-5703, Attn: Loyalty Department;
                </p>
                <p>
                  b) If to Bank: to the address indicated on the Merchant
                  Application.
                </p>
                <p>
                  c) If to ITR: 1167 Caledonia Rd., Toronto, Ontario M6A 2X1,
                  Attn: Mr. Bram Belzberg, CEO.
                </p>
                <div>
                  d) If to Merchant: To the facsimile number, email address or
                  address provided as the billing address, and to the contact
                  listed on the Merchant Application.
                </div>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.14.</Div>
              <Div col>
                A written notice shall also be deemed sent and received if
                notification is given to the Merchant: (i) through any
                technological Platform offered by Servicer and used by Merchant
                to access its account; or (ii) by way of any billing statement
                sent to Merchant, whether such billing statement is sent via a
                technological Platform, by commercial courier or by mail.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.15.</Div>
              <Div col>
                Choice of Law. This Agreement shall be governed exclusively by
                Delaware law, without regard to its choice of law provisions.
                Merchant hereby waives releases and agrees not to assert any
                rights it may have under any foreign law or regulation that
                would be inconsistent with the terms of this Agreement as
                governed by Delaware law. Merchant also waives its right to a
                trial by jury of any disputes arising from its relationship with
                Servicer.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.16.</Div>
              <Div col>
                Entire Agreement; Assignability. This Agreement expresses the
                entire understanding of the parties with respect to the subject
                matter hereof and except as provided herein, may be modified
                only in writing executed by Servicer and Merchant. This
                Agreement may not be assigned by Merchant, directly or by
                operation of law, without Servicer's prior written consent. Any
                purported assignment in violation of this clause is ineffective,
                void, and unenforceable. Each of Bank, Nuvei and ITR may assign
                their rights and delegate their duties under this Agreement,
                without notice to Merchant. This Agreement will be binding upon
                and inure to the benefit of the parties' respective heirs,
                personal representatives, successors and assigns.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.17.</Div>
              <Div col>
                Deposit Account. Merchant will at all times maintain an Account
                at a bank that is a member of the Federal Reserve ACH system and
                will provide Servicer with proper authorization to debit the
                Account. All credits for collected funds and debits for fees,
                payments and Chargebacks and other amounts for which Merchant is
                liable under the terms of this Agreement will be made to the
                Account. Merchant may not close or change the Account without
                written notice to Servicer. Merchant will be solely liable for
                all fees and costs associated with the Account and for all
                overdrafts.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.18.</Div>
              <Div col>
                Credit and Financial Inquiries; Additional Locations;
                Inspections. Servicer may make, at any time, any credit inquires
                which it may consider necessary to accept or review acceptance
                of this Agreement or investigate Merchant's deposit or Card
                acceptance activities subsequent to acceptance of this
                Agreement. Such inquiries may include, but are not limited to, a
                credit and/or criminal check of the business including its
                proprietor, partners, principal owners or shareholders or
                officers. Upon Servicer's request, Merchant will provide the
                written consent of any person for which an inquiry has been or
                is to be made if such person has not executed this Agreement and
                will provide any financial statements, income tax and business
                tax returns and other financial information as Servicer may
                consider necessary to perform initial or periodic reviews of
                Merchant's financial stability and business practices. Merchant
                may accept Cards only at locations approved by Servicer.
                Additional locations may be added, subject to Servicer's
                approval. Any party to this Agreement may delete any location by
                providing notice as provided herein. Merchant will permit
                Servicer, at any time and from time to time, to inspect
                locations to confirm that Merchant has or is adhering to the
                terms of this Agreement and is maintaining the proper
                facilities, equipment inventory, records and license or permit
                (where necessary) to conduct its business. However, nothing in
                this Section may be deemed to waive Merchant's obligation to
                comply in all respects with the terms of this Agreement.
                Servicer, its internal and external auditors, and its regulators
                may audit compliance with this Agreement, compliance with
                Applicable Law, rules, regulations and guidance applicable to
                the services, Card acceptance and Transaction processing, and
                data security provisions, including Card Brand compliance.
                Merchant will make available its records maintained and produced
                under this Agreement, and Merchant's facilities will be made
                accessible, upon notice during normal business hours for
                examination and audit. Nothing in this Section may be construed
                to require Merchant to give access to its facilities, personnel
                or records in a manner that unreasonably interferes with its
                business operations. Each party will bear its expenses of any
                audit, save that Merchant will bear the cost of any audit
                carried out by Bank or at the request of a Card Brand.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.19.</Div>
              <Div col>
                Confidential Information. Merchant acknowledges that certain
                Confidential Information may be communicated by Servicer to
                Merchant or its designees. As a condition to the receipt of the
                Confidential Information from Servicer, Merchant shall: (i) not
                disclose to any third party, directly or indirectly, any portion
                of the Confidential Information; (ii) not use Confidential
                Information in any fashion except to perform obligations
                hereunder or with Servicer's express prior written consent;
                (iii) disclose Confidential Information, in whole or in part,
                only to its employees and agents who need to have access thereto
                for internal business purposes related to this Agreement; (iv)
                take all necessary steps to ensure that its employees and agents
                are informed of and comply with the confidentiality restrictions
                contained in this Agreement; and (v) take all necessary
                precautions to protect the confidentiality of the Confidential
                Information received hereunder and exercise at least the same
                degree of care in safeguarding the Confidential Information as
                it would with its own confidential information, and in no event
                apply less than a reasonable standard of care to prevent
                disclosure. Merchant shall promptly notify Servicer of any
                unauthorized disclosure or use of the Confidential Information.
                Merchant shall cooperate and assist Servicer in preventing or
                remedying any such unauthorized use or disclosure.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.20.</Div>
              <Div col>
                Marketing of Payment Card Services. From time to time, Servicer
                may offer to Merchant certain additional products and services
                which may or may not be related to the processing of credit card
                Transactions. If such offers are made, Merchant may decline the
                offers or be deemed to have accepted the offers and be liable
                for payment therefor.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.21.</Div>
              <Div col>
                Force Majeure. The parties will be released from liability
                hereunder if they fail to perform any obligation where the
                failure occurs by reason of any act of God, fire, flood, storm,
                earthquake, tidal wave, communications failure, sabotage, war,
                military operation, terrorism, national emergency, pandemic,
                epidemic, mechanical or electronic breakdown, civil commotion or
                the order, requisition, request or recommendation of any
                governmental authority, or either party's compliance therewith,
                or governmental regulation or priority, or any other similar
                cause beyond either party's reasonable control.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.22.</Div>
              <Div col>
                No Third Party Beneficiary. No other person or entity may be
                deemed to be a third-party beneficiary of this Agreement, except
                for KEV Group Inc. and its affiliates that may provide services
                to Merchants from time-to-time.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.23.</Div>
              <Div col>
                Inconsistency. In the event of any inconsistency between the
                terms of this Agreement and the Merchant Application, the
                provisions of the Merchant Application shall prevail.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>6.24.</Div>
              <Div col>
                Application of Certain Terms. For the sake of clarity and
                notwithstanding any provision of this Agreement to the contrary,
                in the event and to the extent any activity occurs under or in
                connection with this Agreement or the provision of any Service
                that involves or relates to any Card Brand of which Bank is not
                a member or APM that does not directly involve Bank: (i) the
                term "Servicer", as it relates to any such activity or Service,
                refers solely and exclusively to Nuvei; (ii) Merchant will look
                only to Nuvei for the performance of such Services and
                Servicer-related obligations; and (iii) Merchant hereby releases
                Bank from any obligation, and irrevocably waives any right,
                claim or cause of action Merchant may have against Bank, in
                connection with or arising under any provision of this Agreement
                that relates to such activity or Service.
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART TWO</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.</Div>
              <Div col>
                AMERICAN EXPRESS. Additional terms and conditions applicable
                only to Merchants with American Express Card acceptance.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.1.</Div>
              <Div col>
                The following terms and conditions apply to Merchant's
                participation in the American Express OptBlueSM Program (the
                "Program"). Merchant's participation in American Express Card
                Acceptance is subject to the approval of American Express. All
                capitalized terms under this Part Two that are not defined in
                this Agreement shall be given the definition assigned by
                American Express. For the purposes of this Part Two, any
                conflict between defined terms and those terms defined by
                American Express, the definition by American Express shall
                control.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.2.</Div>
              <Div col>
                American Express Merchant Operating Guide. Merchant shall be
                bound by the American Express Merchant Operating Guide:
                www.americanexpress.com/merchantopguide, which is incorporated
                by reference into this Agreement and as may be amended from time
                to time.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.3.</Div>
              <Div col>
                General Terms. Merchant authorizes ISO and/or its affiliates to
                submit American Express Transactions to, and receive settlement
                on such Transactions from, American Express or Servicer on
                behalf of Merchant. Merchant agrees that (i) ISO and/or its
                affiliates may collect and disclose Transaction Data (as defined
                in the American Express Merchant Operating Guide), Merchant
                Data, and other information about Merchant to American Express
                (where "Merchant Data" means the mandatory, conditional, and
                optional requirements including, but not limited to names,
                postal and email addresses, tax ID numbers, names and social
                security numbers of the authorized signer, and similar
                identifying information about Merchants, as set forth in the
                American Express Technical Specifications); and (ii) American
                Express may use such information to perform its responsibilities
                in connection with the Program, promote the American Express
                Network, perform analytics and create reports, and for any other
                lawful business purposes, including commercial marketing
                communications purposes within the parameters of the Program,
                and important transactional or relationship communications from
                American Express. Merchant agrees that American Express may use
                the information obtained in Merchant's application at the time
                of setup to screen and/or monitor Merchant in connection with
                Card marketing and administrative purposes.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.4.</Div>
              <Div col>
                Marketing Message Opt-Out. Merchant may opt-out of receiving
                future commercial marketing communications from American Express
                by contacting ISO. Note that Merchant may continue to receive
                marketing communications while American Express updates its
                records to reflect this choice. Opting out of commercial
                marketing communications will not preclude Merchant from
                receiving important transactional or relationship messages from
                American Express.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.5.</Div>
              <Div col>
                Conversion to American Express Direct Merchant. Merchant
                acknowledges that it may be converted from American Express Card
                OptBlue program to a direct relationship with American Express
                if and when it becomes a High CV Merchant, where a "High CV
                Merchant" is a Merchant whose payment or purchase volume, less
                refunds and Chargebacks, is (i) greater than USD $1,000,000 in a
                rolling twelve (12) month period or (ii) greater than USD
                $100,000 in any three (3) consecutive months (which total shall
                include the volume from all Merchant's establishments under the
                same tax identification number (TIN). If this occurs, upon such
                conversion, (i) Merchant will be bound by American Express'
                then-current Card Acceptance Agreement; and (ii) American
                Express will set pricing and other fees payable by Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.6.</Div>
              <Div col>
                American Express as Third-Party Beneficiary. Notwithstanding
                anything in the Agreement to the contrary, American Express
                shall have third-party beneficiary rights, but not obligations,
                to the terms of this Agreement applicable to American Express
                Card acceptance to enforce such terms against Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.7.</Div>
              <Div col>
                American Express Opt-Out. Merchant may opt out of accepting
                American Express Cards at any time without directly or
                indirectly affecting its rights to accept Cards bearing the
                Marks of other Card Brands.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.8.</Div>
              <Div col>
                Refund Policies. Merchant's refund policies for purchases on the
                American Express Card must be at least as favorable as its
                refund policy for purchase on any other Cards, and the refund
                policy must be disclosed to Cardholders at the time of purchase
                and in compliance with Applicable Law. Merchant may not bill or
                attempt to collect from any Cardholder for any purchases or
                payments on the Card unless a Chargeback has been exercised,
                Merchant has fully paid for such Chargeback, and it otherwise
                has the right to do so.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.9.</Div>
              <Div col>
                Establishment Closing. Upon termination of this Agreement or
                Merchant's participation in the Program, Merchant will cease all
                use of, and remove American Express Licensed Marks from the
                Merchant's website and wherever else they are displayed. ISO may
                immediately terminate Merchant's participation in the Program
                (i) if Merchant breaches any provision in this Agreement
                pertaining to American Express Card Acceptance, (ii) if Merchant
                breaches any provision in the American Express Merchant
                Operating Guide, (iii) for cause or fraudulent or other
                activity, or (iv) at the request of American Express.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>7.10.</Div>
              <Div col>
                Data Incidents. Merchant must report all instances of a Data
                Incident (as defined in the American Express Merchant Operating
                Guide) immediately to ISO after discovery of the incident.
                Merchant must ensure data quality and that Transaction Data and
                customer information is processed promptly, accurately, and
                completely, and complies with the American Express Technical
                Specifications.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}></Div>
              <Div col>
                <strong>
                  THE FOLLOWING SERVICES ARE PROVIDED BY ISO ONLY.
                </strong>{" "}
                Bank shall not have any obligation or liability of any nature in
                connection with such services.
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART THREE</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.</Div>
              <Div col>
                UNIONPAY. Additional terms and conditions applicable only to
                Merchants with UnionPay Card acceptance. In case of conflict or
                inconsistency between this Part and other Parts of this
                agreement, this Part shall prevail.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.1.</Div>
              <Div col>
                Merchant undertakes to prominently display the UnionPay
                acceptance logos at their premises where Card transactions take
                place.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.2.</Div>
              <Div col>
                Merchant agrees to accept all valid UnionPay-branded Cards.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.3.</Div>
              <Div col>
                Except as permitted by the local regulator or UnionPay and
                unless an additional surcharge has already been imposed on other
                Card Brands, the Merchant shall undertake not to impose an
                additional surcharge on a UnionPay Cardholder; and shall accept
                UnionPay Cards at the same price and terms as cash.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.4.</Div>
              <Div col>
                The Merchant shall not retain account numbers, PIN and other
                UnionPay Card transaction data and shall keep confidential all
                UnionPay Card information. Merchant shall not provide account
                information or transaction data to a third party other than ISO
                or an institution designated by ISO.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.5.</Div>
              <Div col>
                The Merchant undertakes to be responsible for the storage and
                maintenance of payment terminals.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>8.6.</Div>
              <Div col>
                If Merchant only accepts UnionPay Cards and does not accept any
                other Card Brand, then Bank shall not be a party to and shall
                not be bound by any provisions of this Agreement. ISO shall
                assume all rights and obligations of Bank herein and Bank shall
                have no liability to Merchant whatsoever. Merchant waives any
                and all rights it may have against Bank.
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART FOUR</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.</Div>
              <Div col>
                TERMINAL BAILMENT. If Merchant has accepted to receive a
                terminal from ISO that is neither leased nor purchased, the
                following additional terms and conditions of this Part shall
                apply.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.1.</Div>
              <Div col>
                Bailment. ISO hereby agrees to bail to Merchant, the terminal(s)
                described in the Merchant Application Form ("Terminal"), subject
                to the terms and conditions hereof. The bailment term shall
                commence on the date of delivery and shall end upon termination:
                (i) by ISO upon five (5) days prior notice; (ii) by Merchant
                upon thirty (30) days prior written notice; or (iii) immediately
                and without notice where the Agreement is terminated.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.2.</Div>
              <Div col>
                Activation. Merchant understands and agrees that bailment of the
                Terminal is conditional upon: (i) Merchant actively processing,
                pursuant to this Agreement within thirty (30) days of the date
                of delivery of the Terminal; and (ii) continued processing under
                this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.3.</Div>
              <Div col>
                Maintenance. Merchant shall at all times during the term of this
                Agreement maintain the Terminal in good operating order, repair,
                and condition; and protect same from damage or deterioration.
                Merchant shall not use Terminal for any purpose other than that
                for which it was intended under this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.4.</Div>
              <Div col>
                {" "}
                Return. In the event of a termination of this Agreement, this
                bailment shall be deemed automatically terminated and Merchant
                shall, at Merchant's sole expense, immediately return Terminal
                to ISO in its original condition, save for normal wear and tear,
                failing which Merchant agrees to immediately pay ISO an amount
                equal $600.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.5.</Div>
              <Div col>
                Delivery. Delivery shall be as of the time that Terminal is
                received by Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.6.</Div>
              <Div col>
                {" "}
                Representations and Warranties. AS REGARDS THE TERMINAL AND ANY
                DATA STORED THEREIN, ISO MAKES ABSOLUTELY NO REPRESENTATIONS AND
                WARRANTIES, EXPRESS OR IMPLIED, WRITTEN OR ORAL, AS TO
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, CONDITION,
                QUALITY, DURABILITY OR SUITABILITY OF THE EQUIPMENT, TITLE OR
                NON-INFRINGEMENT AND EXPRESSLY DISCLAIMS SAME.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.7.</Div>
              <Div col>
                Risk of Loss. Merchant assumes all risk of loss or damage to the
                Terminal as at the time of delivery. Merchant agrees to
                indemnify and hold ISO harmless from and against all claims,
                liability, damage, loss or expenses which may occur to or arise
                from the Terminal during or after such time.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.8.</Div>
              <Div col>
                Entire Agreement. This Part, together with any documents to be
                delivered pursuant hereto, constitutes the entire agreement
                between Merchant and ISO pertaining to the bailed terminals and
                supersedes all prior agreements, understandings, negotiations,
                and discussions, whether oral or written, of such parties.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.9.</Div>
              <Div col>
                Limitation of Liability: Amount. IN NO EVENT SHALL ISO BE LIABLE
                TO MERCHANT FOR ANY LIABILITY, WHETHER BASED UPON WARRANTY,
                CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, IN AN AMOUNT
                GREATER THAN THE WHOLESALE VALUE OF THE TERMINAL.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>9.10.</Div>
              <Div col>
                Limitation of Liability: Damages. IN NO EVENT SHALL ISO BE
                LIABLE TO THE MERCHANT FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL,
                EXEMPLARY, PUNITIVE, TREBLE OR OTHER INDIRECT DAMAGES, OR FOR
                LOSS OF PROFITS AND REVENUE, LOSS OF DATA OR LOSS OF USE
                DAMAGES, ARISING OUT OF THE MANUFACTURE, SALE, SUPPLYING OR
                FAILURE OR DELAY IN SUPPLYING OF THE TERMINAL, WHETHER BASED
                UPON WARRANTY, CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
                EVEN IF AWARE OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
                UNDER NO CIRCUMSTANCES SHALL ISO'S AGGREGATE LIABILITY TO
                MERCHANT EXCEED THE TERMINAL VALUE INDICATED IN THE APPLICATION
                HERETO. MERCHANT ACKNOWLEDGES THAT BANK HAS PROVIDED NO
                WARRANTIES, EITHER EXPRESS OR IMPLIED, WRITTEN OR ORAL,
                INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH
                RESPECT TO ANY EQUIPMENT AND THAT BANK HAS NO LIABILITY WITH
                RESPECT TO ANY EQUIPMENT.
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART FIVE</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>10.</Div>
              <Div col> MERCHANT DASHBOARD - WEB REPORTS & ALERTS</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>10.1.</Div>
              <Div col>
                Merchant shall be automatically enrolled in Merchant Dashboard -
                WEB REPORTS & ALERTS, an advanced online reporting tool that is
                accessible at https://merchant.nuvei.com.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>10.2.</Div>
              <Div col>
                Free Trial Period. During the first 60 days following enrolment,
                Merchant will have free access to Merchant Dashboard - WEB
                REPORTS & ALERTS. Merchant shall have the right to use a free
                trial period one time, upon its initial enrolment into Merchant
                Dashboard - WEB REPORTS & ALERTS. After expiration of the
                initial trial period, Merchant will be charged a monthly
                membership fee of $9.75 per merchant identification number. The
                monthly membership fee will be automatically debited via ACH and
                is non-refundable.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>10.3.</Div>
              <Div col>
                Cancellation. To cancel access to Merchant Dashboard - WEB
                REPORTS & ALERTS, Merchant must opt out at least three business
                days before the first day of the calendar month as of which
                enrollment is cancelled. Merchant may opt out by contacting
                Client Care 1 (877) 772-3346 or by email at
                support@merchant-support.com. If the amount of the fee changes
                after enrolment for any reason, ISO will provide at least thirty
                (30) days advance written notice to Merchant regarding any such
                change.
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART SIX</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>11.</Div>
              <Div col>BUSINESS COACH - MERCHANT BUSINESS MANAGEMENT</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>11.1.</Div>
              <Div col>
                Merchant shall be automatically enrolled into Business Coach, a
                business management and analytics tool that is accessible at
                https://merchant.nuvei.com.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>11.2.</Div>
              <Div col>
                Free Trial Period. During the first 30 days following
                enrollment, Merchant will have free access to Business Coach.
                Merchant shall have the right to use a free trial period one
                time, upon its initial enrolment into Business Coach. After
                expiration of the initial trial period, Merchant will be charged
                a monthly fee of $29.99 per merchant identification number. The
                monthly fee will be automatically debited via ACH and is
                non-refundable.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>11.3.</Div>
              <Div col>
                Cancellation To cancel Business Coach, Merchant must opt out at
                least three business days before the first day of the calendar
                month as of which enrollment is cancelled by contacting Client
                Care 1 (877) 772-3346 or by email at{" "}
                <a href="mailto:support@merchant-support.com">
                  support@merchant-support.com
                </a>
                .
              </Div>
            </Div>
            <Div classNames={["t-row"]} flexJustify="center">
              <strong>PART SEVEN</strong>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.</Div>
              <Div col>
                ADDITIONAL SERVICES AGREEMENT - E-COMMERCE / GATEWAY
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.1.</Div>
              <Div col>
                Services. ISO will provide the transactional gateway services
                and related goods and services (the "Gateway Services") to
                Merchant as set forth in the Application. ISO and Merchant shall
                agree upon the equipment and services to initially be provided
                under this Agreement and may update and change the goods and
                services provided to Merchant by mutual consent.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.2.</Div>
              <Div col>
                <p>
                  Limited License. ISO hereby grants to Merchant a limited,
                  revocable, worldwide, non-exclusive, non sub-licensable and
                  non-transferable license under intellectual property rights
                  owned or licensed by ISO, to use Gateway Services, provided
                  however that:
                </p>
                <p>
                  a) such license is subject to all obligations and restrictions
                  imposed on Merchant in this Agreement;
                </p>
                <p>
                  b) such license extends only to Merchant's employees and
                  contractors, but only to the extent that such employees and
                  contractors use the Gateway Services for the sole purpose of
                  collecting payments on behalf of Merchant, and for no other
                  purpose whatsoever;
                </p>
                <p>
                  c) such license extends only to Merchant's use of Gateway
                  Services solely to perform the functions specified herein and
                  in compliance therewith, and
                </p>
                <div>
                  d) while exercising such license, Merchant shall treat the
                  Gateway Services as ISO's Confidential Information under this
                  Agreement.
                </div>
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.3.</Div>
              <Div col>
                Limitations on Rights Granted. Except as expressly provided to
                the contrary in this Agreement, Merchant shall not, and shall
                not knowingly cause or permit any third party to use or
                reproduce Gateway Services. Merchant shall not, and shall not
                knowingly cause or permit any third party to disassemble,
                decompile, decrypt, extract, reverse engineer, or prepare a
                derivative work based upon the Gateway Services, or otherwise
                apply any procedure or process to Gateway Services in order to
                ascertain, derive, or appropriate for any reason or purpose, the
                source code or source listings for the Gateway Services, or any
                algorithm, process, procedure or other information contained in
                the Gateway Services. Except as expressly authorized herein,
                Merchant may not rent, lease, distribute, assign, sublicense,
                transfer, modify, alter, or time share the Gateway Services.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.</Div>
              <Div col>Control Panel.</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.1.</Div>
              <Div col>
                To access the Nuvei online reporting system ("Nuvei Control
                Panel"), Merchant shall provide a valid, working administrative
                email address and identify an "Administrator" to access the
                Nuvei Control Panel. Subsequent changes in the identity of the
                Administrator will require submitting additional documentation
                signed by the Administrator or a C-level executive of the
                Merchant. Nuvei recommends that the Administrator be a C-level
                executive of the Merchant. By electing to allow the
                Administrator access to the Nuvei Control Panel, Merchant hereby
                grants the Administrator full access/action permissions to
                Merchant's account(s) on the Nuvei Control Panel. Unless
                expressly requested by Merchant or the Administrator, the
                Administrator is the only person who is authorized on behalf of
                Merchant to define, allocate and manage the access of the
                Merchant's additional authorized users to the Nuvei Control
                Panel. This includes, but is not limited to, role management,
                requesting password re-allocation, and granting a shared log-in
                ("Administrator Permissions"). Merchant specifically authorizes
                the Administrator to appoint authorized users with Administrator
                Permissions without the need for further Merchant approval.
                Merchant is fully aware of the scope and potential consequences
                of such permission. When contacting Nuvei, the Administrator and
                authorized users with Administrator Permissions will be taken
                through security procedures to validate their identity.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.2.</Div>
              <Div col>
                Account activation email shall be initiated by the Nuvei Control
                Panel directly by the Administrator and those users authorized
                by the Administrator. The Administrator and such authorized
                users shall be referred to jointly as "Authorized Users". Unless
                expressly waived by Nuvei, only company e-mail domain addresses
                are acceptable for Authorized Users (i.e. not Hotmail, Gmail,
                Yahoo, etc.). Generic email accounts (e.g.,
                admin@merchantcompany.com) must be role-based and may not be an
                internal distribution group. Any changes to the identity of the
                Administrator must be made via the administrative email address
                provided. Password resets are only available by calling the
                Nuvei Technical Support Team. A password reset may only be
                initiated by the Administrator. The Administrator is authorized
                to perform a password reset for any Authorized User.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.3.</Div>
              <Div col>
                Merchant shall not attempt, nor permit persons who are not
                identified herein, or by the Administrator, and confirmed by
                Nuvei as an Authorized User, to access the Nuvei Control Panel.
                Any action taken by an Administrator or an Authorized User while
                such person was logged into the Nuvei Control Panel shall be
                deemed to be authorized by the Merchant. Nuvei shall not be
                responsible for any loss occurring as a result of a failure to
                maintain access permissions or to keep those access permissions
                secure. Merchant shall be fully and solely responsible for the
                establishment and maintenance of procedures to ensure the
                control and confidentiality of access permissions and any
                account passwords. Merchant is solely responsible for
                terminating access permissions for an Administrator or
                Authorized User that is no longer authorized to access the Nuvei
                Control Panel because such person has left the company or
                otherwise. Merchant represents and warrants that it has in
                place, and shall continue to maintain and enforce, stringent
                security policies that address, amongst other things, the proper
                guidelines for authorized users to use the Nuvei Control Panel
                and how to retain and safeguard user IDs and passwords, such as
                periodical password changes, prohibition of usage of public
                computers/networks, and disablement of utilities/browsers that
                remember username/password.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.4.</Div>
              <Div col>
                The Nuvei Control Panel is accessed through the internet which
                is a public system over which Nuvei has no control. Merchant
                fully agrees to use the Nuvei Control Panel at Merchant's sole
                risk.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.5.</Div>
              <Div col>
                ANY PERSON WHO ENTERS THE CORRECT ACCOUNT IDENTIFICATION AND
                SECURITY VALIDATION AND VERIFICATION INFORMATION IN THE NUVEI
                CONTROL PANEL WILL BE ABLE TO ACCESS THE MERCHANT'S ACCOUNT. ANY
                TRANSACTION COMPLETED USING THOSE ACCESS PERMISSIONS WILL BE
                DEEMED AUTHORIZED BY MERCHANT. NUVEI SHALL HAVE NO LIABILITY FOR
                ANY LOSS OR DAMAGE THAT MAY OCCUR AS A RESULT OF ANY
                UNAUTHORIZED ACCESS TO THE NUVEI CONTROL PANEL USING AN
                ADMINISTRATOR'S OR AUTHORIZED USER'S CORRECT ACCOUNT
                IDENTIFICATION AND SECURITY VALIDATION AND VERIFICATION
                INFORMATION.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.4.6.</Div>
              <Div col>
                Nuvei reserves the right to change the access guidelines and
                requirements to access the Nuvei Control Panel at any time and
                without notice. Nuvei may, at any time, suspend or otherwise
                restrict certain or all functionalities and/or access
                permissions including, but not limited to, instances in which
                Nuvei suspects unauthorized access or compromise of the account
                security.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.5.</Div>
              <Div col>
                ISO Marks. Subject to the limitations in this Agreement and
                subject to ISO's prior written approval, ISO grants Merchant the
                non-exclusive right and license to use ISO's Marks during the
                term of this Agreement solely in conjunction with the use of the
                Gateway Services. ISO grants no rights in the ISO Marks or in
                any other trademark, trade name, service mark, product
                designation, business name or goodwill of ISO, except as
                licensed hereunder or by separate written agreement of the
                parties. Merchant agrees that it will not at any time during and
                after this Agreement assert or claim any interest in or do
                anything that may adversely affect the validity of any ISO Marks
                or any other trademark, trade name, service mark, product
                designation, business name or goodwill belonging to or licensed
                to ISO (including, without limitation registering or attempting
                to register any ISO Mark or any such other trademark, trade
                name, service mark or product designation). During the term of
                this Agreement, Merchant agrees not to use any trademark, trade
                name, service mark, product designation or business name likely
                to create confusion with a trademark, trade name, service mark,
                product designation or business name of ISO, except for the ISO
                Marks expressly licensed hereunder. Upon expiration or
                termination of this Agreement, Merchant will immediately cease
                all display, advertising and use of all of the ISO Marks and
                will not thereafter use, advertise or display any trademark,
                trade name, service mark, product designation or business name
                which is, or any part of which is, likely to create confusion
                with any ISO Mark or with any trademark, trade name or product
                designation of ISO.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.6.</Div>
              <Div col>
                Intellectual Property. "Intellectual Property" means all of the
                following owned by a party hereto: (i) trademarks, service marks
                (registered and unregistered), trade names, product designations
                and business names and goodwill associated therewith; (ii)
                patents, patentable inventions, computer programs, and software;
                (iii) databases; (iv) trade secrets and the right to limit the
                use or disclosure thereof; (v) copyrights in all works,
                including software programs; and (vi) domain names. The rights
                owned by a party in its Intellectual Property shall be defined,
                collectively as "Intellectual Property Rights." Other than the
                express licenses granted by this Agreement, ISO grants no right
                or license to Merchant by implication, estoppel or otherwise to
                the Gateway Services or any Intellectual Property Rights of ISO.
                Each party shall retain all ownership rights, title, and
                interest in and to its own products and services (including in
                the case of ISO, in the Gateway Services) and all intellectual
                property rights therein, subject only to the rights and licenses
                specifically granted herein. ISO alone (and not Merchant) shall
                have the right, but not the obligation, to pursue trademark,
                service mark, copyright and patent protection, in its sole
                discretion, for the Gateway Services and any Intellectual
                Property Rights incorporated therein. Merchant will cooperate
                with ISO in pursuing such protection, including without
                limitation executing and delivering to ISO such instruments as
                may be required to register or perfect ISO's interests in any
                Intellectual Property Rights and any assignments thereof.
                Merchant shall not remove or destroy the proprietary,
                confidentiality, trademark, service mark, copyright or patent
                markings or notices placed upon or contained in any materials or
                documentation received from ISO in connection with this
                Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.7.</Div>
              <Div col>
                Merchant's Responsibilities. Merchant understands and
                acknowledges that Gateway Services are merely a processing
                conduit. ISO has no control of or responsibility for accepting,
                processing or filling any orders for purchases by Merchant's
                customers, or for handling any related inquiries. Merchant shall
                be fully and solely responsible for verifying the accuracy and
                completeness of all card transactions submitted and processed
                via Gateway Services, including determining the appropriate
                action to be taken for each such transaction (approve, void,
                reject or decline). ISO shall not be liable for any improperly
                processed transaction or illegal or fraudulent access to
                Merchant's gateway account. Merchant agrees and acknowledges
                that it shall be fully and solely responsible for all activities
                conducted through Gateway Services. Merchant shall be fully and
                solely responsible for the content and promotion of its website.
                Merchant represents and warrants that it is the owner and/or
                that it has full right and authority to disseminate all
                information, data, graphics, text, video, music or other
                intellectual property which forms part of its Web site, which is
                provided by Merchant to persons purchasing goods or services
                from Merchant, or which is used by Merchant in its advertising
                and promotion.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.8.</Div>
              <Div col>
                Third-Party Providers. If ISO uses third-party payment
                processing services providers, other than or in addition to ISO,
                Merchant shall verify ISO's list of payment processing services
                providers in order to determine if ISO can properly transmit the
                necessary Card information to Merchant's third-party providers.
                Merchant acknowledges that ISO's list can be modified from time
                to time and must be verified by Merchant prior to being set up
                with ISO. Merchant shall obtain the proper authorization from
                Merchant's other provider and/or settlement bank to be able to
                use Merchant's Account for key entry transactions and
                transactions being processed over the internet or online.
                Merchant shall be responsible for credits, returns, disputes and
                all costs associated with data transmissions. Merchant shall
                obtain accurate Card and ACH transaction information and
                authorization from its participating customers, and will
                transmit said information to ISO via the internet or service
                agreed upon by Merchant and ISO.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.9.</Div>
              <Div col>
                Merchant Obligations. (1) Merchant shall provide a valid,
                working administrative email address on enrolment. Any changes
                to Merchant's account must be made via the administrative email
                address provided upon enrolment. The security of Merchant's
                account is dependent in part upon Merchant maintaining the
                security of such administrative email address. Merchant shall be
                fully and solely responsible for any unauthorized changes to
                Merchant's account via this email address. (2) Merchant will be
                given an ID code and password to allow Merchant to have access
                to Gateway Services. Merchant shall be fully and solely
                responsible for the establishment and maintenance of procedures
                to insure the control and confidentiality of identification
                codes and passwords and other access procedures ("Codes").
                FAILURE TO PROTECT THE CODES MAY ALLOW UNAUTHORIZED PARTIES TO
                ACCESS THE GATEWAY SERVICES.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.10.</Div>
              <Div col>
                Unauthorized Access Risk Mitigation. Merchant is required to put
                in place internal procedures to limit the risk of unauthorized
                access to the gateway services, including, but not limited to
                (a) changing the password at least once every 120 calendar days;
                (b) keeping every identification code under secure conditions;
                and (c) not keeping, in any form or in any place, any list of
                passwords. Merchant agrees to comply with any access or
                identification procedures and security protocols established
                from time to time by ISO, and if Merchant believes that any Code
                or security procedures has or may have become known by an
                unauthorized person (whether employed by Merchant or not),
                Merchant shall immediately notify ISO by telephone and confirm
                to ISO in writing such oral notification within 24 hours.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.11.</Div>
              <Div col>
                Authorizations. Merchant will obtain from each of its
                participating customers, the required payment information in
                proper form authorizing automatic debits to customers' bank
                accounts to transfer payment amounts to the Account. Merchant
                warrants that Merchant will properly warehouse all
                authorizations obtained from its participating customers and
                will provide such authorizations for inspection upon request by
                ISO or any regulatory body governing these types of
                transactions. Merchant hereby authorizes ISO to debit Merchant
                for fees and other charges as set forth in this Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.12.</Div>
              <Div col>
                Ownership. The ownership of the tokens created by ISO hereunder,
                if any, shall rest with ISO at all times.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.13.</Div>
              <Div col>
                Providing Gateway Services. During the term of this Agreement,
                ISO shall provide the Gateway Services and use its commercially
                reasonable efforts to provide the Gateway Services and maintain
                them in an uninterrupted and error-free fashion consistent with
                its practices in effect as of the date of this Agreement.
                However, the parties acknowledge that the Gateway Services are
                computer-network based and may be subject to outages and delays.
                In such an event, ISO shall use commercially reasonable efforts
                to diligently and promptly remedy any and all material
                interruptions. ISO will not be liable in any manner for any
                interruptions, outages, or other delays relating to the Gateway
                Services, including any attributed to ISO's vendors. ISO may
                arrange for any portion of the Gateway Services to be provided
                to Merchant by any of its parent, affiliate or subsidiary
                corporations, without any liability of such parent, affiliate or
                subsidiary to Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.14.</Div>
              <Div col>
                Indemnification. Merchant shall defend, indemnify, and hold
                harmless ISO and any of its officers, directors, agents and
                employees, from and against any and all claims, actions,
                proceedings and suits, and all related liabilities, damages,
                settlements, penalties, fines, costs or expenses (including
                reasonable attorneys' fees and other litigation expenses)
                incurred by ISO, arising out of or relating to ("Claim"): (a)
                any breach or alleged breach by Merchant of any representation,
                warranty, or obligation of Merchant set forth in this Merchant
                Agreement; (b) any damage or loss caused by negligence, fraud,
                dishonesty or willful misconduct by Merchant or any of its
                employees, agents or customers; (c) the reliability, accuracy,
                or legitimacy of payment data or purchase orders submitted by
                Merchant to ISO; (d) Card transactions submitted by Merchant to
                ISO and rejected by ISO or ISO; (e) claims by Merchant's
                Cardholders, including, without limitation, claims relating to
                the disclosure of Data; (f) any alleged or actual violation by
                Merchant of Applicable Law; or (g) any fines and/or penalties
                charged or to be charged to ISO by the Card Brands or any other
                entity. Upon written notice from ISO to Merchant, Merchant shall
                immediately undertake the defense of any such Claim, by
                representatives of Merchant's own choosing, subject to ISO's
                reasonable approval; and/or reimburse ISO for any expense or
                loss arising from or relating to a Claim.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.15.</Div>
              <Div col>
                Limitation of Liability. MERCHANT AGREES AND ACKNOWLEDGES THAT
                USE OF GATEWAY SERVICES ARE AT MERCHANT'S SOLE RISK. ANY GATEWAY
                SERVICES, GOODS OR SOFTWARE PROVIDED HEREUNDER ARE PROVIDED ON
                AN "AS IS", "AS AVAILABLE" BASIS, AND ISO GIVES NO OTHER EXPRESS
                OR IMPLIED WARRANTIES, REPRESENTATIONS, OR CONDITIONS OF ANY
                KIND, WHETHER STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
                TO: WARRANTIES THAT THE GATEWAY SERVICES WILL BE COMPLETE,
                ACCURATE, SECURE, TIMELY, AVAILABLE, ACCESSIBLE, UNINTERRUPTED
                OR ERROR-FREE; IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
                MERCHANTABILITY, QUALITY OR FITNESS FOR A PARTICULAR PURPOSE; OR
                THOSE ARISING BY OPERATION OF LAW, COURSE OF DEALING OR USAGE OF
                TRADE. MERCHANT UNDERSTANDS AND ACKNOWLEDGES THAT ISO SHALL NOT
                BE LIABLE FOR ANY IMPROPERLY PROCESSED OR AUTHORIZED
                TRANSACTION, OR ILLEGAL OR FRAUDULENT ACCESS TO MERCHANT'S
                GATEWAY ACCOUNT OR DATA. THIS DISCLAIMER OF WARRANTIES
                CONSTITUTES AN ESSENTIAL PART OF THIS MERCHANT AGREEMENT. THIS
                SECTION SHALL SURVIVE THE TERMINATION OF THIS MERCHANT
                AGREEMENT.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.16.</Div>
              <Div col>
                EXCEPT AS OTHERWISE PROVIDED IN THIS MERCHANT AGREEMENT,
                MERCHANT EXPRESSLY AGREES THAT ISO SHALL NOT BE LIABLE FOR ANY
                LOSS (HOWEVER ARISING, INCLUDING NEGLIGENCE), ARISING FROM OR
                RELATED TO: (A) MERCHANT'S FAILURE TO PROPERLY ACTIVATE,
                INTEGRATE OR SECURE ITS GATEWAY ACCOUNT; (B) IMPROPER, ILLEGAL,
                UNAUTHORIZED OR FRAUDULENT TRANSACTIONS PROCESSED THROUGH
                MERCHANT'S GATEWAY ACCOUNT; (C) DISRUPTION OF MERCHANT SERVICES,
                SYSTEMS, SERVER OR WEB SITE BY ANY MEANS, INCLUDING WITHOUT
                LIMITATION, DDOS ATTACKS, SOFTWARE VIRUSES, TROJAN HORSES,
                WORMS, TIME BOMBS, OR ANY OTHER TECHNOLOGY; (D) ACTIONS OR
                INACTIONS BY ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION, A
                MSP OR BANK; OR (E) UNAUTHORIZED ACCESS TO (i) DATA, CARDHOLDER
                INFORMATION (INCLUDING CREDIT CARD NUMBERS AND OTHER DATA),
                TRANSACTION DATA OR PERSONAL INFORMATION BELONGING TO ISO ,
                MERCHANT OR ANY THIRD PARTY OR (ii) THE GATEWAY SERVICES, OR ANY
                SYSTEM OR PROGRAM ASSOCIATED THEREWITH; OR (F) THE LIMITATION OF
                THE FUNCTIONING OF ANY GATEWAY SERVICES OR SOFTWARE, HARDWARE,
                OR EQUIPMENT ASSOCIATED THEREWITH. ISO MAKES NO REPRESENTATION,
                WARRANTY OR GUARANTEE WHATSOEVER IN RELATION TO THIRD PARTY
                PRODUCTS OR SERVICES (INCLUDING THOSE OF A MSP). MERCHANT'S USE
                OF ANY SUCH THIRD-PARTY PRODUCTS OR SERVICES IS AT ITS OWN RISK.
                ISO ASSUMES NO RESPONSIBILITY AND EXPRESSLY DISCLAIMS ANY
                LIABILITY FOR CLAIMS OF LOSS AND/OR FRAUD INCURRED RESULTING
                FROM THE USE OF OR CONCLUSIONS DRAWN FROM ANY THIRD PARTY
                PRODUCT OR SERVICE, REGARDLESS OF WHETHER OR NOT ISO IS A
                RESELLER OF OR REFERRAL AGENT FOR SUCH PRODUCT OR SERVICE.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.17.</Div>
              <Div col>
                Data Security Compliance. Merchant shall ensure that any of its
                agents or subcontractors to whom it provides Cardholder
                Information received from, created or received by Merchant on
                behalf of ISO, agrees to the same restrictions and conditions
                that apply through this Agreement to Merchant with respect to
                such information. Upon five (5) business days' notice or
                immediately upon any unauthorized access to, use or disclosure
                of any Cardholder Information, ISO may at its discretion,
                conduct an on-site audit and review of Merchant's procedures and
                systems.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.18.</Div>
              <Div col>
                Data Accuracy. Merchant shall provide ISO with data necessary
                for the electronic funds transfer ('collection data") in the
                form and at the times prescribed by ISO and shall make periodic
                checks and updates necessary to cause the collection data to be
                current and accurate at all times. The format and schedule
                requirements for delivery of collection data by Merchant may be
                changed by ISO during the term of this Agreement, and Merchant
                shall deliver collection data in conformity with changed
                requirements set forth from time to time by ISO. Merchant
                warrants to ISO that all data and entries delivered to ISO by
                Merchant will (a) be correct in form, (b) contain true and
                accurate information, (c) be fully authorized by its customer,
                and (d) be timely under the terms and provisions of this
                Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.19.</Div>
              <Div col>
                Use of Data. ISO shall have the right to use the data received
                from Merchant to help create, develop, operate, deliver, and
                improve its products, services, content and advertising, and for
                loss prevention and anti-fraud purposes.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.20.</Div>
              <Div col>
                Records. Merchant shall be solely responsible for compiling and
                retaining records of all transactional information for
                Merchant's records. Except as otherwise provided herein, ISO
                shall not have the obligation to store, retain, report or
                otherwise provide any copies of or access to any Data collected
                or processed by ISO or any of its suppliers. Merchant shall be
                solely responsible for all equipment, hardware and software
                required to access or use Gateway Services.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.21.</Div>
              <Div col>
                Fees. Merchant shall pay to ISO the fees listed in the
                Application, including all applicable taxes. ISO shall issue
                monthly invoices to Merchant in arrears. Merchant agrees to pay
                ISO for the Gateway Services via deduction of all fees and
                charges due directly from the Account via ACH. Merchant must
                review the invoices and inform ISO of any errors within 60 days
                of the date of the invoice. ISO will have no obligation to
                provide refunds for errors that the Merchant fails to report
                within such 60-day period.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.22.</Div>
              <Div col>
                Inconsistency. In the event of any inconsistency between the
                terms of this Part and any other terms and conditions of this
                Merchant Agreement, the provisions of this Part shall prevail.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-2"]}>12.23.</Div>
              <Div col>
                Termination. The license to Gateway Services shall immediately
                terminate upon the earlier of: (i) termination of expiration of
                this Merchant Agreement; (ii) termination of the Gateway
                Services; or (iii) failure of Merchant to comply with any
                provisions of this Part. ISO may terminate Gateway Services for
                any reason with or without notice.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.</Div>
              <Div col>FOREIGN CURRENCY PROCESSING</Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.1.</Div>
              <Div col>
                Multi-Currency Processing Services (MCP). Multi-currency
                processing ("MCP") enables a Merchant to price its goods and
                services in various selected currencies (each an "Acceptance
                Currency") and the Cardholder then decides to purchase the goods
                and services in the currency of his/her choice (e.g. Euro) ("MCP
                Transaction"). The Cardholder provides the card details at the
                point of sale and completes the Transaction in the chosen
                Acceptance Currency (e.g. Euro). The Transaction is authorized
                and submitted to the Card Brands for clearing and settlement in
                the chosen Acceptance Currency. However, the Merchant receives
                settlement of the MCP Transaction in US Dollars in the same way
                as it receives settlement of other Transactions under the
                Merchant Agreement.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.2.</Div>
              <Div col>
                Acceptance Currencies. It is the Merchant's responsibility to
                set its prices in the various Acceptance Currencies. ISO can
                make available to the Merchant for download a daily currency
                exchange rate table. However, the Merchant can choose to set
                prices for its goods and services as it wishes, either by
                reference to such exchange rate table, or any other exchange
                rate source, or as localized pricing in a fixed amount in the
                Acceptance Currency (e.g. &pound;29.99). MCP Transactions will
                be cleared through the applicable Card Brands in the selected
                Acceptance Currency. The MCP shall apply only to those Cards
                issued under the brands of Visa, Mastercard or such other Card
                Brands included within the MCP that are billed to the Cardholder
                in an Acceptance Currency.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.3.</Div>
              <Div col>
                Conversion of MCP transaction. The conversion of the MCP
                Transaction from the Acceptance Currency to US Dollars will be
                effected based upon a daily exchange rate table produced for the
                conversion of transactions from the involved Acceptance Currency
                to US Dollars, which is obtainable from ISO. Upon authorization
                of a MCP Transaction, the authorization message returned to the
                Merchant will contain the US Dollar amount into which that MCP
                Transaction will be converted to assist Merchants in reconciling
                their accounting. Merchant acknowledges that because of the
                fluctuation in currency exchange rates, localized pricing of
                goods and services in foreign Acceptance Currencies is likely to
                result in the Merchant receiving different amounts in US Dollars
                from day to day, which may be more or less than the typical US
                Dollar price of the Merchant's goods or services. This risk is
                reduced if Merchant uses the daily exchange rate table made
                available by ISO.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.4.</Div>
              <Div col>
                Termination or suspension. ISO reserves the right to add, delete
                or suspend any Acceptance Currency to or from the MCP, as the
                case may be, at any time without notice to Merchant. Further,
                ISO may terminate or suspend MCP services for any reason upon
                notice to the Merchant.
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.5.</Div>
              <Div col>
                <p>
                  MCP Service Requirements. Merchant will comply with all
                  reasonable instructions provided by ISO pertaining to
                  Merchant's participation in MCP. Without limiting the
                  foregoing, Merchant agrees to comply with the following
                  specific MCP requirements:
                </p>
                <p>
                  a) Disclosure of Foreign Currency Amount at Point of Sale: In
                  order to comply with Card Brand regulations, the Merchant must
                  display the amount of the MCP Transaction at the point of
                  sale, including an Internet website buy page, in the various
                  Acceptance Currencies that the Merchant wishes to offer, to
                  enable the Cardholder to select the Acceptance Currency for
                  the Transaction BEFORE providing his Card information for
                  payment. Merchant agrees that it will at its sole cost use a
                  point of sale device, or system which is certified or
                  supported by ISO for use with the MCP.
                </p>
                <p>
                  b) Disclosure of Foreign Currency Amount on Receipt: In
                  accordance with Card Brand rules, all MCP Transaction receipts
                  must show the amount of the Transaction in the correct
                  Acceptance Currency chosen by the Cardholder and such amount
                  must be preceded by the appropriate international currency
                  symbol, or currency code for that Acceptance Currency.
                </p>
                <p>
                  c) Timely Presentment of MCP Transactions: The Merchant must
                  present all MCP Transactions for settlement to ISO within one
                  day after authorization, in order to minimize the risk of loss
                  attributable to movement in the currency exchange rates for
                  the various Acceptance Currencies between the time that a
                  Foreign Transaction is completed and the submission of the
                  batch clearing and settlement files to ISO. For the avoidance
                  of doubt, neither ISO nor any of its vendors bears
                  responsibility for any foreign exchange loss attributable to
                  an MCP Transaction processed using MCP.
                </p>
                <p>
                  d) Credits/Returns: In the event that Merchant issues a
                  credit, reflecting either the partial or complete return or
                  reimbursement of a MCP Transaction, Merchant must process said
                  credit in the Acceptance Currency. In determining the US
                  Dollar amount of the credit to be deducted from the Merchant's
                  account, ISO shall use the MCP exchange rate applicable on the
                  date of presentment of the credit or return transaction by
                  Merchant. Due to the differences in the currency exchange
                  rates applied to the underlying MCP Transaction and the
                  related credit respectively, Merchant acknowledges that the
                  final amount of the US Dollars and could well be more.
                  Merchant acknowledges that it will be responsible for the full
                  amount of the credit, as set forth in the Merchant Agreement.
                </p>
                <p>
                  e) Chargebacks: A Chargeback incurred in connection with a
                  Foreign Transaction will be transmitted to ISO by the
                  applicable Card Brand in the Acceptance Currency and converted
                  by such Card Brand into US Dollars at the Card Brand's
                  designated foreign exchange rate. Due to the variances in
                  currency exchange rates applied to the underlying MCP
                  Transaction and the related Chargeback respectively, Merchant
                  acknowledges that the final amount of the Chargeback will
                  differ from the original settlement amount received by the
                  Merchant for the MCP Transaction in US Dollars and could well
                  be more. Merchant acknowledges that it will be responsible for
                  the full amount of the Chargeback, as set forth in the
                  Merchant Agreement.
                </p>
                <div>
                  f) Merchant Options: Merchant shall indicate which currencies
                  it wishes to use as Acceptance Currencies by checking the
                  applicable box in Schedule 1. Merchant can change options by
                  written request to ISO not less than 30 days' notice.
                </div>
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.6.</Div>
              <Div col>
                <p>Dynamic Currency Conversion (DCC):</p>
                <p>
                  a) The Dynamic Currency Conversion enables certain of
                  Merchant's customers, whose Cards are denominated in certain
                  currencies ("Program Currency") other than US Dollars
                  ("Foreign Cardholders"), to present a Card at Merchant's
                  point-of-sale and to pay for a purchase in the currency of the
                  Foreign Cardholder, based upon a rate of exchange determined
                  by ISO, while Merchant receives settlement of the foreign
                  transaction in US Dollars (collectively, a "Foreign
                  Transaction").
                </p>
                <p>
                  b) Merchant acknowledges and agrees that a Foreign Transaction
                  will be converted to the Program Currency in which the Card is
                  denominated based upon an exchange rate in effect at the time
                  of authorization for retail transactions and that the Foreign
                  Transaction, as converted, will be cleared through the "Card
                  Brands" in the currency in which the Card is denominated. The
                  DCC program shall apply only to those Cards issued by Visa,
                  Mastercard or under the brands of such other Card Brands
                  included within the DCC program that are billed to the Foreign
                  Cardholder in a Program Currency. Additionally, DCC may not
                  apply to credit-return transactions, or certain Foreign
                  Transactions that are referred to ISO for authorization or
                  otherwise authorized by Merchant via telephone. ISO reserves
                  the right to add, delete or suspend any currency to or from
                  the DCC program, as the case may be, at any time without
                  notice to Merchant. Further, ISO may terminate or suspend DCC
                  services for any reason upon notice to the Merchant.
                </p>
              </Div>
            </Div>

            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.7.</Div>
              <Div col>
                <p>
                  DCC Service Requirements. Merchant will comply with all
                  reasonable instructions provided by ISO pertaining to
                  Merchant's participation in the DCC program. Without limiting
                  the foregoing, Merchant agrees to comply with the following
                  specific DCC requirements:
                </p>
                <p>
                  a) Program Disclosure: Merchant agrees to comply with all
                  instructions and specifications applicable to DCC as provided
                  by ISO from time to time. Without limiting the generality of
                  the foregoing, Merchant shall follow DCC program procedures,
                  as may be amended by ISO from time to time.
                </p>
                <p>
                  b) Foreign Cardholder Opt-In: Merchant shall provide Foreign
                  Cardholders with the ability to "opt-in," or consent to
                  participate in DCC. In the event that a particular Foreign
                  Cardholder elects not to opt-in, it is understood that ISO
                  will process that Foreign Cardholder's transaction in US
                  Dollars. Merchant agrees to make such reasonable modifications
                  as ISO may request to increase the likelihood of Foreign
                  Cardholders opting-into the DCC program. It is understood that
                  any Foreign Transaction for which Merchant fails to provide a
                  Foreign Cardholder with the opt-in procedure as described
                  herein may be subject to a Chargeback as defined in the
                  Agreement.
                </p>
                <p>
                  c) Timely Presentment of Foreign Transactions: Merchant
                  acknowledges that the timely presentment of Foreign
                  Transactions is necessary for participation in the DCC
                  program. For the avoidance of doubt, Foreign Transactions must
                  be presented within twenty-four (24) hours of the completion
                  of the Foreign Transaction. Notwithstanding the foregoing,
                  Foreign Transactions submitted by Merchants involved in the
                  hotel, lodging and cruise industries must be submitted within
                  twenty-four (24) hours of a Foreign Cardholder's checkout from
                  the Merchant's establishment, failing which Merchant may be
                  subject to additional charges.
                </p>
                <p>
                  d) Credits/Returns: Unless Merchant uses point-of-sale
                  technology that is not capable of processing credit-returns in
                  the Program Currency in the manner required by ISO, a
                  credit-return to a Foreign Cardholder's account, reflecting
                  either the partial or complete return or reimbursement of a
                  Foreign Transaction, will be converted to the Program Currency
                  using the exchange rate applicable on the date of presentment
                  of the credit by Merchant, and that credit, as converted, will
                  be cleared through the applicable Card Brand in the Program
                  Currency in which the Card is denominated, and if such support
                  is not in place, then such credit or return transaction will
                  be processed in US Dollars, subject always to applicable Card
                  Brand rules.
                </p>
                <p>
                  e) Chargebacks: A Chargeback incurred in connection with a
                  Foreign Transaction will be transmitted to ISO by the
                  applicable Card Brand in the Program Currency and converted by
                  such Card Brand into US Dollars at the Card Brand's designated
                  foreign exchange rate. Given the potential variances in
                  exchange rates applied to the underlying Foreign Transaction
                  and Chargeback, Merchant acknowledges that the original amount
                  of the Chargeback will likely differ from the original
                  settlement amount received by the Merchant for the Foreign
                  Transaction in US Dollars. Notwithstanding anything herein to
                  the contrary, Merchant acknowledges that it will be
                  responsible for the full amount of the Chargeback under the
                  terms of this Merchant Agreement.
                </p>
                <div>
                  f) Interchange cost Merchant agrees that any increased
                  Interchange cost attributable to the increase in the price of
                  the goods or services in US Dollars prior to its conversion by
                  the applicable service fee shall be borne by Merchant.
                </div>
              </Div>
            </Div>
            <Div classNames={["t-row"]}>
              <Div classNames={["t-col-1"]}>13.8.</Div>
              <Div col>
                Indemnification. Merchant agrees to indemnify ISO, the Card
                Brands, and other card sponsors against any and all claims,
                demands, losses, damages, liability, actions, costs, judgments,
                arbitral awards and expenses (including reasonable attorneys'
                fees), to which ISO, , the Card Brands and other card sponsors
                may be subjected arising in whole or in part from the MCP and
                DCC services, any Card transaction involving Merchant, any act
                or omission of Merchant in connection with any such Card
                transaction, use of any third-party vendor, any breach of the
                Merchant Agreement by Merchant, or the Merchant's or any
                third-party vendor's violation of Applicable Law or Card Brand
                operating regulations, or any return of goods, price adjustment
                or other dispute with or claim by a Cardholder against Merchant
                (whether or not such Foreign Cardholder's claim is valid),
                including, but not limited to, claims or losses of any
                Cardholder or of any third party. Without limiting the
                generality of the foregoing or any other provision in this
                Merchant Agreement, Merchant agrees to be solely responsible for
                its actions in honoring or refusing to honor Cards and in
                retaining Cards in accordance with Card Brand merchant operating
                procedures; and Merchant will indemnify, defend and hold the
                Card Brands, ISO, and other members of the Card Brands harmless
                from any claim, loss or liability arising from any injury to
                persons, property or reputation which occurs as a result
                thereof.
              </Div>
            </Div>

            <Div classNames={["t-row"]} marginTop={5}>
              <strong>SCHEDULE- DATA PROTECTION</strong>
            </Div>
            <Div>
              <strong>1. Definitions</strong>
            </Div>
            <Div row>
              <Div classNames={["t-col-1"]}>1.1.</Div>
              <Div col>
                In this Data Protection Schedule the following words shall have
                the following meanings:
              </Div>
            </Div>
            <div>
              (a) <strong>Controller</strong>, <strong>process</strong>, and{" "}
              <strong>processor</strong> have the meanings given to them in the
              applicable Data Protection Law;
            </div>
            <div>
              (b) <strong>Data Subject</strong> means an individual who is the
              subject of personal data;
            </div>
            <div>
              (c) <strong>Data Protection Law</strong> means: (i) the General
              Data Protection Regulation ((EU) 2016/679) (GDPR); and (ii) any
              other laws, regulations and secondary legislation enacted from
              time to time in United States of America relating to data
              protection, the use of information relating to individuals, the
              information rights of individuals and/or the processing of
              personal data; and
            </div>
            <div>
              (d) <strong>Personal Data</strong> means information relating to
              an identified or identifiable natural person. An identifiable
              natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, an identification number, location data, an online
              identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that person.
            </div>

            <Div row>
              <Div classNames={["t-col-1"]}>1.2.</Div>
              <Div col>
                Terms and expressions not defined in this Schedule shall have
                the meaning set out in the Merchant Agreement.
              </Div>
            </Div>

            <div>
              <strong>2. Compliance with data protection law</strong>
            </div>
            <div>
              2.1. Merchant and Nuvei shall comply with the applicable Data
              Protection Law as it applies to personal data processed under this
              Agreement. This clause is in addition to, and does not relieve,
              remove, or replace, Merchant or Nuvei's obligations under the
              applicable regulation.
            </div>
            <Div>
              <strong>3. Data processing</strong>
            </Div>
            <div>
              3.1. Merchant and Nuvei agree and acknowledge that for the purpose
              of Data Protection Laws Merchant will be acting as a data
              controller and Nuvei will be acting as a data processor in respect
              of the Personal Data that is the subject of this agreement.
            </div>
            <div>
              3.2. The Merchant is solely and wholly responsible for
              establishing and maintaining the lawful basis for the processing
              of personal data by Nuvei under this Agreement in order to fulfil
              its obligations and with respect to including (where applicable)
              the obtaining of all necessary consents from data subjects.
            </div>
            <div>
              3.3. A description of the data processing carried out by Nuvei
              under this Agreement is set out in Part 1 of the Appendix to this
              Data Protection Schedule.
            </div>
            <div>
              3.4. The Merchant acknowledges that aggregated, anonymized data
              may be created based on Personal Data. Data subjects are not
              identifiable from this data. This Data may be used and or shared
              with third parties for the purposes of billing, product enablement
              and build, testing or product improvement and for the purposes of
              replying to requests from public authorities.
            </div>
            <div>
              3.5. The Merchant and Nuvei agree to ensure that all staff are
              appropriately trained in line with their responsibilities under
              applicable data protection law.
            </div>
            <div>
              3.6. Data protection enquiries should be addressed to Nuvei's Data
              Protection Officer at privacy@nuvei.com
            </div>
            <div>
              3.7. In respect of the personal data processed by Nuvei as a data
              processor acting on behalf of the Merchant under this Agreement,
              Nuvei shall:
            </div>
            <div>
              (a) process the personal data only on the Merchant's written
              instructions, for compliance with legal obligations to which Nuvei
              is subject (in which case it shall, if permitted by such law,
              promptly notify the Merchant of that requirement before
              processing), and where processing is necessary for the purposes of
              the legitimate interests pursued by Nuvei including the prevention
              of fraud and the maintenance of information security (except where
              such interests are overridden by the interests or fundamental
              rights and freedoms of the data subject which require protection
              of personal data, or where the data subject is a child);
            </div>
            <div>
              (b) ensure that it has in place appropriate technical and
              organizational measures to protect against unauthorized, unlawful
              or accidental processing, including accidental or unlawful
              destruction, loss, alteration, unauthorized disclosure of, or
              access to personal data, such measures in each case to be
              appropriate to the likelihood and severity of harm to data
              subjects that might result from the unauthorized, unlawful or
              accidental processing, having regard to the state of technological
              development and the cost of implementing any measures.
            </div>
            <div>
              (c) ensure that persons engaged in the processing of personal data
              are bound by appropriate confidentiality obligations;
            </div>
            <div>
              (d) keep a record of the processing it carries out, and ensure the
              same is accurate;
            </div>
            <div>
              (e) comply with any lawful request from the Merchant requesting
              access to, copies of, or the amendment, transfer or deletion of
              the Personal Data to the extent the same is necessary to allow the
              Merchant to fulfil its own obligations under the Data Protection
              Law, including the Merchant's obligations arising in respect of a
              request from a data subject;
            </div>
            <div>
              (f)&nbsp;notify the Merchant, except if otherwise prohibit by
              other applicable laws or regulation, if it receives any complaint,
              notice or communication (whether from a data subject, competent
              supervisory authority or otherwise) relating to the processing,
              the personal data or to either party's compliance with the Data
              Protection Law as it or they relate to this Agreement, and provide
              the Merchant with reasonable co-operation, information and other
              assistance in relation to any such complaint, notice or
              communication;
            </div>
            <div>
              (g) notify the Merchant if, in its opinion, an instruction from
              the Merchant infringes any Data Protection Law (provided always
              that the Merchant acknowledges that it remains solely responsible
              for obtaining independent legal advice regarding the legality of
              its instructions) or Nuvei is subject to legal requirements that
              would make it unlawful or otherwise impossible for Nuvei to act
              according to the Merchant's instructions or to comply with Data
              Protection Law;
            </div>
            <div>
              (h) ensure in each case that prior to the processing of any
              personal data by any sub-processor, terms equivalent to the terms
              set out in this Data Protection Schedule are included in a written
              contract between Nuvei and any sub-processor engaged in the
              processing of the personal data;
            </div>
            <div>
              (i) subject always to the requirement of sub-clause 3.7(h)
              regarding a written contract, the Merchant hereby gives its prior
              written authorization to the appointment by Nuvei of each of the
              sub-processors or categories of sub-processors (as the case may
              be) who will process personal data listed in Part 2 of the
              Appendix to this Data Protection Schedule, and to the extent this
              authorization is in respect of a category of sub-processors, Nuvei
              shall inform the Merchant of any intended changes concerning the
              addition or replacement of other categories of sub-processors;
            </div>
            <div>
              (j) For personal data collected in regards to European Union, only
              transfer the personal data outside of the European Union
              (including to the UK if it ceases to be a member of the European
              Union) if it has fulfilled one of the following conditions:
            </div>
            <div>
              i. the Personal Data is transferred to a country approved by the
              European Commission as providing an adequate level of protection
              for Personal Data,
            </div>
            <div>
              ii. the transfer is made pursuant to European Commission-approved
              standard contractual clauses for the transfer of Personal Data
            </div>
            <div>
              iii. or other appropriate legal data transfer mechanisms are used.
            </div>
            <div>
              The transfer of personal data may take place worldwide subject to
              the aforementioned arrangements. If the legal means by which
              adequate protection for the transfer is achieved ceases to be
              valid, Nuvei will work with the Merchant to put in place an
              alternative solution. The Merchant acknowledges that Nuvei may
              disclose the data to any applicable Acquirer, APMP, Card Scheme
              and their respective sub-processors, (including sub-processors
              located outside the EEA and such other entities to which it may be
              reasonably necessary to disclose and transfer personal data
              including the competent regulatory authority, law enforcement
              authorities and anti-terrorism or organized crime agencies to whom
              it is necessary to disclose data.
            </div>
            <div>
              (k) inform the Merchant (and in any event within five business day
              of becoming aware of such an event) if any personal data processed
              under this Agreement is lost or destroyed or becomes damaged,
              corrupted, or unusable or is otherwise subject to unauthorized or
              unlawful processing including unauthorized or unlawful access or
              disclosure;
            </div>
            <div>
              (l) inform the Merchant (and in any event within five (5) business
              days) if it receives a request from a data subject for access to
              that person's personal data and shall:
            </div>
            <div>
              i. promptly provide the Merchant with reasonable co-operation and
              assistance in relation to such request; and
            </div>
            <div>
              ii. not disclose the personal data to any data subject (or to any
              third party) other than at the request of the Merchant or as
              otherwise required under this Agreement;
            </div>
            <div>
              (m) provide reasonable assistance to the Merchant in responding to
              requests from data subjects and in assisting the Merchant to
              comply with its obligations under Data Protection Law with respect
              to security, breach notifications, data protection impact
              assessments and consultations with supervisory authorities or
              regulators;
            </div>
            <div>
              (o) subject to the requirements of commercial and client
              confidentiality, make available to the Merchant such information
              as is reasonably required to demonstrate compliance with this Data
              Protection Schedule and, subject to any other conditions set out
              in this Agreement regarding audit, allow for and contribute to
              audits, including inspections, of compliance with this Data
              Protection Schedule conducted by the Merchant or a professional
              independent auditor engaged by the Merchant. The following
              requirements apply to any audit:
            </div>
            <div>
              i. the Merchant must give a minimum sixty (60) days' notice of its
              intention to audit;
            </div>
            <div>
              ii. the Merchant may exercise the right to audit no more than once
              in any calendar year;
            </div>
            <div>
              iii. commencement of the audit shall be subject to agreement with
              Nuvei of a scope of work for the audit at least ten (10) days in
              advance;
            </div>
            <div>
              iv. Nuvei may restrict access to certain parts of its facilities
              and certain records where such restriction is necessary for
              commercial and/or client confidentiality;
            </div>
            <div>
              v. the audit shall not include penetration testing, vulnerability
              scanning, or other security tests;
            </div>
            <div>
              vi. the right to audit does not include the right to inspect, copy
              or otherwise remove any records, other than those that relate
              specifically and exclusively to the Merchant;
            </div>
            <div>
              vii. any independent auditor will be required to sign such
              non-disclosure agreement as is reasonably required by Nuvei prior
              to the audit; and
            </div>
            <div>
              viii. the Merchant shall compensate Nuvei for its reasonable costs
              (including for the time of its personnel, other than the client
              relationship manager) incurred in supporting any audit.
            </div>

            <Div classNames={["t-row"]} marginTop={5}>
              <strong>Appendix to the Data Protection Schedule</strong>
            </Div>
            <Div classNames={["t-row"]} marginTop={4}>
              <strong>Part 1 - Description of the processing</strong>
            </Div>
            <table border={1} cellPadding={10}>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Subject matter of the processing
                  </td>
                  <td style={{ border: "1px solid black" }} width="75%">
                    The processing of personal data to the extent necessary for
                    the provision of the services set out in this Agreement
                    between Nuvei and Merchant.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Duration of the processing
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    The duration of the processing of personal data by Nuvei
                    under this Agreement is the period of this Agreement and the
                    longer of such additional period as: (i) is specified in any
                    provisions of this Agreement regarding data retention; and
                    (ii) is required for compliance with law.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Nature of the processing
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Such processing as is necessary to enable Nuvei to comply
                    with its obligations, pursue its legitimate interests,
                    exercise its rights under this Agreement, and to comply with
                    its statutory obligations, including collection, recording,
                    organization, structuring, storage, adaptation or
                    alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available,
                    alignment or combination, restriction, erasure, or
                    destruction.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Purpose of the processing
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    The performance of Nuvei's obligations, exercise of its
                    rights under this Agreement, the pursuit of its legitimate
                    interests, its compliance with statutory obligations,
                    including the performance of functions required or requested
                    by the Merchant.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Personal data types
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Personal data provided to Nuvei by or on behalf of the
                    Merchant, including personal data provided directly to Nuvei
                    by a data subject or third party: (i) on the instruction or
                    request of the Merchant; or (ii) on the request of Nuvei
                    where Nuvei has been authorized to make such request by the
                    Merchant or is legally required to make such request. The
                    personal data processed under this Agreement will include
                    (depending on the scope of Services provided): name;
                    address; date of birth; gender; nationality; location; email
                    address; billing address; address; Country; Country code;
                    zip code; post code; user&nbsp;ID; telephone number; IP
                    address, primary account number and associated card
                    information (or similar number or code identifying an
                    alternative payment method).
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Categories of data subjects
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Personal data related to individuals associated with the
                    Merchant (including its past, current, and future
                    shareholders and directors). Personal data related to
                    individuals purchasing goods and/or services from the
                    Merchant.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Obligations and rights of the controller
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    As set out in the Agreement.
                  </td>
                </tr>
              </tbody>
            </table>
            <Div classNames={["t-row"]} marginTop={4}>
              <strong>
                Part 2 - Authorized sub-processors and categories of
                sub-processor
              </strong>
            </Div>
            <table border={1} cellPadding={10}>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    <strong>
                      Authorized sub-processor / category of sub-processor
                    </strong>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <strong>
                      Description of the processing carried out by the
                      sub-processor / category of sub-processor
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Other members of the Nuvei Group and Affiliates
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Any of the processing carried out by Nuvei
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Nuvei's partners, service providers, affiliates and
                    subsidiaries
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Use of personal data in the provision of payments and
                    ancillary services, chargebacks, the investigation of
                    suspected incidents of fraud and other services necessary to
                    support the provision of payment services.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Any applicable Acquirer, Alternative Payment Method
                    Provider, Financial Institution, Card Scheme and their
                    respective sub-processors, (including sub-processors located
                    outside the EEA)
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Use of personal data in the provision of payments and
                    ancillary services, chargebacks, the investigation of
                    suspected incidents of fraud and other services necessary to
                    support the provision of payment services.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Compliance service providers
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Use of personal data in the performance of checks to
                    identify politically exposed persons, persons that are
                    subject to sanctions and other checks required by laws to
                    which Nuvei is subject.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid black" }}>
                    Technology service providers used in the administration of
                    payment, reconciliation and fraud services
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    Use of personal data to facilitate the provision of payment
                    services (including ancillary services) and fraud services.
                  </td>
                </tr>
              </tbody>
            </table>

            <Div marginTop={5}>
              <USPaymentTermsAppendix1Content />
            </Div>

            <Div classNames={["t-row"]} marginTop={5}>
              0149012.0751650 4878-6692-0060v6
            </Div>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default USPaymentTerms;
