import { Helmet } from "react-helmet";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Privacy Policy</title>
        <meta name="description" content="" />
      </Helmet>

      <PageHeader title="Privacy Policy" subtitle="" />

      <Div container>
        <Div row marginTop={4}>
          <Div col>
            <p>
              Your privacy is important to us. It is Integrated Register
              Systems, Inc.'s policy to respect your privacy regarding any
              information we may collect from you across our website,{" "}
              <a href="https://www.intouchreceipting.com">
                https://www.intouchreceipting.com
              </a>
              ,{" "}
              <a href="https://www.intouchpay.com">
                https://www.intouchpay.com
              </a>
              , and other sites we own and operate.
            </p>
            <h4>1. Information we collect</h4>
            <h5>Log data</h5>
            <p>
              When you visit our website, our servers may automatically log the
              standard data provided by your web browser. It may include your
              computer's Internet Protocol (IP) address, your browser type and
              version, the pages you visit, the time and date of your visit, the
              time spent on each page, and other details.
            </p>
            <h5>Device data</h5>
            <p>
              We may also collect data about the device you're using to access
              our website. This data may include the device type, operating
              system, unique device identifiers, device settings, and
              geo-location data. What we collect can depend on the individual
              settings of your device and software. We recommend checking the
              policies of your device manufacturer or software provider to learn
              what information they make available to us.
            </p>
            <h5>Personal information</h5>
            <p>We may ask for personal information, such as your:</p>
            <ul>
              <li>Name</li>
              <li>Email</li>
              <li>Date of birth</li>
              <li>Phone/mobile number</li>
              <li>Home/Mailing address</li>
              <li>Work address</li>
              <li>Payment information</li>
              <li>Driver's license details</li>
              <li>Tax/SSN/Medicare/etc. number</li>
              <li>Other data requested on behalf of our clients</li>
            </ul>
            <p>
              In connection with the accounting, fee management, receipting and
              other software we provide to schools and school boards, we receive
              personal information from school boards and school board
              employees, including school bank account information, employee
              names and business contact information, employee identification
              number, student names, date of birth, gender, address, phone
              number, school board district, school, grade level, homeroom
              number, course enrollment, student identification number, and
              parent/guardian information. We use this information in order to
              administer our service and provide software products to our school
              and school board customers, including assigning school fees to the
              appropriate accounts, facilitating payment for such fees,
              obtaining and administering permissions, and providing accounting
              and finance services to school boards . We rely on our school and
              school board clients to ensure they have all necessary authority
              and consent to provide this information to us in accordance with
              applicable privacy legislation for the purposes of providing the
              software services. We may monitor use of the software by our
              customers’ employees or other authorized users in order to detect,
              investigate or prevent any actual or potential violation of our
              terms and conditions and to better understand the improve the
              software.
            </p>
            <p>
              We will use your data to provide you with the services you
              requested.
            </p>
            <h5>Business data</h5>
            <p>
              Business data refers to data that accumulates over the normal
              course of operation on our platform. This may include transaction
              records, stored files, user profiles, analytics data and other
              metrics, as well as other types of information, created or
              generated, as users interact with our services.
            </p>
            <h4>2. Legal bases for processing</h4>
            <p>
              We will process your personal information lawfully, fairly and in
              a transparent manner. We collect and process information about you
              only where we have legal bases for doing so.
            </p>
            <p>
              These legal bases depend on the services you use and how you use
              them, meaning we collect and use your information only where:
            </p>
            <ul>
              <li>
                it's necessary for the performance of a contract to which you
                are a party or to take steps at your request before entering
                into such a contract (for example, when we provide a service you
                request from us);
              </li>
              <li>
                it satisfies a legitimate interest (which is not overridden by
                your data protection interests), such as for research and
                development, to market and promote our services, and to protect
                our legal rights and interests;
              </li>
              <li>
                you give us consent to do so for a specific purpose (for
                example, you might consent to us sending you our newsletter); or
              </li>
              <li>
                we need to process your data to comply with a legal obligation.
              </li>
            </ul>
            <p>
              Where you consent to our use of information about you for a
              specific purpose, you have the right to change your mind at any
              time (but this will not affect any processing that has already
              taken place).
            </p>
            <p>
              We store your personal information for a period of time that is
              consistent with our business purposes. While we retain this
              information, we will protect it within commercially acceptable
              means to prevent loss and theft, as well as unauthorized access,
              disclosure, copying, use or modification. Security procedures are
              in place to protect the confidentiality of your data. That said,
              we advise that no method of electronic transmission or storage is
              100% secure and cannot guarantee absolute data security. If
              necessary, we may retain your personal information for our
              compliance with a legal obligation or in order to protect your
              vital interests or the vital interests of another natural person.
            </p>
            <h4>3. Collection and use of information</h4>
            <p>
              We may collect, hold, use and disclose information for the
              following purposes and personal information will not be further
              processed in a manner that is incompatible with these purposes:
            </p>
            <ul>
              <li>to provide you with our platform's core features;</li>
              <li>to process any transactional or ongoing payments;</li>
              <li>
                to enable you to access and use our website, associated
                applications and associated social media platforms;
              </li>
              <li>to contact and communicate with you;</li>
              <li>for internal record keeping and administrative purposes;</li>
              <li>
                for analytics, market research and business development,
                including to operate and improve our website, associated
                applications and associated social media platforms;
              </li>
              <li>
                for advertising and marketing, including to send you promotional
                information about our products and services and information
                about third parties that we consider may be of interest to you;
                and
              </li>
              <li>
                to comply with our legal obligations and resolve any disputes
                that we may have.
              </li>
            </ul>
            <h4>4. Disclosure of personal information to third parties</h4>
            <p>
              We will not sell your data to third parties, but we may disclose
              personal information to:
            </p>
            <ul>
              <li>
                third party service providers for the purpose of enabling them
                to provide their services, including (without limitation) IT
                service providers, data storage, hosting and server providers,
                ad networks, analytics, error loggers, debt collectors,
                maintenance or problem-solving providers, marketing or
                advertising providers, professional advisors and payment systems
                operators;
              </li>
              <li>our employees, contractors and/or related entities;</li>
              <li>
                credit reporting agencies, courts, tribunals and regulatory
                authorities, in the event you fail to pay for goods or services
                we have provided to you;
              </li>
              <li>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise or defend our legal rights; and
              </li>
              <li>third parties to collect and process data.</li>
            </ul>
            <h4>5. International transfers of personal information</h4>
            <p>
              The personal information we collect is stored and processed in
              United States, or where we or our partners, affiliates and
              third-party providers maintain facilities. By providing us with
              your personal information, you consent to the disclosure to these
              overseas third parties.
            </p>
            <p>
              We will ensure that any transfer of personal information from
              countries in the European Economic Area (EEA) to countries outside
              the EEA will be protected by appropriate safeguards, for example
              by using standard data protection clauses approved by the European
              Commission, or the use of binding corporate rules or other legally
              accepted means.
            </p>
            <p>
              Where we transfer personal information from a non-EEA country to
              another country, you acknowledge that third parties in other
              jurisdictions may not be subject to similar data protection laws
              to the ones in our jurisdiction. There are risks if any such third
              party engages in any act or practice that would contravene the
              data privacy laws in our jurisdiction and this might mean that you
              will not be able to seek redress under our jurisdiction's privacy
              laws.
            </p>
            <h4>6. Your rights and controlling your personal information</h4>
            <p>
              <strong>Choice and consent:</strong> By providing personal
              information to us, you consent to us collecting, holding, using
              and disclosing your personal information in accordance with this
              privacy policy. If you are under 16 years of age, you must have,
              and warrant to the extent permitted by law to us, that you have
              your parent or legal guardian's permission to access and use the
              website and they (your parents or guardian) have consented to you
              providing us with your personal information. You do not have to
              provide personal information to us, however, if you do not, it may
              affect your use of this website or the products and/or services
              offered on or through it.
            </p>
            <p>
              <strong>Information from third parties:</strong> If we receive
              personal information about you from a third party, we will protect
              it as set out in this privacy policy. If you are a third party
              providing personal information about somebody else, you represent
              and warrant that you have such person's consent to provide the
              personal information to us.
            </p>
            <p>
              <strong>Restrict:</strong> You may choose to restrict the
              collection or use of your personal information. If you have
              previously agreed to us using your personal information for direct
              marketing purposes, you may change your mind at any time by
              contacting us using the details below. If you ask us to restrict
              or limit how we process your personal information, we will let you
              know how the restriction affects your use of our website or
              products and services.
            </p>
            <p>
              <strong>Access and data portability:</strong> You may request
              details of the personal information that we hold about you. You
              may request a copy of the personal information we hold about you.
              Where possible, we will provide this information in CSV format or
              other easily readable machine format. You may request that we
              erase the personal information we hold about you at any time. You
              may also request that we transfer this personal information to
              another third party.
            </p>
            <p>
              <strong>Correction:</strong> If you believe that any information
              we hold about you is inaccurate, out of date, incomplete,
              irrelevant or misleading, please contact us using the details
              below. We will take reasonable steps to correct any information
              found to be inaccurate, incomplete, misleading or out of date.
            </p>
            <p>
              <strong>Notification of data breaches:</strong> We will comply
              laws applicable to us in respect of any data breach.
            </p>
            <p>
              <strong>Complaints:</strong> If you believe that we have breached
              a relevant data protection law and wish to make a complaint,
              please contact us using the details below and provide us with full
              details of the alleged breach. We will promptly investigate your
              complaint and respond to you, in writing, setting out the outcome
              of our investigation and the steps we will take to deal with your
              complaint. You also have the right to contact a regulatory body or
              data protection authority in relation to your complaint.
            </p>
            <p>
              <strong>Unsubscribe:</strong> To unsubscribe from our e-mail
              database or opt-out of communications (including marketing
              communications), please contact us using the details below or
              opt-out using the opt-out facilities provided in the
              communication.
            </p>
            <h4>7. Cookies</h4>
            <p>
              We use “cookies” to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit, so we can understand how you use our site. This helps us
              serve you content based on preferences you have specified. Please
              refer to our Cookie Policy for more information.
            </p>
            <h4>8. Business transfers</h4>
            <p>
              If we or our assets are acquired, or in the unlikely event that we
              go out of business or enter bankruptcy, we would include data
              among the assets transferred to any parties who acquire us. You
              acknowledge that such transfers may occur, and that any parties
              who acquire us may continue to use your personal information
              according to this policy.
            </p>
            <h4>9. Limits of our policy</h4>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </p>
            <h4>10. Changes to this policy</h4>
            <p>
              At our discretion, we may change our privacy policy to reflect
              current acceptable practices. We will take reasonable steps to let
              users know about changes via our website. Your continued use of
              this site after any changes to this policy will be regarded as
              acceptance of our practices around privacy and personal
              information.{" "}
            </p>
            <p>
              If we make a significant change to this privacy policy, for
              example changing a lawful basis on which we process your personal
              information, we will ask you to re-consent to the amended privacy
              policy.
            </p>
            <p>
              <strong>Integrated Register Systems, Inc.</strong>
              <br />
              Privacy Officer
              <br />
              https://www.intouchreceipting.com
              <br />
              https://www.intouchpay.com
            </p>
            <p>This policy is effective as of January 1, 2020.</p>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default PrivacyPolicy;
