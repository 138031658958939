import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { routes } from "./misc/routes";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Support from "./pages/Support";
import Sales from "./pages/Sales";
import About from "./pages/About";

import Integrations from "./pages/Integrations";
import FeeFineManagement from "./pages/FeeFineManagement";
import AccountingIntegration from "./pages/AccountingIntegration";
import PointOfSale from "./pages/PointOfSale";
import PaymentsPortal from "./pages/PaymentsPortal";
import AthleticActivityRegistration from "./pages/AthleticActivityRegistration";
import FundraisingDonations from "./pages/FundraisingDonations";

import Partners from "./pages/Partners";
import Testimonials from "./pages/Testimonials";
import Careers from "./pages/Careers";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import CookiePolicy from "./pages/CookiePolicy";
import USPaymentTerms from "./pages/USPaymentTerms";

import TouchBaseLiteRequest from "./pages/TouchBaseLiteRequest";
import ServiceLevelAgreements from "./pages/ServiceLevelAgreements";
import USServiceLevelAgreement from "./pages/USServiceLevelAgreement";
import USPaymentTermsAppendix1 from "./pages/USPaymentTermsAppendix1";

const App = () => {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="InTouch | %s">
        <title>InTouch | Leading K-12 School Payments Platform</title>
      </Helmet>

      <Header />
      <main className="flex-shrink-0 flex-fill print-no-margin" style={{ marginTop: "80px" }}>
        <ScrollToTop />
        <Routes>
          <Route path={routes.Home} element={<Home />} />
          <Route path={routes.Support} element={<Support />} />
          <Route path={routes.Sales} element={<Sales />} />
          <Route path={routes.About} element={<About />} />

          <Route path={routes.Integrations} element={<Integrations />} />
          <Route
            path={routes.FeeFineManagement}
            element={<FeeFineManagement />}
          />
          <Route
            path={routes.AccountingIntegration}
            element={<AccountingIntegration />}
          />
          <Route path={routes.PointOfSale} element={<PointOfSale />} />
          <Route path={routes.PaymentsPortal} element={<PaymentsPortal />} />
          <Route
            path={routes.AthleticActivityRegistration}
            element={<AthleticActivityRegistration />}
          />
          <Route
            path={routes.FundraisingDonations}
            element={<FundraisingDonations />}
          />

          <Route path={routes.Partners} element={<Partners />} />
          <Route path={routes.Testimonials} element={<Testimonials />} />
          <Route path={routes.Careers} element={<Careers />} />

          <Route path={routes.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.TermsOfService} element={<TermsOfService />} />
          <Route path={routes.CookiePolicy} element={<CookiePolicy />} />

          <Route
            path={routes.ServiceLevelAgreements}
            element={<ServiceLevelAgreements />}
          />
          <Route
            path={routes.ServiceLevelAgreementsNoHypen}
            element={<ServiceLevelAgreements />}
          />

          <Route
            path={routes.USServiceLevelAgreement}
            element={<USServiceLevelAgreement />}
          />
          <Route
            path={routes.USServiceLevelAgreementNoHyphen}
            element={<USServiceLevelAgreement />}
          />

          <Route path={routes.USPaymentTerms} element={<USPaymentTerms />} />
          <Route
            path={routes.USPaymentTermsAppendix1}
            element={<USPaymentTermsAppendix1 />}
          />

          <Route
            path={routes.TouchBaseLiteRequest}
            element={<TouchBaseLiteRequest />}
          />
          <Route
            path={routes.TouchBaseLiteRequestOld}
            element={<Navigate to={routes.TouchBaseLiteRequest} />}
          />

          {/* <Route path="*" element={<Navigate to={routes.Home} />} /> */}
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
