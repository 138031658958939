/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/

export {};

const composeComparers =
  <T>(
    previousComparer: (a: T, b: T) => number,
    currentComparer: (a: T, b: T) => number
  ): ((a: T, b: T) => number) =>
  (a: T, b: T) =>
    previousComparer(a, b) || currentComparer(a, b);

const keyComparer =
  <T>(
    _keySelector: (key: T) => string,
    descending?: boolean
  ): ((a: T, b: T) => number) =>
  (a: T, b: T) => {
    const sortKeyA = _keySelector(a);
    const sortKeyB = _keySelector(b);
    if (sortKeyA > sortKeyB) {
      return !descending ? 1 : -1;
    } else if (sortKeyA < sortKeyB) {
      return !descending ? -1 : 1;
    } else {
      return 0;
    }
  };

declare global {
  interface Array<T> {
    toClassName(this: string[]): string;

    toCsv(this: (string | {})[]): string;

    toQuery(this: string[]): string;

    toSequence(this: T[], length: number): number[];

    sum(this: T[], transform: (value: T) => number): number;

    count(
      this: T[],
      predicate?: (value: T, index?: number, list?: T[]) => boolean
    ): number;

    any(
      this: T[],
      predicate?: (value: T, index?: number, list?: T[]) => boolean
    ): boolean;

    where(
      this: T[],
      predicate: (value: T, index?: number, list?: T[]) => boolean
    ): T[];

    firstOrDefault(
      this: T[],
      predicate?: (value: T, index?: number, list?: T[]) => boolean
    ): T | undefined;

    groupBy(
      this: T[],
      keySelector?: (key: T) => any
    ): { key: any; values: T[] }[];

    _comparer: (a: T, b: T) => number;

    orderBy(this: T[], keySelector: (key: T) => any): T[];

    orderByDescending(this: T[], keySelector: (key: T) => any): T[];

    thenBy(this: T[], keySelector: (key: T) => any): T[];

    thenByDescending(this: T[], keySelector: (key: T) => any): T[];
  }
}

Array.prototype.toClassName = function () {
  return this.filter((x) => x)
    .join(" ")
    .trim();
};

Array.prototype.toCsv = function () {
  return typeof this[0] === "object"
    ? this?.map((o) => Object.keys(o).map((x) => `${x}: ${(o as any)[x]}`))
        .join(", ")
        .trim()
    : this.join(", ").trim();
};

Array.prototype.toQuery = function () {
  return `?${this.join("&").trim()}`;
};

Array.prototype.toSequence = function (length: number) {
  return [...Array(length)].map((_, index) => index + 1);
};

Array.prototype.sum = function <T>(transform: (value: T) => number) {
  return this.map(transform).reduce((p, c) => p + c, 0);
};

Array.prototype.count = function (
  predicate?: (value: any, index?: number, list?: any[]) => boolean
) {
  return (predicate ? this.filter(predicate) : this).length;
};

Array.prototype.any = function (
  predicate?: (value: any, index?: number, list?: any[]) => boolean
) {
  return (predicate ? this.filter(predicate) : this)?.length > 0;
};

Array.prototype.where = function (
  predicate: (value: any, index?: number, list?: any[]) => boolean
) {
  return this.filter(predicate);
};

Array.prototype.firstOrDefault = function (
  predicate?: (value: any, index?: number, list?: any[]) => boolean
) {
  return (predicate ? this.filter(predicate) : this)[0];
};

Array.prototype.groupBy = function <T>(keySelector?: (key: keyof T) => any) {
  if (!keySelector) {
    return this;
  }

  return this.reduce((groups: { key: any; values: T[] }[], item) => {
    const key = keySelector(item);

    let group = groups.firstOrDefault((x) => x.key === key);

    if (!group) {
      group = { key, values: [] };
      groups.push(group);
    }

    group.values.push(item);
    return groups;
  }, []);
};

Array.prototype.orderBy = function <T>(keySelector: (key: keyof T) => any) {
  Array.prototype._comparer = keyComparer(keySelector, false);
  return this.sort(Array.prototype._comparer);
};

Array.prototype.orderByDescending = function <T>(
  keySelector: (key: keyof T) => any
) {
  Array.prototype._comparer = keyComparer(keySelector, true);
  return this.sort(Array.prototype._comparer);
};

Array.prototype.thenBy = function <T>(keySelector: (key: keyof T) => any) {
  return this.sort(
    composeComparers(this._comparer, keyComparer(keySelector, false))
  );
};

Array.prototype.thenByDescending = function <T>(
  keySelector: (key: keyof T) => any
) {
  return this.sort(
    composeComparers(this._comparer, keyComparer(keySelector, true))
  );
};
