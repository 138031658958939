import { ReactNode } from "react";

export type ShirtSize = "sm" | "md" | "lg" | "xl" | "xxl";
export type FlexJustify = "start" | "end" | "center" | "between" | "around" | "evenly";
export type FlexAlignment = "start" | "end" | "center" | "baseline" | "stretch";
export type TextAlignment = "start" | "end" | "center";

export type OneToFive = 1 | 2 | 3 | 4 | 5;
export type ZeroToFive = 0 | OneToFive;
export type OneToTwelve = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type ZeroToTwelve = 0 | OneToTwelve;

export type ColumnSize = boolean | ZeroToTwelve | "auto";

export type BaseColor = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
export type BaseSubtleColor =
  | "primary-subtle"
  | "secondary-subtle"
  | "success-subtle"
  | "danger-subtle"
  | "warning-subtle"
  | "info-subtle"
  | "light-subtle"
  | "dark-subtle";
export type BaseSecondaryColor = "body-secondary";
export type BaseTertiaryColor = "body-tertiary";
export type AlertColor = BaseColor;
export type ButtonColor = BaseColor | "link";
export type TextBackgroundColor = BaseColor;
export type BackgroundColor = BaseColor | BaseSubtleColor | BaseSecondaryColor | BaseTertiaryColor | "body" | "black" | "white" | "transparent";
export type BorderColor = BaseColor | "body" | "white";
export type TextColor = BaseColor | "body" | "white" | "muted" | "black-50" | "white-50";

export type Display = "none" | "inline" | "inline-block" | "block" | "grid" | "table" | "table-cell" | "table-row" | "flex" | "inline-flex";

export type Margin = ZeroToFive | "auto" | [ZeroToFive | "auto", ShirtSize];
export type Offset = ZeroToTwelve | "auto" | [ZeroToTwelve | "auto", ShirtSize];
export type Overflow = "auto" | "hidden" | "visible" | "scroll";

type BootstrapProps = { when?: boolean } & {
  active?: boolean;

  alert?: boolean;
  alertColor?: AlertColor;

  backgroundColor?: BackgroundColor;
  backgroundGradient?: boolean;
  backgroundOpacity?: 0 | 25 | 50 | 75 | 100;

  badge?: boolean;

  border?: boolean;
  borderBottom?: boolean | ZeroToFive;
  borderColor?: BorderColor;
  borderEnd?: boolean;
  borderStart?: boolean;
  borderTop?: boolean;
  borderWidth?: OneToFive;

  breadcrumb?: boolean;
  breadcrumbItem?: boolean;

  btn?: boolean;
  btnSm?: boolean;
  btnLg?: boolean;
  btnType?: ButtonColor;
  btnOutline?: ButtonColor;
  btnToolbar?: boolean;
  btnGroup?: boolean;

  card?: boolean;
  cardHeader?: boolean;
  cardBody?: boolean;
  cardFooter?: boolean;

  children?: ReactNode | ReactNode[];
  classNames?: string[];

  container?: boolean;

  col?: ColumnSize;
  colXs?: ColumnSize;
  colSm?: ColumnSize;
  colMd?: ColumnSize;
  colLg?: ColumnSize;
  colXl?: ColumnSize;
  colXxl?: ColumnSize;

  checkLabel?: boolean;
  colLabel?: boolean;

  cursor?: "pointer" | "default";

  display?: Display | 1 | 2 | 3 | 4 | 5 | 6;
  displayXs?: Display;
  displaySm?: Display;
  displayMd?: Display;
  displayLg?: Display;
  displayXl?: Display;
  displayXxl?: Display;

  displayPrint?: Display;

  dropdown?: boolean;
  dropdownMenu?: boolean;
  dropdownMenuEnd?: boolean;
  dropdownItem?: boolean;

  feedback?: "valid" | "invalid";

  flex?: boolean;
  flexXs?: boolean;
  flexSm?: boolean;
  flexMd?: boolean;
  flexLg?: boolean;
  flexXl?: boolean;
  flexXxl?: boolean;

  flexAlign?: FlexAlignment;
  flexAlignXs?: FlexAlignment;
  flexAlignSm?: FlexAlignment;
  flexAlignMd?: FlexAlignment;
  flexAlignLg?: FlexAlignment;
  flexAlignXl?: FlexAlignment;
  flexAlignXxl?: FlexAlignment;
  flexAlignSelf?: FlexAlignment;

  flexColumn?: boolean | ShirtSize;
  flexColumnReverse?: boolean | ShirtSize;

  flexJustify?: FlexJustify;
  flexJustifyXs?: FlexJustify;
  flexJustifySm?: FlexJustify;
  flexJustifyMd?: FlexJustify;
  flexJustifyLg?: FlexJustify;
  flexJustifyXl?: FlexJustify;
  flexJustifyXxl?: FlexJustify;

  flexRow?: boolean | ShirtSize;
  flexRowReverse?: boolean | ShirtSize;

  flexWrap?: boolean;
  flexNoWrap?: boolean;

  flexBasis?: 0; // not from bootstrap
  flexFill?: boolean | ShirtSize;
  flexGrow?: (0 | 1) | [0 | 1, ShirtSize];
  flexShrink?: (0 | 1) | [0 | 1, ShirtSize];

  fontSize?: OneToFive | 5.5 | 6 | 6.5 | 7;
  fontStyle?: "italic" | "normal";
  fontWeight?: "bold" | "bolder" | "semibold" | "normal" | "light" | "lighter";

  formText?: boolean;

  gap?: ZeroToFive;

  grid?: boolean;

  gutter?: ZeroToFive;
  gutterX?: ZeroToFive;
  gutterY?: ZeroToFive;

  height?: 0 | 25 | 50 | 100 | "auto";

  inputGroup?: boolean;
  inputGroupText?: boolean;

  isInvalid?: boolean;
  isValid?: boolean;

  listGroup?: boolean;
  listGroupFlush?: boolean;
  listGroupItem?: boolean;
  listGroupItemAction?: boolean;

  margin?: Margin;
  marginBottom?: Margin;
  marginEnd?: Margin;
  marginStart?: Margin;
  marginTop?: Margin;
  marginTopMd?: Margin;
  marginX?: Margin;
  marginY?: Margin;

  maxHeight?: 100;
  maxWidth?: 100;

  nav?: boolean;
  navFill?: boolean;
  navItem?: boolean;
  navLink?: boolean;
  navUnderline?: boolean;
  navPills?: boolean;
  navTabs?: boolean;

  offset?: Offset;

  opacity?: 0 | 25 | 50 | 75 | 100;

  overflow?: Overflow;
  overflowX?: Overflow;
  overflowY?: Overflow;

  padding?: ZeroToFive;
  paddingBottom?: ZeroToFive;
  paddingEnd?: ZeroToFive;
  paddingStart?: ZeroToFive;
  paddingTop?: ZeroToFive;

  paddingX?: ZeroToFive;
  paddingX_Xs?: ZeroToFive;
  paddingX_Sm?: ZeroToFive;
  paddingX_Md?: ZeroToFive;
  paddingX_Lg?: ZeroToFive;
  paddingX_Xl?: ZeroToFive;
  paddingX_Xxl?: ZeroToFive;

  paddingY?: ZeroToFive | 2.5;
  paddingY_Xs?: ZeroToFive;
  paddingY_Sm?: ZeroToFive;
  paddingY_Md?: ZeroToFive;
  paddingY_Lg?: ZeroToFive;
  paddingY_Xl?: ZeroToFive;
  paddingY_Xxl?: ZeroToFive;

  position?: "static" | "relative" | "absolute" | "fixed" | "sticky";

  rounded?: boolean | 0 | 1 | 2 | 3 | "top" | "end" | "bottom" | "start" | "circle" | "pill";
  roundedTop?: boolean | 0 | 1 | 2 | 3 | "circle" | "pill";

  row?: boolean;

  show?: boolean;

  textAlign?: TextAlignment;
  textBackgroundColor?: TextBackgroundColor;
  textBreak?: boolean;
  textCapitalize?: boolean;
  textCenter?: boolean | ShirtSize;
  textColor?: TextColor;
  textEnd?: boolean | ShirtSize;
  textLowercase?: boolean;
  textNoWrap?: boolean;
  textStart?: boolean | ShirtSize;
  textTruncate?: boolean;
  textUnderline?: boolean;
  textUppercase?: boolean;
  textWrap?: boolean;

  userSelect?: "none";

  invisible?: boolean;
  visible?: boolean;
  visuallyHidden?: boolean;

  width?: 0 | 25 | 50 | 100 | "auto";
};

export const GetClassNamesFromProps = <T>(props: { [key: string]: T }, classNames?: string[]) => {
  let remainingProps: { [key: string]: T } = {};
  const propEntries = Object.entries(props).where((x) => x[1] !== undefined);

  classNames ??= [];

  propEntries.forEach((prop) => {
    const [key, value] = prop;

    if (value === undefined || value === null) {
      return;
    }

    switch (key as keyof BootstrapProps) {
      case "alert":
      case "badge":
      case "breadcrumb":
      case "btn":
      case "card":
      case "container":
      case "dropdown":
      case "row":
        classNames!.push(key);
        break;

      case "active":
        value && classNames!.push("active");
        break;

      case "alertColor":
        classNames!.push(`alert-${value}`);
        break;

      case "backgroundColor":
        classNames!.push(`bg-${value}`);
        break;
      case "backgroundGradient":
        classNames!.push("bg-gradient");
        break;
      case "backgroundOpacity":
        classNames!.push(`bg-opacity-${value}`);
        break;

      case "border":
        classNames!.push(value ? "border" : "border-0");
        break;
      // case "borderBottom":
      //   classNames!.push(value ? "border-bottom" : "border-bottom-0");
      //   break;
      case "borderBottom":
        typeof value === "boolean"
          ? classNames!.push("border-bottom")
          : classNames!.push(`border-bottom border-${value}`);
        break
      case "borderColor":
        classNames!.push(`border-${value}`);
        break;
      case "borderEnd":
        classNames!.push(value ? "border-end" : "border-end-0");
        break;
      case "borderStart":
        classNames!.push(value ? "border-start" : "border-start-0");
        break;
      case "borderTop":
        classNames!.push(value ? "border-top" : "border-top-0");
        break;
      case "borderWidth":
        classNames!.push(`border-${value}`);
        break;

      case "breadcrumbItem":
        classNames!.push("breadcrumb-item");
        break;

      case "btnSm":
        classNames!.push("btn-sm");
        break;
      case "btnLg":
        classNames!.push("btn-lg");
        break;
      case "btnType":
        classNames!.push(`btn-${value}`);
        break;
      case "btnOutline":
        classNames!.push(`btn-outline-${value}`);
        break;
      case "btnToolbar":
        classNames!.push("btn-toolbar");
        break;
      case "btnGroup":
        classNames!.push("btn-group");
        break;

      case "cardHeader":
        classNames!.push("card-header");
        break;
      case "cardBody":
        classNames!.push("card-body");
        break;
      case "cardFooter":
        classNames!.push("card-footer");
        break;

      case "col":
        classNames!.push(typeof value === "boolean" ? "col" : `col-${value}`);
        break;
      case "colXs":
        classNames!.push(typeof value === "boolean" ? "col-xs" : `col-xs-${value}`);
        break;
      case "colSm":
        classNames!.push(typeof value === "boolean" ? "col-sm" : `col-sm-${value}`);
        break;
      case "colMd":
        classNames!.push(typeof value === "boolean" ? "col-md" : `col-md-${value}`);
        break;
      case "colLg":
        classNames!.push(typeof value === "boolean" ? "col-lg" : `col-lg-${value}`);
        break;
      case "colXl":
        classNames!.push(typeof value === "boolean" ? "col-xl" : `col-xl-${value}`);
        break;
      case "colXxl":
        classNames!.push(typeof value === "boolean" ? "col-xxl" : `col-xxl-${value}`);
        break;

      case "checkLabel":
        classNames!.push("form-check-label");
        break;
      case "colLabel":
        classNames!.push("col-form-label");
        break;

      case "cursor":
        classNames!.push("cursor-pointer");
        break;

      case "display":
        classNames!.push(typeof value === "number" ? `display-${value}` : `d-${value}`);
        break;
      case "displayXs":
        classNames!.push(`d-xs-${value}`);
        break;
      case "displaySm":
        classNames!.push(`d-sm-${value}`);
        break;
      case "displayMd":
        classNames!.push(`d-md-${value}`);
        break;
      case "displayLg":
        classNames!.push(`d-lg-${value}`);
        break;
      case "displayXl":
        classNames!.push(`d-xl-${value}`);
        break;
      case "displayXxl":
        classNames!.push(`d-xxl-${value}`);
        break;

      case "displayPrint":
        classNames!.push(`d-print-${value}`);
        break;

      case "dropdownMenu":
        classNames!.push("dropdown-menu");
        break;
      case "dropdownMenuEnd":
        classNames!.push("dropdown-menu-end");
        break;
      case "dropdownItem":
        classNames!.push("dropdown-item");
        break;

      case "feedback":
        classNames!.push(`${value}-feedback`);
        break;

      case "flex":
        classNames!.push("d-flex");
        break;
      case "flexXs":
        classNames!.push("d-xs-flex");
        break;
      case "flexSm":
        classNames!.push("d-sm-flex");
        break;
      case "flexMd":
        classNames!.push("d-md-flex");
        break;
      case "flexLg":
        classNames!.push("d-lg-flex");
        break;
      case "flexXl":
        classNames!.push("d-xl-flex");
        break;
      case "flexXxl":
        classNames!.push("d-xxl-flex");
        break;

      case "flexAlign":
        classNames!.push(`align-items-${value}`);
        break;
      case "flexAlignXs":
        classNames!.push(`align-items-xs-${value}`);
        break;
      case "flexAlignSm":
        classNames!.push(`align-items-sm-${value}`);
        break;
      case "flexAlignMd":
        classNames!.push(`align-items-md-${value}`);
        break;
      case "flexAlignLg":
        classNames!.push(`align-items-lg-${value}`);
        break;
      case "flexAlignXl":
        classNames!.push(`align-items-xl-${value}`);
        break;
      case "flexAlignXxl":
        classNames!.push(`align-items-xxl-${value}`);
        break;
      case "flexAlignSelf":
        classNames!.push(`align-self-${value}`);
        break;

      case "flexColumn":
        classNames!.push(typeof value === "boolean" ? "flex-column" : `flex-${value}-column`);
        break;
      case "flexColumnReverse":
        classNames!.push(typeof value === "boolean" ? (value ? "flex-column-reverse" : "") : `flex-${value}-column-reverse`);
        break;

      case "flexJustify":
        classNames!.push(`justify-content-${value}`);
        break;
      case "flexJustifyXs":
        classNames!.push(`justify-content-xs-${value}`);
        break;
      case "flexJustifySm":
        classNames!.push(`justify-content-sm-${value}`);
        break;
      case "flexJustifyMd":
        classNames!.push(`justify-content-md-${value}`);
        break;
      case "flexJustifyLg":
        classNames!.push(`justify-content-lg-${value}`);
        break;
      case "flexJustifyXl":
        classNames!.push(`justify-content-xl-${value}`);
        break;
      case "flexJustifyXxl":
        classNames!.push(`justify-content-xxl-${value}`);
        break;

      case "flexRow":
        classNames!.push(typeof value === "boolean" ? "flex-row" : `flex-${value}-row`);
        break;
      case "flexRowReverse":
        classNames!.push(typeof value === "boolean" ? (value ? "flex-row-reverse" : "") : `flex-${value}-row-reverse`);
        break;

      case "flexWrap":
        classNames!.push("flex-wrap");
        break;
      case "flexNoWrap":
        classNames!.push("flex-nowrap");
        break;

      case "flexBasis":
        classNames!.push(`flex-basis-${value}`);
        break;
      case "flexFill":
        classNames!.push(typeof value === "boolean" ? "flex-fill" : `flex-${value}-fill`);
        break;
      case "flexGrow":
        classNames!.push(!Array.isArray(value) ? `flex-grow-${value}` : `flex-${value[1]}-grow-${value[0]}`);
        break;
      case "flexShrink":
        classNames!.push(!Array.isArray(value) ? `flex-shrink-${value}` : `flex-${value[1]}-shrink-${value[0]}`);
        break;

      case "fontSize":
        classNames!.push(`fs-${value.toString().replace(".", "")}`);
        break;
      case "fontStyle":
        classNames!.push(`fst-${value}`);
        break;
      case "fontWeight":
        classNames!.push(`fw-${value}`);
        break;

      case "formText":
        classNames!.push("form-text");
        break;

      case "gap":
        classNames!.push(`gap-${value}`);
        break;

      case "grid":
        classNames!.push("d-grid");
        break;

      case "gutter":
        classNames!.push(`g-${value}`);
        break;
      case "gutterX":
        classNames!.push(`gx-${value}`);
        break;
      case "gutterY":
        classNames!.push(`gy-${value}`);
        break;

      case "height":
        classNames!.push(`h-${value}`);
        break;

      case "inputGroup":
        classNames!.push("input-group");
        break;
      case "inputGroupText":
        classNames!.push("input-group-text");
        break;

      case "isInvalid":
        value && classNames!.push("is-invalid");
        break;
      case "isValid":
        value && classNames!.push("is-valid");
        break;

      case "listGroup":
        classNames!.push("list-group");
        break;
      case "listGroupFlush":
        classNames!.push("list-group-flush");
        break;
      case "listGroupItem":
        classNames!.push("list-group-item");
        break;
      case "listGroupItemAction":
        classNames!.push("list-group-item-action");
        break;

      case "margin":
        classNames!.push(Array.isArray(value) ? `m-${value[1]}-${value[0]}` : `m-${value}`);
        break;
      case "marginBottom":
        classNames!.push(Array.isArray(value) ? `mb-${value[1]}-${value[0]}` : `mb-${value}`);
        break;
      case "marginEnd":
        classNames!.push(Array.isArray(value) ? `me-${value[1]}-${value[0]}` : `me-${value}`);
        break;
      case "marginStart":
        classNames!.push(Array.isArray(value) ? `ms-${value[1]}-${value[0]}` : `ms-${value}`);
        break;
      case "marginTop":
        classNames!.push(Array.isArray(value) ? `mt-${value[1]}-${value[0]}` : `mt-${value}`);
        break;
      case "marginTopMd":
        classNames!.push(`mt-md-${value}`);
        break;
      case "marginX":
        classNames!.push(Array.isArray(value) ? `mx-${value[1]}-${value[0]}` : `mx-${value}`);
        break;
      case "marginY":
        classNames!.push(Array.isArray(value) ? `my-${value[1]}-${value[0]}` : `my-${value}`);
        break;

      case "maxHeight":
        classNames!.push(`mh-${value}`);
        break;
      case "maxWidth":
        classNames!.push(`mw-${value}`);
        break;

      case "nav":
        classNames!.push("nav");
        break;
      case "navFill":
        classNames!.push("nav-fill");
        break;
      case "navItem":
        classNames!.push("nav-item");
        break;
      case "navLink":
        classNames!.push("nav-link");
        break;
      case "navUnderline":
        classNames!.push("nav-underline");
        break;
      case "navPills":
        classNames!.push("nav-pills");
        break;
      case "navTabs":
        classNames!.push("nav-tabs");
        break;

      case "offset":
        classNames!.push(Array.isArray(value) ? `offset-${value[1]}-${value[0]}` : `offset-${value}`);
        break;

      case "opacity":
        classNames!.push(`opacity-${value}`);
        break;

      case "overflow":
        classNames!.push(`overflow-${value}`);
        break;
      case "overflowX":
        classNames!.push(`overflow-x-${value}`);
        break;
      case "overflowY":
        classNames!.push(`overflow-y-${value}`);
        break;

      case "padding":
        classNames!.push(`p-${value}`);
        break;
      case "paddingBottom":
        classNames!.push(`pb-${value}`);
        break;
      case "paddingEnd":
        classNames!.push(`pe-${value}`);
        break;
      case "paddingStart":
        classNames!.push(`ps-${value}`);
        break;
      case "paddingTop":
        classNames!.push(`pt-${value}`);
        break;

      case "paddingX":
        classNames!.push(`px-${value}`);
        break;
      case "paddingX_Xs":
        classNames!.push(`px-xs-${value}`);
        break;
      case "paddingX_Sm":
        classNames!.push(`px-sm-${value}`);
        break;
      case "paddingX_Md":
        classNames!.push(`px-md-${value}`);
        break;
      case "paddingX_Lg":
        classNames!.push(`px-lg-${value}`);
        break;
      case "paddingX_Xl":
        classNames!.push(`px-xl-${value}`);
        break;
      case "paddingX_Xxl":
        classNames!.push(`px-xxl-${value}`);
        break;

      case "paddingY":
        classNames!.push(`py-${value.toString().replace(".", "")}`);
        break;
      case "paddingY_Xs":
        classNames!.push(`py-xs-${value}`);
        break;
      case "paddingY_Sm":
        classNames!.push(`py-sm-${value}`);
        break;
      case "paddingY_Md":
        classNames!.push(`py-md-${value}`);
        break;
      case "paddingY_Lg":
        classNames!.push(`py-lg-${value}`);
        break;
      case "paddingY_Xl":
        classNames!.push(`py-xl-${value}`);
        break;
      case "paddingY_Xxl":
        classNames!.push(`py-xxl-${value}`);
        break;

      case "position":
        classNames!.push(`position-${value}`);
        break;

      case "rounded":
        classNames!.push(typeof value === "boolean" ? "rounded" : `rounded-${value}`);
        break;
      case "roundedTop":
        classNames!.push(typeof value === "boolean" ? "rounded-top" : `rounded-top-${value}`);
        break;

      case "show":
        value && classNames!.push("show");
        break;

      case "textAlign":
        classNames!.push(`text-${value}`);
        break;
      case "textBackgroundColor":
        classNames!.push(`text-bg-${value}`);
        break;
      case "textBreak":
        classNames!.push("text-break");
        break;
      case "textCapitalize":
        classNames!.push("text-capitalize");
        break;
      case "textCenter":
        classNames!.push(typeof value === "boolean" ? "text-center" : `text-${value}-center`);
        break;
      case "textColor":
        classNames!.push(`text-${value}`);
        break;
      case "textEnd":
        classNames!.push(typeof value === "boolean" ? "text-end" : `text-${value}-end`);
        break;
      case "textLowercase":
        classNames!.push("text-lowercase");
        break;
      case "textNoWrap":
        classNames!.push("text-nowrap");
        break;
      case "textStart":
        classNames!.push(typeof value === "boolean" ? "text-start" : `text-${value}-start`);
        break;
      case "textTruncate":
        classNames!.push("text-truncate");
        break;
      case "textUnderline":
        !!value && classNames!.push("text-decoration-underline");
        break;
      case "textUppercase":
        classNames!.push("text-uppercase");
        break;
      case "textWrap":
        classNames!.push("text-wrap");
        break;

      case "userSelect":
        classNames!.push(`user-select-${value}`);
        break;

      case "visible":
        classNames!.push(value ? "visible" : "invisible");
        break;
      case "invisible":
        classNames!.push(value ? "invisible" : "visible");
        break;
      case "visuallyHidden":
        classNames!.push("visually-hidden");
        break;

      case "width":
        classNames!.push(`w-${value}`);
        break;

      default:
        remainingProps[key] = value;
    }
  });

  return { classNames, remainingProps };
};

export default BootstrapProps;
