import { ReactNode } from "react";

import Div from "./Div";
import Render from "./Render";
import PageHeader from "./PageHeader";
import CallToAction from "./CallToAction";

const PageWithHeaderCallToAction = ({
  title,
  subtitle,
  callToAction,
  children,
}: {
  title: string;
  subtitle: string;
  callToAction?: {
    title: string;
    subtitle: string;
    buttonHref: string;
    buttonText: string;
  };
  children: ReactNode;
}) => {
  return (
    <Div flex flexColumn height={100}>
      <PageHeader title={title} subtitle={subtitle} />
      <Div container flexShrink={0}>
        <Div row marginY={3}>
          <Div col>{children}</Div>
        </Div>
      </Div>
      <Render when={callToAction}>
        <CallToAction {...callToAction!} />
      </Render>
    </Div>
  );
};

export default PageWithHeaderCallToAction;
