import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const PaymentsPortal = () => {
  return (
    <PageWithHeaderCallToAction
      title="Payments Portal"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Payments Portal</title>
        <meta
          name="description"
          content="Secure and convenient online access."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Secure and convenient online access.
      </Div>

      <Div row>
        <Div col={12}>
          <ContentSection title="Why InTouch Payments Portal?">
            <p>
              InTouch Payments Portal and Point of Sale are the cornerstones of
              our platform. They work together seamlessly to provide a
              convenient experience to your students, parents, and staff with
              the information they need.
            </p>

            <ul>
              <li>Payments deposit directly into the district bank account</li>
              <li>
                We <strong>never</strong> touch the district's money
              </li>
              <li>
                No cash handling required, making for easy bank reconciliations
              </li>
              <li>
                No NSF checks and NSF check charges
                <ul>
                  <li>Helping the system pay for itself right away!</li>
                </ul>
              </li>
              <li>
                Districts using InTouch Payments Portal have reported up to 40%
                more revenue from fines
              </li>
              <li>Simplify with one payment solution for all your programs</li>
              <li>
                As always, there is a full audit trail, and its ready to upload
                to your GL
              </li>
            </ul>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="For Students & Parents">
            <ul>
              <li>Pay for everyone in one transaction</li>
              <li>
                Pay for everything in one place
                <ul>
                  <li>Activities</li>
                  <li>Course fees</li>
                  <li>Library fines</li>
                  <li>Device insurance</li>
                  <li>Nutrition deposits</li>
                  <li>Athletics participation</li>
                </ul>
              </li>
              <li>Pay on any device</li>
              <li>
                Athletics and activities registration
                <ul>
                  <li>
                    Fully-integrated{" "}
                    <a href={routes.AthleticActivityRegistration}>
                      InTouch Registrations
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="For The Community">
            <p>Not just for students!</p>
            Examples:
            <ul>
              <li>Community education</li>
              <li>Facility rentals</li>
              <li>Employee benefits</li>
              <li>Fingerprinting</li>
              <li>... and more</li>
            </ul>
            {/* <p>
              Pay for community ed classes, facility rentals, or employee
              benefits, fingerprinting, adult education classes, or
              community-based summer camps. The InTouch AR feature easily tracks
              partial payments, recurring online customer payments, and provides
              detailed financial ledgers to any entity on demand.
            </p> */}
          </ContentSection>
        </Div>

        {/* <Div col={12} colMd={4}>
          <ContentSection title="For Fundraising">
            <p>
              Raise money for clubs, teams, and activities with InTouch's easy
              to use fundraising and donation platform.
            </p>
            <ul>
              <li>
                Fundraising runs through InTouch, making it reliable and secure.
              </li>
              <li>Customizable to accommodate many types of fundraisers</li>
              <li>
                Donors quickly and easily give to your club, sport, event, or
                activity.
              </li>
              <li>
                3% processing fees compared to other crowdfunding sites, which
                charge up to 30%.
              </li>
              <li>
                With InTouch, <strong>97%</strong> of the money goes to the
                intended program!
              </li>
            </ul>
          </ContentSection>
        </Div> */}
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default PaymentsPortal;
