import React, { forwardRef } from "react";
import BootstrapProps, { GetClassNamesFromProps } from "./BootstrapProps";

type DivProps = Omit<React.HTMLAttributes<HTMLDivElement>, "className"> &
  BootstrapProps;

const Div = forwardRef<HTMLDivElement, DivProps>((props, ref) => {
  const bsClassNames = GetClassNamesFromProps(props, props.classNames);

  let { children, when, classNames, ...rest } = bsClassNames.remainingProps;

  return when === false ? null : (
    <div ref={ref} className={bsClassNames.classNames.toClassName()} {...rest}>
      {children}
    </div>
  );
});

export default Div;
