const useFetch = () => {
  // const [abortController, setAbortController] = useState(new AbortController());

  // useEffect(() => {
  //   return () => {
  //     abortController.abort();
  //   };
  // }, [abortController]);

  const getJson = async <T>(url: string, token?: string) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }

    const abort = new AbortController();
    //setAbortController(abort);

    let init: RequestInit = {
      headers,
      method: "GET",
      mode: "cors",
      signal: abort.signal,
    };

    let response = await fetchy(url, init);

    return await response.json() as T;
  };

  const postJson = async <T>(url: string, body: T, token?: string) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }

    const abort = new AbortController();
    //setAbortController(abort);

    const init = {
      body: JSON.stringify(body),
      headers,
      method: "POST",
      mode: "cors",
      signal: abort.signal,
    } as RequestInit;

    return fetchy(url, init);
  };

  const putJson = async <T>(url: string, body: T, token?: string) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }

    const abort = new AbortController();
    //setAbortController(abort);

    const init = {
      body: JSON.stringify(body),
      headers,
      method: "PUT",
      mode: "cors",
      signal: abort.signal,
    } as RequestInit;

    return fetchy(url, init);
  };

  const del = async (url: string, token: string) => {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    if (token) {
      headers.append("Authorization", "Bearer " + token);
    }

    const abort = new AbortController();
    //setAbortController(abort);

    let init: RequestInit = {
      headers,
      method: "DELETE",
      mode: "cors",
      signal: abort.signal,
    };

    if (token) {
      init.credentials = "include";
    }

    return fetchy(url, init);
  };

  // private
  const fetchy = async (url: string, init: RequestInit) => {
    try {
      //console.info(`${init.method?.toString()} ${url}`);
      let response = await fetch(url, init);

      return response;
    } catch (ex) {
      return Promise.reject();
    }
  };

  return {
    getJson,
    postJson,
    putJson,
    delete: del,
  };
};

export default useFetch;
