import { Helmet } from "react-helmet";

import { routes } from "../misc/routes";

import PageWithHeaderCallToAction from "../components/PageWithHeaderCallToAction";
import ContentSection from "../components/ContentSection";
import Div from "../components/Div";

const FeeFineManagement = () => {
  return (
    <PageWithHeaderCallToAction
      title="Fee/Fine Management"
      subtitle=""
      callToAction={{
        title: "Request a Demo",
        subtitle:
          "Let us share our amazing platform with your school district.",
        buttonHref: routes.Sales,
        buttonText: "Get Started",
      }}
    >
      <Helmet titleTemplate="InTouch | %s">
        <title>Fine/Fee Management</title>
        <meta
          name="description"
          content="Complete fine and fee management streamlines and saves time."
        />
      </Helmet>

      <Div
        textAlign="center"
        classNames={["text-intouch-green"]}
        marginTop={2}
        marginBottom={5}
        display={6}
      >
        Complete, end-to-end solution to streamline and save time.
      </Div>

      <Div row>
        <Div col={12} colMd={6}>
          <ContentSection title="Easy Management">
            <p>
              Quickly and accurately assess fines and fees to a single student
              or groups of students all at once, complete with automatic price
              shifting based on your policies.
            </p>
            <p>
              Purpose-built fine and fee reports streamline your processes and
              save staff time.
            </p>

            <h5 className="text-intouch-blue">Partial Payments</h5>
            <p>
              Provide a convenient and equitable option for making payments over
              time, increasing overall collection.
            </p>
            <p>
              Helpful for many scenarios, including expensive out-of-state
              trips, device replacement, and more.
            </p>

            <h5 className="text-intouch-blue">Controls</h5>
            <p>
              Automatically apply district policies regarding free/reduced
              pricing for authorized programs. Fees will be waived or adjusted
              accordingly.
            </p>
            <p>
              Optionally limit purchases of certain items such as parking
              passes, prom tickets, and more.
            </p>
          </ContentSection>
        </Div>

        <Div col={12} colMd={6}>
          <ContentSection title="Online Portal">
            <p>
              The InTouch platform makes fines and fees from your various
              systems available to view and pay, in-person and online.
            </p>
            <p>
              Provide real-time access to view fines and fees, purchase history
              all in one place.
            </p>
          </ContentSection>

          <ContentSection title="Integration">
            <p>
              Integration is critical for an efficient and streamlined
              experience for students, parents, and the district.
            </p>
            <p>
              By integrating fines and fees from your other systems, you
              increase the visibility of these outstanding debts, in-person and
              online.
            </p>
            <p>
              Increased visibility has many benefits, including increased
              revenue due to the school and recovering assets.
            </p>
          </ContentSection>

          <ContentSection title="Automate">
            <p>
              Easily automate course-based fees based on your district policies,
              featuring automatic free/reduced pricing.
            </p>
            <p>
              Integrations with your various other systems are automated as
              well; no manual imports or rekeying.
            </p>
          </ContentSection>

          <ContentSection title="GL Accounting">
            <p>
              All transactions automatically have the appropriate budget code
              attached, so when it's time to upload them to the GL, the data is
              ready for you.
            </p>
          </ContentSection>
        </Div>
      </Div>
    </PageWithHeaderCallToAction>
  );
};

export default FeeFineManagement;
