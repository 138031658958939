import "../styles/iconlink.scss";

import Div from "../components/Div";

const IconLink = ({
  href,
  src,
  alt,
}: {
  href: string;
  src: string;
  alt: string;
}) => {
  return (
    <Div col={4} colMd={2} paddingX={1}>
      <a href={href} className="icon-link">
        <Div
          flex
          flexColumn
          flexAlign="center"
          textAlign="center"
          border
          rounded
          height={100}
          padding={2}
          classNames={["icon-link-button", "pe-pointer", "h6"]}
        >
          <img src={src} width={75} height={75} alt={alt} />
          <Div marginTop={2} classNames={["icon-link-label"]}>{alt}</Div>
        </Div>
      </a>
    </Div>
  );
};

export default IconLink;
