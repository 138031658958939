// import Check2Square from "../icons/Check2Square";
import BoxArrowUpRight from "../icons/BoxArrowUpRight";
import { routes } from "../misc/routes";

import Div from "./Div";
import NavBar from "./NavBar";
import NavItem from "./NavItem";

const Header = () => {
  return (
    <header className="fixed-top shadow bg-intouch-blue-dark d-print-none">
      <Div container>
        <NavBar logoHref={routes.Home}>
          <NavItem title="Home" route={routes.Home} bold />
          <NavItem title="Integrations" route={routes.Integrations} bold />
          <NavItem title="About" route={routes.About} bold />
          <NavItem title="Sales" route={routes.Sales} bold />
          <NavItem title="Support" route={routes.Support} bold />
          <Div classNames={["nav-link"]} fontSize={5} flex flexAlign="center">
            <a
              href="https://app.intouchpay.com"
              className="nav-item fw-500 text-white"
              // style={{ textDecoration: "none" }}
            >
              Go To App
              <Div marginStart={2} display="inline-block">
                <BoxArrowUpRight />
              </Div>
            </a>
          </Div>
        </NavBar>
      </Div>
      {/* <Div
        backgroundColor="warning"
        flex
        flexJustify="center"
        flexAlign="center"
      >
        <Div
          flex
          flexAlign="center"
          backgroundColor="warning"
          paddingY={2}
          fontSize={4}
        >
          <Div display="inline" textColor="white">
            Western WA User Group Meeting
          </Div>
          <a
            href="https://www.signupgenius.com/go/10C094BACAC2EA1F5C43-renton"
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center badge bg-danger ms-2 link-light"
          >
            <Check2Square />
            <Div display="inline" marginStart={2}>
              Sign Up Here!
            </Div>
          </a>
        </Div>
      </Div> */}
    </header>
  );
};

export default Header;
