import { Helmet } from "react-helmet";

import PageHeader from "../components/PageHeader";
import TestimonialRow from "../components/TestimonialRow";

import NorthwestISD from "../img/clients/nwisd-small.jpg";
import DeerCreek from "../img/clients/deercreek.jpg";
import CentralKitsap from "../img/clients/cksd.png";
import SalemKeiser from "../img/clients/salemkeizer.jpg";
import Rochester from "../img/clients/rochester.png";
import UniversityPlace from "../img/clients/universityplace.jpg";
import Bethel from "../img/clients/bethel.jpg";
import Sequim from "../img/clients/sequim.png";
import Issaquah from "../img/clients/issaquah.jpeg";
import Battleground from "../img/clients/battleground.png";

const Testimonials = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>Testimonials</title>
        <meta
          name="description"
          content="We have decades of happy clients, see what they have to say."
        />
      </Helmet>

      <PageHeader title="Client Testimonials" subtitle="" />

      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <TestimonialRow
              image={
                <img src={NorthwestISD} width="100px" alt="Northwest ISD" />
              }
              quotes={[
                "I have been using the InTouch system for 3 ½ years [2016] and I couldn’t be happier. My users are excited with how easy the system is to use and my bookkeepers are able to see how much money is on their campuses throughout the day.",
                "Parents are excited with receiving electronic receipts to their email address. No more looking for a paper receipt at the bottom of your child’s backpack.",
                "Thank you for creating an excellent product!!",
              ]}
              person="Kimberly McGreger"
              org="Northwest ISD"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={DeerCreek}
                  width="100px"
                  alt="Deer Creek Public Schools"
                />
              }
              quotes={[
                "The InTouch Receipting Suite has provided our district with the ability to offer online payments to our students and community.  By adding this feature we are able to expedite our payment process for our activity accounts and provide instant receipts.  We look forward to expanding our usage of InTouch in the future to make our processes even more streamlined.",
              ]}
              person="James C. Edwards"
              title="Chief Financial Officer"
              org="Deer Creek Public Schools"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={CentralKitsap}
                  width="100px"
                  alt="Central Kitsap School District"
                />
              }
              quotes={[
                "InTouch computerized receipting is awesome! I work in the district business office/accounting and use the features of InTouch all the time and don’t even receipt revenue! Locating a student for an NSF or refund and being able to obtain the correct parent address; running reports by revenue code; and, of course, running the deposit summary reports from my workstation daily. This has completely eliminated sending reports back and forth through district mail. Since I have the ability to run the reports myself I no longer have to call our bookkeepers for clarification, I can usually obtain the information I require by looking up the detail in InTouch.",
                "A student history is available anytime a parent requests the information. A single check can be split between different district funds. Lists are available for field trips, sales, fines, sports, accounts, etc. The district bookkeepers are also athletic secretaries, no additional help has been required. No more hand receipting or making lists by hand, a miracle!",
                "Eleven years ago I contacted InTouch Receipting with a request for a receipting system that could be used by bookkeepers that would eliminate the need for them to maintain a student card file and keep track of revenue. An InTouch Receipting product was being used in a HS student store at the time, at the advisor’s suggestion I contacted InTouch Receipting and the rest is history!! InTouch, a windows system, was installed as soon as it became available. One of the biggest advantages of a computerized receipting system is that money collection has been completely centralized and removed from the teaching staff.",
              ]}
              person="Sharon Schmidt"
              org="Central Kitsap School District"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={SalemKeiser}
                  width="100px"
                  alt="Salem-Keizer School District"
                />
              }
              quotes={[
                "THANK YOU ALL FOR GETTING IN TOUCH!  I sailed through registration!  The parents were impressed with the receipts, the ability to see their student's history and the fact that their checks would be processed quicker.... ​I sincerely mean it when I say thank you for this valuable upgrade which is going to be an impressive asset to assist me keep on top of my job!",
              ]}
              person="Jolie Gilman"
              org="Salem-Keizer School District"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={Rochester}
                  width="100px"
                  alt="Rochester High School"
                />
              }
              quotes={[
                "​I have already seen an increase in online payments since going live with TouchBase. Within 24 hours we received payment on outstanding fines!   I am excited to direct parents to our TouchBase web store knowing that it is easy to navigate and their experience will be positive.",
              ]}
              person="Stephanie Winner"
              org="Rochester High School"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={UniversityPlace}
                  width="100px"
                  alt="University Place School District"
                />
              }
              quotes={[
                "The project came off without any hitches and everyone I’ve spoken to seems very happy with the new system. Steve and you are a pleasure to work with. Finally, the whole thing came off with little impact to my department.",
              ]}
              person="Tristan Bequest"
              org="University Place School District"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img src={Bethel} width="100px" alt="Bethel School District" />
              }
              quotes={[
                "​The new InTouch system is a powerful tool for them to process receipts faster and also have information they need at their fingertips by running reports. We also use Intouch for our entire district’s fines and nsfs. The greatest benefit for me at the district office is the ability to upload our revenue data directly from the InTouch receipting system to our district financial system, which has saved a lot of input time and cut down on errors.",
              ]}
              person="Garland Hanson"
              org="Bethel School District"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img src={Sequim} width="100px" alt="Sequim School District" />
              }
              quotes={[
                "Each transaction takes approximately 1/2 the time to process. I am also amazed at how I get the students through long lines. It used to take me to 3:30 p.m. or 4 p.m. to get tickets sold on the last day of ticket sales, now with my new system I’m done and closing at my normal 3:00 p.m. shut down time. I have enjoyed using the system and would recommend it to any potential InTouch system purchaser.",
              ]}
              person="Janet A. Peterson"
              org="Sequim School District"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={Issaquah}
                  width="100px"
                  alt="Issaquah School District #411"
                />
              }
              quotes={[
                "​Issaquah School District has been using InTouch Receipting products for 10 years ~ first with Super Store and then with the Windows based InTouch software. In Touch has been easy to learn and my ASB/GF Secretaries are very happy with the product... ​We have been a part of user group meetings over the years and Arnold and his team are continually working to make a great product even better as we expand to use the Student Store and Athletic/Activities modules.",
              ]}
              person="Sharon Suver-Jones"
              org="Issaquah School District #411"
              className="mb-5"
            />
            <TestimonialRow
              image={
                <img
                  src={Battleground}
                  width="100px"
                  alt="Battleground School District"
                />
              }
              quotes={[
                "Thank you for your high quality service. From the employee who answers the phone to the employees who problem solve I have been treated with respect and kindness this past year. It has been a pleasure to work with such fine professionals.",
              ]}
              person="Deb Clabaugh"
              org="Battleground School District"
              className="mb-5"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
