import { Helmet } from "react-helmet";

import Div from "../components/Div";
import PageHeader from "../components/PageHeader";

const TouchBaseLiteRequest = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>TouchBase Lite Request</title>
        <meta name="description" content="TouchBase Lite Request" />
      </Helmet>

      <PageHeader title="TouchBase Lite Request" subtitle="" />

      <Div container marginTop={3}>
        <Div row>
          <Div col>
            <p>Please complete the steps below:</p>
            <ol>
              <li>
                Download and complete the{" "}
                <a href="https://static.intouchreceipting.com/tbl form.pdf">
                  request form
                </a>
                .
              </li>
              <li>
                Gather any photos or logo files you want to use on your
                fundraising site.
              </li>
              <li>
                Email the request form and photos/logos to{" "}
                <a href="mailto:touchbaselite@intouchreceipting.com">
                  touchbaselite@intouchreceipting.com
                </a>
              </li>
            </ol>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default TouchBaseLiteRequest;
