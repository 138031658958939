import Div from "./Div";

const Partner = ({
  image,
  lead,
  children,
  className,
}: {
  image: JSX.Element;
  lead: string;
  children: JSX.Element;
  className?: string;
}) => {
  return (
    <Div
      flex
      flexColumn
      flexAlign="start"
      classNames={["flex-md-row", className ?? ""]}
    >
      {image}
      <Div marginTop={2} marginTopMd={0} classNames={["ms-md-3"]}>
        <strong>{lead}</strong>
        <div>{children}</div>
      </Div>
    </Div>
  );
};

export default Partner;
