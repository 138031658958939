import { Helmet } from "react-helmet";
import Div from "../components/Div";
import PageHeader from "../components/PageHeader";
import USPaymentTermsAppendix1Content from "./USPaymentTermsAppendix1Content";

const USPaymentTermsAppendix1 = () => {
  return (
    <>
      <Helmet titleTemplate="InTouch | %s">
        <title>
          Merchant Payment T&C USA - Appendix 1
        </title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <PageHeader
        title="Merchant Payment T&C USA - Appendix 1"
        subtitle=""
      />

      <style type="">
        {`
        .t-row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0;
          margin-bottom: 1rem;
        }

        .t-col-1 {
          flex: 0 0 auto;
          width: 4.1667%
        }

        .t-col-2 {
          flex: 0 0 auto;
          width: 6.2500%
        }
      `}
      </style>

      <Div container>
        <Div row marginTop={4}>
          <Div col>
            <USPaymentTermsAppendix1Content />
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default USPaymentTermsAppendix1;
